import React from 'react';
import NavigationItem from './navigation_item';
import { useUiOptions } from '../../../store';

import { PageUrls } from '../../../types';

import './navigation_bar.scss';

const NavigationBar = () => {
  const { hasAcceptedTermsAndConditions, hasEnteredValidUserDetails } = useUiOptions();

  return (
    <div className="navigation-wrapper">
      <nav>
        <NavigationItem targetPath={PageUrls.welcome}>Welcome</NavigationItem>
        <NavigationItem targetPath={PageUrls.yourDetails} linkIsDisabled={!hasAcceptedTermsAndConditions}>
          Your details
        </NavigationItem>
        <NavigationItem
          targetPath={PageUrls.yourChoices}
          linkIsDisabled={!hasAcceptedTermsAndConditions || !hasEnteredValidUserDetails}
        >
          Your choices
        </NavigationItem>
        <NavigationItem
          targetPath={PageUrls.annualAllowance}
          linkIsDisabled={!hasAcceptedTermsAndConditions || !hasEnteredValidUserDetails}
        >
          Annual Allowance
        </NavigationItem>
        <NavigationItem
          targetPath={PageUrls.furtherInformation}
          linkIsDisabled={!hasAcceptedTermsAndConditions || !hasEnteredValidUserDetails}
        >
          Further information
        </NavigationItem>
      </nav>
    </div>
  );
};

export default NavigationBar;
