import React from 'react';
import useDocumentTitle from '../../../store/use_document_title';
import StringLiterals from '../../utils/stringLiterals';
import './further_information.scss';

const FurtherInformation = () => {
  useDocumentTitle(StringLiterals.title + 'Further information');

  return (
    <div className="further-information-page-content">
      <h1>Further information</h1>

      <div className="copy-section">
        <h2>Benefits+</h2>
        <p>
          The Benefits+ process will open in February each year, and you will need to choose your pension accrual rate
          for the forthcoming CARE year (starting 1 April). In future years, if you do not specifically choose a pension
          accrual rate, your accrual rate will be the same as the previous year.
        </p>
        <p>
          Once you have chosen your pension accrual rate, it will apply for the whole of the forthcoming CARE year. You
          will not normally be able to change it during that CARE year, other than for some specific life events, so
          please consider this when making your choice.
        </p>

        <h2>Information sources</h2>
        <p>
          The Bank wants you to have full information about the CARE scheme, and you can obtain information from the
          following sources:
        </p>

        <div className="information-tiles">
          <div className="information-tiles__tile-wrapper">
            <div className="information-tiles__icon information-tiles__icon--laptop"></div>
            <p className="information-tiles__text">
              Information including the member booklet for the scheme and Q&amp;As can be found on the dedicated section
              of the intranet.
            </p>
          </div>
          <div className="information-tiles__tile-wrapper">
            <div className="information-tiles__icon information-tiles__icon--piggybank"></div>
            <p className="information-tiles__text">
              Further information about the Supplementary Pension Plan is available on Benefits+
            </p>
          </div>
          <div className="information-tiles__tile-wrapper">
            <div className="information-tiles__icon information-tiles__icon--phone"></div>
            <p className="information-tiles__text">
              Raise a service request through One Bank Service or call AskHR on 020 3461 5115 (lines open until midday
              Monday to Friday).
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FurtherInformation;
