import { reducerAction, reducerActions, UiOptionsStore } from '../../types';

const defaultState: UiOptionsStore = {
  hasAcceptedTermsAndConditions: false,
  hasEnteredValidUserDetails: false,
  yourDetailsErrorFields: [],
};

const uiOptionsReducer = (oldState: UiOptionsStore = defaultState, action: reducerAction): UiOptionsStore => {
  const { payload } = action || {};
  switch (action?.type) {
    case reducerActions.UPDATE_UI_OPTIONS:
      return { ...oldState, ...payload };
    case reducerActions.RESET_ALL:
      return { ...defaultState };
    default:
      return oldState;
  }
};

export default uiOptionsReducer;
