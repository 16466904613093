import { combineReducers } from 'redux';

import userData from './user_inputs/user_data_reducer';
import uiOptions from './user_inputs/ui_options_reducer';

import pensionValuationOutput from './engine_outputs/pension_valuation_reducer';
import annualAllowanceOutput from './engine_outputs/annual_allowance_reducer';
import ltaOutput from './engine_outputs/lta_reducer';

const rootReducer = combineReducers({
  userData,
  uiOptions,
  pensionValuationOutput,
  annualAllowanceOutput,
  ltaOutput,
});

export default rootReducer;
