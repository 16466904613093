import React, { useState } from 'react';
import './accordion.scss';

interface AccordionProps {
  children?: React.ReactNode;
  heading: string;
  includeCloseButton?: boolean;
}

const Accordion = ({ children, heading, includeCloseButton }: AccordionProps) => {
  const [accordionIsVisible, setAccordionIsVisible] = useState(false);
  const toggleAccordionVisibility = () => {
    setAccordionIsVisible((prevState) => !prevState);
  };

  return (
    <>
      <button
        type="button"
        className={`accordion-heading ${accordionIsVisible ? 'active' : ''}`}
        onClick={() => toggleAccordionVisibility()}
      >
        {heading}
      </button>

      <div className={`accordion-content ${accordionIsVisible ? 'active' : ''}`}>
        <div className="accordion-content__inner-wrapper">
          {children}
          {includeCloseButton && (
            <button
              type="button"
              onClick={() => toggleAccordionVisibility()}
              className="accordion-content__close-button"
            >
              X <span>Close assumptions</span>
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default Accordion;
