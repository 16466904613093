import { useSelector, useDispatch } from 'react-redux';

import { reducerActions, PensionValuationOrAnnualAllowanceOutputStore, ReduxStore, LtaOutputStore } from '../../types';

import { useUserData } from '../user_inputs/hooks';

export const formatPensionValuation = (output: PensionValuationOrAnnualAllowanceOutputStore) => {
  const { futureTerms } = useUserData();

  const targetResultSet = futureTerms ? 'futureTerms' : 'currentTerms';

  const baselineResults = output.baseline[targetResultSet];
  const scenarioResults = output.scenario[targetResultSet];
  const baselineSalary = futureTerms ? baselineResults.salaryAtRetirementAge : baselineResults.salary;
  const scenarioSalary = scenarioResults.salary;

  const baselinePensionValuation = {
    pastService: baselineResults.totalPastServicePensionAtRetirementAge,
    futureService: baselineResults.totalFutureServicePensionAtRetirementAge,
    total: baselineResults.totalPensionAtRetirementAge,
  };

  const scenarioPensionValuation = {
    pastService: scenarioResults.totalPastServicePensionAtRetirementAge,
    futureService: scenarioResults.totalFutureServicePensionAtRetirementAge,
    total: scenarioResults.totalPensionAtRetirementAge,
  };

  const pensionValuationImplications = {
    difference: Math.abs(baselinePensionValuation.total - scenarioPensionValuation.total),
  };

  const salaryImplications = {
    current: baselineSalary,
    afterScenario: scenarioSalary,
    adjustmentForScenario: scenarioSalary - baselineSalary,
  };

  return {
    salaryImplications,
    pensionValuationImplications,
    baselinePensionValuation,
    scenarioPensionValuation,
  };
};

export const usePensionValuationOutput = () =>
  useSelector((state: ReduxStore): any => {
    return { ...formatPensionValuation(state.pensionValuationOutput) };
  });

export const useUpdatePensionValuationOutput = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (payload: object) =>
    dispatch({
      type: reducerActions.UPDATE_PENSION_VALUATION_OUTPUT,
      payload,
    });
};

export const useAnnualAllowanceOutput = () =>
  useSelector((state: ReduxStore): any => {
    return state.annualAllowanceOutput;
  });

export const useUpdateAnnualAllowanceOutput = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (payload: object) =>
    dispatch({
      type: reducerActions.UPDATE_ANNUAL_ALLOWANCE_OUTPUT,
      payload,
    });
};

const formatLtaOutput = (ltaOutput: LtaOutputStore) => {
  type LtaOutputCategory = 'baseline' | 'scenario';

  const formattedUserSummary: { [key in LtaOutputCategory]: Record<string, number> } = {
    baseline: {},
    scenario: {},
  };

  let outputCategory: LtaOutputCategory;

  // eslint-disable-next-line guard-for-in
  for (outputCategory in formattedUserSummary) {
    const ltaOutputForCategory = ltaOutput.userSummary[outputCategory];

    formattedUserSummary[outputCategory] = {
      past: ltaOutputForCategory.futureTerms.past,
      legacy: ltaOutputForCategory.futureTerms.legacy,
      future: ltaOutputForCategory.futureTerms.future,
      total:
        ltaOutputForCategory.futureTerms.past +
        ltaOutputForCategory.futureTerms.legacy +
        ltaOutputForCategory.futureTerms.future,
    };
  }

  return {
    ...formattedUserSummary,
    ltaStandardValue: ltaOutput.ltaStandardValue,
    ltaAlternativeValue: ltaOutput.ltaAlternativeValue,
  };
};

export const useLtaOutput = () =>
  useSelector((state: ReduxStore): any => {
    return { ...formatLtaOutput(state.ltaOutput) };
  });

export const useUpdateLtaOutput = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (payload: object) =>
    dispatch({
      type: reducerActions.UPDATE_LTA_OUTPUT,
      payload,
    });
};
