import React, { ChangeEvent } from 'react';

import './radio_inputs.scss';

type RadioInputProps = {
  labelledBy?: string;
  selected: any;
  radioOptions: {
    label: string;
    value: any;
  }[];
  allowMultipleSelections?: boolean;
  handleChange: (event: ChangeEvent) => void;
};

const RadioInputs = ({
  labelledBy,
  selected,
  radioOptions,
  handleChange,
  allowMultipleSelections = false,
}: RadioInputProps) => {
  const htmlDomId = (option: any) => `${labelledBy}-${option.label.replace(/ /gi, '-')}-radio`;

  return (
    <div className="radio-options">
      {radioOptions.map((option) => {
        return (
          <div key={option.value} className="radio-option">
            <input
              id={htmlDomId(option)}
              type={allowMultipleSelections ? 'checkbox' : 'radio'}
              className="custom-radio"
              name={labelledBy}
              checked={allowMultipleSelections ? selected.has(option.value) : option.value === selected}
              onChange={() => handleChange(option.value)}
            />
            <label htmlFor={htmlDomId(option)}>{option.label}</label>
          </div>
        );
      })}
    </div>
  );
};

export default RadioInputs;
