import React from 'react';
import { useAnnualAllowanceOutput, useUserData } from '../../../../store';
import Formatter from '../../../utils/formatter';

interface AnnualAllowanceRowDataCellsProps {
  accrualRatesToShow: Set<number>;
  targetAttribute?: string;
  children?: React.ReactNode;
}

const AnnualAllowanceRowDataCells = ({
  accrualRatesToShow,
  targetAttribute,
  children,
}: AnnualAllowanceRowDataCellsProps) => {
  const annualAllowanceOutput = useAnnualAllowanceOutput();
  const formatter = new Formatter();
  const { currentAccrualRate } = useUserData();

  return (
    <>
      {Array.from(accrualRatesToShow).map((accrualRate) => {
        return (
          <td
            key={`${targetAttribute}-${accrualRate}`}
            className={accrualRate === currentAccrualRate ? 'current-user-accrual-rate' : ''}
          >
            {children ||
              formatter.asCurrencyWithoutPence(
                annualAllowanceOutput[`annualAllowanceForAccrualRate${accrualRate}`][targetAttribute]
              )}
          </td>
        );
      })}
    </>
  );
};

export default AnnualAllowanceRowDataCells;
