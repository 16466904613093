export default class FlexRateTable {
  public rates: number[][];

  constructor(rates: number[][]) {
    this.rates = rates;
  }

  public getDifference(from: number, to: number) {
    var difference = this.rates[from][to];
    return isNaN(difference) ? NaN : difference;
  }
}
