import { reducerAction, reducerActions, LtaOutputStore } from '../../types';
import OutputLtaBaseline from '../../engine/output/lta/baseline';
import OutputLtaScenario from '../../engine/output/lta/scenario';

const defaultState: LtaOutputStore = {
  userSummary: {
    baselinePastDcFund: [],
    baselinePastCare: [],
    baselineFutureCare: [],
    baselinePastFinalSalary: [],
    baselineLtaValue: [],
    pastDcFund: [],
    futureDcFund: [],
    ltaValue: [],
    baseline: new OutputLtaBaseline(),
    scenario: new OutputLtaScenario(),
    pastServiceFinalSalaryPension: 0,
    flexibleBenefitsPot: 0,
  },
  ltaStandardValue: 0,
  ltaAlternativeValue: 0,
};

const ltaOutputReducer = (oldState: LtaOutputStore = defaultState, action: reducerAction): LtaOutputStore => {
  switch (action.type) {
    case reducerActions.UPDATE_LTA_OUTPUT:
      return { ...oldState, ...action.payload };
    case reducerActions.RESET_ALL:
      return { ...defaultState };
    default:
      return oldState;
  }
};

export default ltaOutputReducer;
