import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useUiOptions } from '../../../store';
import { PageUrls } from '../../../types';
import { Welcome, YourDetails, YourChoices, AnnualAllowance, FurtherInformation } from '../../pages';
import { isFirefox } from 'react-device-detect';
import '../../../css/transitions.scss';

const PageRouter = () => {
  const { hasAcceptedTermsAndConditions } = useUiOptions();

  const location = useLocation();

  useEffect(
    function scrollToTopOnPageChange() {
      window.scrollTo({ top: 0 });
    },
    [location]
  );

  const coreRoutes = (
    <Routes location={location}>
      <Route path={PageUrls.welcome} element={<Welcome />} />
      {hasAcceptedTermsAndConditions && (
        <>
          <Route path={PageUrls.yourDetails} element={<YourDetails />} />
          <Route path={PageUrls.yourChoices} element={<YourChoices />} />
          <Route path={PageUrls.annualAllowance} element={<AnnualAllowance />} />
          <Route path={PageUrls.furtherInformation} element={<FurtherInformation />} />
        </>
      )}
      <Route path="*" element={<Navigate to={PageUrls.welcome} replace={false} />} />
    </Routes>
  );

  // If you refresh the browser, Firefox has issues with the CSS Transitions as it requires
  // a redirect to home, it just gets stuck in a loop. So no CSS transitions for Firefox.
  if (isFirefox) {
    return coreRoutes;
  } else {
    return (
      <TransitionGroup>
        <CSSTransition key={location.key} timeout={{ enter: 300, exit: 150 }} classNames="fade">
          {coreRoutes}
        </CSSTransition>
      </TransitionGroup>
    );
  }
};

export default PageRouter;
