import React from 'react';
import { useUpdateUserData, useUserData } from '../../../../store';

import SliderInput from '../../../inputs/slider_input/slider_input';
import HelpLabel from '../../../../components/help_label/help_label';

const CompareLta = () => {
  const { legacyDbPension, legacyDcFund } = useUserData();
  const updateUserData = useUpdateUserData();

  return (
    <section className="user-options-tab-content user-options-tab-content--compare-lta">
      <h2>Compare with LTA</h2>
      <p>
        Compare the value of your benefits with the Lifetime Allowance that is projected to apply at your chosen
        retirement age. Please note this chart shows the value of both your Bank pension benefits under your chosen
        options and any additional pension benefits you have elsewhere that you can enter below.
      </p>
      <h3>Additional pension benefits</h3>

      <form noValidate>
        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="other-db-pensions"
            description="Other defined benefit (DB) pensions"
            extendedDescription="The annual amount (not cash value) of any other DB pensions you may have from other past employers (i.e. not the Bank of England), projected to your retirement age. You should be able to obtain an estimate of this figure from the administrators of your previous schemes."
          />
          <SliderInput
            labelledBy="other-db-pensions"
            value={legacyDbPension}
            handleChange={(value) => updateUserData({ legacyDbPension: value })}
            min={0}
            max={100_000}
            inputRepresentsMoney
          />
        </fieldset>
        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="other-dc-funds"
            description="Other defined contribution (DC) funds"
            extendedDescription="The current cash value of any other DC funds you may have, either from previous employment or any personal pension arrangements you may have."
          />
          <SliderInput
            labelledBy="other-dc-funds"
            value={legacyDcFund}
            handleChange={(value) => updateUserData({ legacyDcFund: value })}
            min={0}
            max={1_000_000}
            inputRepresentsMoney
          />
        </fieldset>
      </form>
    </section>
  );
};

export default CompareLta;
