export default class ExcelFunctions {
  public LESS: number;
  public EXACT: number;
  public GREATER: number;
  /*
    match types for Lookup
    LESS: finds the largest value that is less than or equal to value. The values in the array argument must be placed in ascending order
    EXACT: finds the first value that is exactly equal to value. The values in the array argument can be in any order.
    GREATER: finds the smallest value that is greater than or equal to value. The values in the array argument must be placed in descending order
  */
  constructor() {
    this.LESS = 1;
    this.EXACT = 2;
    this.GREATER = 3;
  }

  /*
    rounds a number to a specified number of digits
    matches functionality provided by Excel's ROUND()
    http://office.microsoft.com/en-gb/excel-help/round-function-HP010062455.aspx
  */
  public round(x: number, n: number) {
    // Math.abs(null) returns 0...
    // we should return null if the input is null
    if (x === null) {
      return null;
    }

    var rounded = Math.round(Math.abs(x) * Math.pow(10, n)) / Math.pow(10, n);
    return x < 0 ? 0 - rounded : rounded;
  }

  /*
    rounds a number to a specified number of digits
    matches functionality provided by Excel's ROUNDDOWN()
    http://office.microsoft.com/en-gb/excel-help/rounddown-HP005209241.aspx
  */
  public roundDown(x: number, n: number) {
    // Math.abs(null) returns 0...
    // we should return null if the input is null
    if (x === null) {
      return null;
    }

    var rounded = Math.floor(Math.abs(x) * Math.pow(10, n)) / Math.pow(10, n);
    return x < 0 ? 0 - rounded : rounded;
  }

  /*
    rounds a number to a specified number of digits
    matches functionality provided by Excel's ROUNDUP()
    http://office.microsoft.com/en-gb/excel-help/roundup-function-HP010342860.aspx
  */
  public roundUp(x: number, n: number) {
    // Math.abs(null) returns 0...
    // we should return null if the input is null
    if (x === null) {
      return null;
    }

    var rounded = Math.ceil(Math.abs(x) * Math.pow(10, n)) / Math.pow(10, n);
    return x < 0 ? 0 - rounded : rounded;
  }

  /*
    count the number of values in range that meet a criterion
    broadly matches functionality provided by Excel's COUNTIF()
    NOTE: only allows for == type comparison
  */
  public countIf(range: any[], criteria: any) {
    var count = 0;
    for (var i = 0; i < range.length; i++) {
      if (range[i] == criteria) {
        count++;
      }
    }
    return count;
  }

  /*
    sum the values in a range that meet a criterion
    broadly matches functionality provided by Excel's SUMIF()
    NOTE: criteria is a function OR and object containing a function (func) and paramaters (param)
  */
  public sumIf(range: number[], criteria: any, sumRange: number[]) {
    sumRange = typeof sumRange == 'undefined' ? range : sumRange;

    var test = typeof criteria == 'function' ? criteria : criteria.func;
    var params = typeof criteria == 'function' ? null : criteria.params;

    var sum = 0;
    for (var i = 0; i < sumRange.length; i++) {
      if (test(range[i], params)) {
        sum += sumRange[i];
      }
    }

    return sum;
  }

  /*
    sum the values in a range
    broadly matches functionality provided by Excel's SUM()
  */
  public sum(range: number[]) {
    var sum = 0;
    for (var i = 0; i < range.length; i++) {
      sum += range[i];
    }
    return sum;
  }

  //used to replicate(ish) Excel's HLookup, VLookup  functions
  public lookup(value: number, lookupArray: number[], targetArray: number[], type: number) {
    //default behaviour is the same as Excel HLookup / VLookup
    type = typeof type !== 'undefined' ? type : this.LESS;

    var index = this.match(value, lookupArray, type);
    return this.index(targetArray, index);
  }

  //used to replicate(ish) Excel's index function
  public index(array: number[], index: number) {
    if (isNaN(index) || index < 0 || index >= array.length) {
      return NaN;
    } else {
      return array[index];
    }
  }

  /*
    to cover the functionality provided MATCH
  */
  public match(value: number, array: number[], type: number) {
    var i;
    if (type == this.LESS) {
      if (value < array[0]) {
        return NaN;
      } else {
        for (i = 0; i < array.length; i++) {
          if (array[i] == value) {
            return i;
          } else if (array[i] > value) {
            return i - 1;
          }
        }

        //nothing found so return the last index
        return array.length - 1;
      }
    } else if (type == this.EXACT) {
      for (i = 0; i < array.length; i++) {
        if (array[i] == value) {
          return i;
        }
      }
      return NaN;
    } else if (type == this.GREATER) {
      if (value > array[array.length - 1]) {
        return NaN;
      }
      for (i = 0; i < array.length; i++) {
        if (array[i] > value) {
          return i;
        }
      }
    }
  }

  //replicate Excel's IFERROR function in the context of JavaScript
  public ifError(value: number, valueIfError: number) {
    return typeof value == 'undefined' || isNaN(value) ? valueIfError : value;
  }
}
