import OutputPensionResults from './results';

export default class OutputPensionScenario {
  public currentTerms: OutputPensionResults;
  public futureTerms: OutputPensionResults;

  constructor() {
    this.currentTerms = new OutputPensionResults();
    this.futureTerms = new OutputPensionResults();
  }
}
