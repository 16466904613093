import React from 'react';
import { Tooltip } from 'react-tooltip';

import 'react-tooltip/dist/react-tooltip.css';
import './help_label.scss';

type HelpProps = {
  labelFor: string;
  description: string;
  extendedDescription: string;
};

const HelpLabel = ({ labelFor, description, extendedDescription }: HelpProps) => {
  const helpClass = 'help-popup ' + (extendedDescription !== '' ? '' : 'help-popup__blank');
  const tooltipId = 'help-' + labelFor;

  return (
    <label htmlFor={labelFor} className="help-label">
      <div className="column">
        <div id={tooltipId} className={helpClass}>
          <Tooltip
            anchorId={tooltipId}
            content={extendedDescription}
            place="right"
            variant="dark"
            style={{ width: '300px', zIndex: 100 }}
          ></Tooltip>
        </div>
      </div>

      <div className="column">
        <span className="help-label__primary-description">{description}</span>
      </div>
    </label>
  );
};

export default HelpLabel;
