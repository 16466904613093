import React from 'react';

import './information_box.scss';

import informationIcon from '../../../images/information-icon.svg';

type InformationBoxProps = {
  children?: React.ReactNode;
};

const InformationBox = ({ children }: InformationBoxProps) => {
  return (
    <div className="information-box">
      <img className="information-box__icon" src={informationIcon} alt="" />
      {children}
    </div>
  );
};

export default InformationBox;
