export default class OutputAnnualAllowanceResults {
  public rate: number;
  public firstExpectedSalary: number;
  public flexibleBenefitsPot: number;
  public performanceAward: number;
  public secondExpectedSalary: number;
  public otherBankTaxableIncome: number;
  public otherExternalTaxableIncome: number;
  public contributionChange: number;
  public thresholdIncome: number;
  public finalSalaryPensionInputAmount: number;
  public carePensionInputAmount: number;
  public dcContributions: number;
  public totalPensionInputAmount: number;
  public adjustedIncome: number;
  public annualAllowance: number;
  public annualAllowanceCarryForward: number;
  public annualAllowanceAssessedValue: number;
  public annualAllowanceCarryForwardForYear: number;
  public annualAllowanceAllowingForCarryForward: number;
  public excessAboveAnnualAllowance: number;
  public taxCharge: number;
  public schemePaysPensionGivenUp: number;
  public increaseInPensionBeforeSchemePays: number;
  public increaseInPensonAfterSchemePays: number;
  public finalSalaryPensionAtStartOfYear: number;
  public careAccruedPensionAtStartOfYear: number;
  public exemptFromTapering: Boolean;
  public revaluedPastServiceFinalSalaryPension: number;
  public finalSalaryPensionAtStartOfPip: number;
  public finalSalaryPensionAtEndOfPip: number;
  public careAccruedPensionAtStartOfPip: number;
  public careAccruedPensionAtEndOfPip: number;
  public finalSalaryPensionInputAmountForYear: number;
  public carePensionInputAmountForYear: number;
  public ageAtDateOfSchemePays: number;
  public careFactor: number;
  public finalSalaryFactor: number;
  public schemePaysGreaterThanCare: Boolean;
  public pastServiceFinalSalaryPension: number;

  constructor() {
    this.rate = 0;
    this.firstExpectedSalary = 0;
    this.flexibleBenefitsPot = 0;
    this.performanceAward = 0;
    this.secondExpectedSalary = 0;
    this.otherBankTaxableIncome = 0;
    this.otherExternalTaxableIncome = 0;
    this.contributionChange = 0;
    this.thresholdIncome = 0;
    this.finalSalaryPensionInputAmount = 0;
    this.carePensionInputAmount = 0;
    this.dcContributions = 0;
    this.totalPensionInputAmount = 0;
    this.adjustedIncome = 0;
    this.annualAllowance = 0;
    this.annualAllowanceCarryForward = 0;
    this.annualAllowanceAssessedValue = 0;
    this.annualAllowanceCarryForwardForYear = 0;
    this.annualAllowanceAllowingForCarryForward = 0;
    this.excessAboveAnnualAllowance = 0;
    this.taxCharge = 0;
    this.schemePaysPensionGivenUp = 0;
    this.increaseInPensionBeforeSchemePays = 0;
    this.increaseInPensonAfterSchemePays = 0;
    this.finalSalaryPensionAtStartOfYear = 0;
    this.careAccruedPensionAtStartOfYear = 0;
    this.exemptFromTapering = false;
    this.revaluedPastServiceFinalSalaryPension = 0;
    this.finalSalaryPensionAtStartOfPip = 0;
    this.finalSalaryPensionAtEndOfPip = 0;
    this.careAccruedPensionAtStartOfPip = 0;
    this.careAccruedPensionAtEndOfPip = 0;
    this.finalSalaryPensionInputAmountForYear = 0;
    this.carePensionInputAmountForYear = 0;
    this.ageAtDateOfSchemePays = 0;
    this.careFactor = 0;
    this.finalSalaryFactor = 0;
    this.schemePaysGreaterThanCare = false;
    this.pastServiceFinalSalaryPension = 0;
  }
};


