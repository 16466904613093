import React from 'react';

import PageRouter from './page_router';
import Header from './header';

import './base_layout.scss';

const BaseLayout = () => {
  return (
    <>
      <Header />
      <main className="page-wrapper">
        <PageRouter />
      </main>
    </>
  );
};

export default BaseLayout;
