import React from 'react';
import HelpLabel from '../../../help_label/help_label';
import AnnualAllowanceRowDataCells from './annual_allowance_row_data_cells';
import AdjustAssumptionsSidebar from './adjust_assumptions_sidebar';
import StringLiterals from '../../../utils/stringLiterals';

import './annual_allowance_table.scss';

interface AnnualAllowanceTableProps {
  selectedAccrualRates: Set<number>;
}

const AnnualAllowanceTable = ({ selectedAccrualRates }: AnnualAllowanceTableProps) => {
  return (
    <section className="annual-allowance-table">
      <table>
        <thead>
          <tr>
            <th className="column-heading">Accrual rates for {StringLiterals.nextTaxYear}</th>
            {Array.from(selectedAccrualRates).map((accrualRate) => {
              return <th className="column-heading" key={accrualRate}>{`${accrualRate}ths`}</th>;
            })}
          </tr>
        </thead>

        <tbody>
          <tr>
            <th className="row-heading">
              <HelpLabel
                labelFor="dial-up-or-down-contribution"
                description="Dial up/down contribution"
                extendedDescription={
                  'This is the amount of salary given up (shown as a negative number) or additional cash generated (shown as a positive number) in exchange for dialling up or down from your standard accrual rate, based on the flex rates for ' +
                  StringLiterals.nextTaxYear +
                  '.'
                }
              />
            </th>

            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="contributionChange"
            />

            <AdjustAssumptionsSidebar />
          </tr>

          <tr className="important-row">
            <th className="row-heading">
              <HelpLabel
                labelFor="threshold-income"
                description="Threshold income"
                extendedDescription="If your threshold income is less than £200,000, you will be exempt from the tapering of the Annual Allowance from £40,000 down to £4,000."
              />
            </th>
            <AnnualAllowanceRowDataCells accrualRatesToShow={selectedAccrualRates} targetAttribute="thresholdIncome" />
          </tr>

          <tr>
            <th className="row-heading">
              <HelpLabel
                labelFor="final-salary-pension-amount"
                description="Final Salary Pension Input Amount"
                extendedDescription={
                  'This is the expected value of the Final Salary benefits you will build up during ' +
                  StringLiterals.nextTaxYear +
                  ', calculated using the Annual Allowance methodology and factor.'
                }
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="finalSalaryPensionInputAmount"
            />
          </tr>

          <tr>
            <th className="row-heading">
              <HelpLabel
                labelFor="care-pension-amount"
                description="CARE Pension Input Amount"
                extendedDescription={
                  'This is the expected value of the CARE pension benefits you will build up during ' +
                  StringLiterals.nextTaxYear +
                  ', calculated using the Annual Allowance methodology and factor.'
                }
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="carePensionInputAmount"
            />
          </tr>

          <tr className="important-row">
            <th className="row-heading">
              <HelpLabel
                labelFor="total-pension-amount"
                description="Total Pension Input Amount"
                extendedDescription={
                  'The estimated value of the pension benefits you will build up during ' +
                  StringLiterals.nextTaxYear +
                  ', calculated using the Annual Allowance methodology and factor (AA assessed value).'
                }
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="totalPensionInputAmount"
            />
          </tr>

          <tr className="important-row">
            <th className="row-heading">
              <HelpLabel
                labelFor="adjusted-income"
                description="Adjusted income"
                extendedDescription={
                  'If your threshold income is greater than £200,000 and your  adjusted income is greater than £260,000, your Annual Allowance will be tapered down from £60,000 to a minimum of £10,000 at a rate of £1 for every £2 of additional adjusted income.'
                }
              />
            </th>
            <AnnualAllowanceRowDataCells accrualRatesToShow={selectedAccrualRates} targetAttribute="adjustedIncome" />
          </tr>

          <tr className="important-row">
            <th className="row-heading">
              <HelpLabel
                labelFor="annual-allowance-after-taper"
                description="Annual allowance (after taper)"
                extendedDescription={
                  'This is the estimated Annual Allowance that may apply to your pension benefits for ' +
                  StringLiterals.nextTaxYear +
                  " based on the information shown above. This figure may be reduced from the standard Annual Allowance of £60,000 in the circumstances described under 'Adjusted income' above."
                }
              />
            </th>
            <AnnualAllowanceRowDataCells accrualRatesToShow={selectedAccrualRates} targetAttribute="annualAllowance" />
          </tr>

          <tr>
            <th className="row-heading">
              <HelpLabel
                labelFor="aa-assessed-value-for-tax-year"
                description={'AA assessed value for ' + StringLiterals.taxYear + ' tax year'}
                extendedDescription={
                  'The estimated value of the pension benefits you will build up in the Bank arrangements between 6 April  ' +
                  StringLiterals.lastYear +
                  ' and 5 April  ' +
                  StringLiterals.thisYear +
                  ', calculated using the Annual Allowance methodology and factor.'
                }
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="annualAllowanceAssessedValue"
            />
          </tr>

          <tr>
            <th className="row-heading">
              <HelpLabel
                labelFor="estimated-aa-carry-forward"
                description={'Estimated AA carry forward from ' + StringLiterals.taxYear}
                extendedDescription=""
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="annualAllowanceCarryForwardForYear"
            />
          </tr>

          <tr className="important-row">
            <th className="row-heading">
              <HelpLabel
                labelFor="annual-allowance-to-allow-for-carry-forward"
                description={'Annual allowance for ' + StringLiterals.nextTaxYear + ' allowing for carry forward'}
                extendedDescription={
                  'This is the estimated Annual Allowance that may apply to your pension benefits for ' +
                  StringLiterals.nextTaxYear +
                  ' based on the information shown above, after allowing for both the taper (if applicable and as shown above) and any carry forward you may have from the ' +
                  StringLiterals.lastLastTaxYear +
                  ', ' +
                  StringLiterals.lastTaxYear +
                  ' and ' +
                  StringLiterals.taxYear +
                  ' years.'
                }
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="annualAllowanceAllowingForCarryForward"
            />
          </tr>

          <tr className="important-row">
            <th className="row-heading">
              <HelpLabel
                labelFor="excess-above-annual-allowance"
                description="Excess above annual allowance"
                extendedDescription=""
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="excessAboveAnnualAllowance"
            />
          </tr>

          <tr className="important-row">
            <th className="row-heading">
              <HelpLabel
                labelFor="tax-charge"
                description="Tax charge (based on input marginal rate)"
                extendedDescription=""
              />
            </th>
            <AnnualAllowanceRowDataCells accrualRatesToShow={selectedAccrualRates} targetAttribute="taxCharge" />
          </tr>

          <tr>
            <th className="row-heading">
              <HelpLabel
                labelFor="pension-given-up-under-scheme-pays"
                description="Pension given up under scheme pays"
                extendedDescription="This is the amount of pension you may have to give up in return for your tax charge shown above being paid by the Bank of England Pension Fund under the 'scheme pays' approach. This amount is indicative only as the scheme pays factors will not be known until nearer the time."
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="schemePaysPensionGivenUp"
            />
          </tr>

          <tr>
            <th className="row-heading">
              <HelpLabel
                labelFor="total-increase-in-pension-before-scheme-pays"
                description="Total increase in pension before scheme pays"
                extendedDescription=""
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="increaseInPensionBeforeSchemePays"
            />
          </tr>

          <tr>
            <th className="row-heading">
              <HelpLabel
                labelFor="increase-in-pension-over-tax-year-after-scheme-pays"
                description="Increase in pension over tax year after scheme pays"
                extendedDescription=""
              />
            </th>
            <AnnualAllowanceRowDataCells
              accrualRatesToShow={selectedAccrualRates}
              targetAttribute="increaseInPensonAfterSchemePays"
            />
          </tr>
        </tbody>
      </table>
    </section>
  );
};

export default AnnualAllowanceTable;
