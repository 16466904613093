export default {
  title: 'BOE Calculator - ',
  genders: {
    male: 'Male',
    female: 'Female',
  },
  lastLastTaxYear: '2021/22',
  lastTaxYear: '2022/23',
  taxYear: '2023/24',
  nextTaxYear: '2024/25',
  lastYear: '2023',
  thisYear: '2024',
  nextYear: '2025',
  retirementOptions: {
    annuity: 'Annuity',
    drawdown: 'Drawdown',
  },
};
