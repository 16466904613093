import React, { useEffect, useState, useRef } from 'react';
import { useUiOptions, useUpdateUiOptions } from '../../../store';

import './money_input.scss';
import Formatter from '../../utils/formatter';

type MoneyInputProps = {
  value: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  hideAYear?: boolean;
  cyName?: string;
  labelledBy?: string;
};

const MoneyInput = ({
  value,
  onChange,
  min = 0,
  max = 1_000_000_000,
  hideAYear,
  cyName,
  labelledBy = '',
}: MoneyInputProps) => {
  const [formValue, setFormValue] = useState<number>(value);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [hasBlurred, setHasBlurred] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement>(null);
  const formatter = new Formatter();

  const { yourDetailsErrorFields } = useUiOptions();
  const updateUiOptions = useUpdateUiOptions();

  const belowMinError = `This must be at least ${formatter.asCurrencyWithoutPence(min)}`;
  const aboveMaxError = `This must be less than ${formatter.asCurrencyWithoutPence(max)}`;

  const errorMessage: string = (() => {
    if (value < min) return belowMinError;
    if (value > max) return aboveMaxError;
    return '';
  })();

  const addErrorOnField = (field: string) => {
    if (yourDetailsErrorFields.indexOf(field) === -1) {
      yourDetailsErrorFields.push(field);
    }
  };

  const removeErrorFromField = (removalField: string) => {
    const idx = yourDetailsErrorFields.indexOf(removalField);
    yourDetailsErrorFields.splice(idx, idx !== -1 ? 1 : 0);
  };

  useEffect(() => {
    if (ref.current) {
      ref.current.setCustomValidity('');
      addErrorOnField(labelledBy);

      if (value < min) {
        ref.current.setCustomValidity(belowMinError);
      } else if (value > max) {
        ref.current.setCustomValidity(aboveMaxError);
      } else {
        removeErrorFromField(labelledBy);
      }

      updateUiOptions({ hasEnteredValidUserDetails: yourDetailsErrorFields.length === 0 });
    }
  }, [value, formValue, hasBlurred]);

  useEffect(() => {
    setFormValue(value);
  }, [value]);

  useEffect(() => {
    if (isEditing && ref.current) ref.current.select();
  }, [isEditing]);

  return (
    <div className="money-input-wrapper">
      <input
        id={labelledBy}
        min={min}
        data-value={value}
        data-cy={cyName}
        ref={ref}
        value={isEditing ? formValue : formatter.asCurrencyWithoutPence(value)}
        type={isEditing ? 'number' : 'text'}
        aria-labelledby={labelledBy}
        onFocus={() => {
          setIsEditing(true);
          if (ref.current) ref.current.select();
        }}
        className={`money-input ${hasBlurred && errorMessage && 'errored'}`}
        inputMode="numeric"
        onChange={(event) => setFormValue(Number(event.target.value))}
        onBlur={(event) => {
          const relatedTarget: any = event.relatedTarget || (event as any).explicitOriginalTarget;
          if (relatedTarget === null) return;
          if (
            !relatedTarget ||
            (!event.currentTarget.contains(relatedTarget) && ref.current && !ref.current.contains(relatedTarget))
          ) {
            setHasBlurred(true);
            setIsEditing(false);
            onChange(formValue);
          }
        }}
      />
      {!hideAYear && <span className="money-input-wrapper__timeframe">a year</span>}
      {hasBlurred && errorMessage && (
        <ul className="input-validation-errors">
          <li key={`money-${errorMessage}}`}>{errorMessage}</li>
        </ul>
      )}
    </div>
  );
};

export default MoneyInput;
