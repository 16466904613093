import { AdvancedDate as AdvDate } from '@lifetime-gallagher/calculator-toolkit';

export default class DateFormatter {
  // Javascript date formatting can be tricky. new Date('1957-07-11') gives
  // the correct date of 11 July 1957, but create it as new Date(1957,7,11)
  // it can take local GMT/UTC and you can end up with 1957-07-10T23:00:00.000Z
  // and this can cause problems when measuring date differences.
  //
  // This formats the date inputs into a single string to remove this issue.
  //
  // When using AdvDate, you have to first wrap it in a Date avoid the
  // same error. Even creating an AdvDate as a string will have that error
  // and only creating an AdvDate from a Date from a single string will
  // avoid this issue.
  //
  // The month param has to be as per the Javascript numbering
  // e.g. Jan = 0, Feb = 1 etc.
  public isoFormat(year: number, month: number, day: number) {
    const monthPadding = month + 1 < 10 ? '0' : '';
    const dayPadding = day < 10 ? '0' : '';
    return new Date(`${year}-${monthPadding}${month + 1}-${dayPadding}${day}`);
  }

  public advDate(year: number, month: number, day: number) {
    return new AdvDate(this.isoFormat(year, month, day));
  }

  // Date is assumed to be in the format dd/mm/yyyy
  public advDateFromString(dayMonthYear: string) {
    // improvement would be to check tht what is passed is indeed in dd/mm/yyyy format and error if not.
    const dateArray = dayMonthYear.split('/');
    const year = parseInt(dateArray[2], 10);
    const month = parseInt(dateArray[1], 10) - 1;
    const day = parseInt(dateArray[0], 10);
    return this.advDate(year, month, day);
  }
}
