export default class formatter {
  public asCurrencyWithoutPence(amount: number) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }

  public asCurrencyWithPence(amount: number) {
    return new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(amount);
  }

  public asGbpWithPence(amount: number) {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(amount);
  }

  public asPercent(amount: number, decimalPlaces: number) {
    return (Math.round(amount * 10000) / 100).toFixed(decimalPlaces) + '%';
  }

  public dateStringToIsoFormat(date: string) {
    // Return the date if it's already in ISO format.
    if (date.split('-').length > 1) return date;

    const dataArray = date.split('/');
    return dataArray[2] + '-' + dataArray[1] + '-' + dataArray[0];
  }

  public dateStringToDdmmyyyyFormat(date: string) {
    // Return the date if it's already in dd/mm/yyyy format.
    if (date.split('/').length > 1) return date;

    const dataArray = date.split('-');
    return dataArray[2] + '/' + dataArray[1] + '/' + dataArray[0];
  }

  public twoDp(value: number) {
    return (Math.round(value * 1000) / 1000).toFixed(2);
  }
}
