const constants = {
  STRING: 'string',
  DMY_DATE_STRING: 'dmy_date_string',
  ISO_DATE_STRING: 'iso_date_string',
  FLOAT: 'float',
  BOOLEAN: 'boolean',
  INT: 'int',
  ENGINE_START_AGE: 16,
  ENGINE_END_AGE: 70,
};

export default constants;
