import React, { useEffect } from 'react';
import {
  useUserData,
  useUpdateUserData,
  useUpdatePensionValuationOutput,
  useUpdateAnnualAllowanceOutput,
  useUpdateLtaOutput,
} from '../../store';
import Engine from '../../engine/engine';
import AnnualAllowance from '../../engine/annualAllowance';
import Parameters from '../../engine/parameters';
import defaultEngineData from '../../engine/config/engine.json';

type EngineRunnerProps = {
  children?: React.ReactNode;
};

const EngineRunner = ({ children }: EngineRunnerProps) => {
  const userData = useUserData();
  const {
    dateOfBirth,
    retirementAge,
    currentAccrualRate,
    dcContributionRate1,
    flexRate1,
    currentAge,
    atBankUntilRetirement,
  } = useUserData();

  const updateUserData = useUpdateUserData();
  const updatePensionValuationOutput = useUpdatePensionValuationOutput();
  const updateAnnualAllowanceOutput = useUpdateAnnualAllowanceOutput();
  const updateLtaOutput = useUpdateLtaOutput();

  const userDataInEngineFormat = () => {
    const formattedDateOfBirth = new Date(userData.dateOfBirth).toLocaleDateString('en-GB');
    const formattedDateOfTrs = new Date(userData.effectiveDateOfTotalRewardStatement).toLocaleDateString('en-GB');
    const hasSelectedNewDcContRateOrAccrualRate = dcContributionRate1 > 0 || flexRate1 !== currentAccrualRate;

    return {
      ...userData,
      membership: userData.membershipType === 'existingMember' ? 1 : 0,
      dateOfBirth: formattedDateOfBirth,
      effectiveDateOfTotalRewardStatement: formattedDateOfTrs,
      termToDateOfLeaving: atBankUntilRetirement ? null : userData.termToDateOfLeaving,
      flexRate2: hasSelectedNewDcContRateOrAccrualRate ? userData.flexRate2 : userData.flexRate1,
      dcContributionRate2: hasSelectedNewDcContRateOrAccrualRate ? userData.dcContributionRate2 : 0,
    };
  };

  const engineParameters = new Parameters({
    ...defaultEngineData,
    person: userDataInEngineFormat(),
  });

  useEffect(
    function setDefaultRetirementAgeOnDobChange() {
      updateUserData({
        currentAge: Math.floor(engineParameters.ageNowRounded()),
        retirementAge: engineParameters.standardRetirementAge(),
      });
    },
    [dateOfBirth]
  );

  useEffect(
    function setDefaultRetirementAgeOnReset() {
      if (retirementAge === null)
        updateUserData({
          retirementAge: engineParameters.standardRetirementAge(),
        });
    },
    [retirementAge]
  );

  useEffect(
    function setDefaultFlexRatesOnCurrentAccrualRateChange() {
      updateUserData({
        flexRate1: currentAccrualRate,
        flexRate2: currentAccrualRate,
      });
    },
    [currentAccrualRate]
  );

  useEffect(
    function resetDefaultAndMaximumTermsOnAgeChange() {
      const newMaxFlexTerm = retirementAge - currentAge;

      updateUserData({
        flexTerm: newMaxFlexTerm,
        maxFlexTerm: newMaxFlexTerm,
        termToDateOfLeaving: Math.min(1, newMaxFlexTerm),
      });
    },
    [retirementAge, currentAge]
  );

  useEffect(
    function updateEngineOutputOnUserDataChange() {
      const engine = new Engine(engineParameters, false);
      const engineOutput = engine.run();

      updatePensionValuationOutput(engineOutput);
      updateLtaOutput({
        userSummary: engine.lta(engineOutput),
        ltaStandardValue: engineParameters.lta(engineParameters.standardRetirementAge()),
        ltaAlternativeValue: engineParameters.lta(Number(engineParameters.person.retirementAge)),
      });

      updateAnnualAllowanceOutput(new AnnualAllowance(engineParameters, engine.run(), false).run());
    },
    [userData]
  );

  return <div>{children}</div>;
};

export default EngineRunner;
