import React from 'react';
import { Provider } from 'react-redux';
import store from './store';

import BaseLayout from './components/base_layout';
import EngineRunner from './components/engine_runner/engine_runner';

import './css/App.scss';

import { HashRouter } from 'react-router-dom';

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <EngineRunner>
          <HashRouter>
            <BaseLayout />
          </HashRouter>
        </EngineRunner>
      </Provider>
    </div>
  );
};

export default App;
