import ExcelFunctions from './lib/excelFunctions';
import Status from './lib/status';
import BenefitType from './lib/benefitType';
import Membership from './lib/membership';
import SalaryScale from './tables/salaryScale';
import AnnuityTable from './tables/annuityTable';
import ErfTable from './tables/erfTable';
import LrfTable from './tables/lrfTable';
import FlexRateTable from './tables/flexRateTable';
import Parameters from './parameters';
import constants from './constants';
import dateConstants from './lib/dateConstants';
import DateFormatter from './lib/dateFormatter';
// Output results / objects:
import OutputPensionResults from './output/pension/results';
import OutputPensionBaseline from './output/pension/baseline';
import OutputPensionScenario from './output/pension/scenario';
import OutputPensionCore from './output/pension/core';
import OutputLtaResults from './output/lta/results';
import OutputLtaBaseline from './output/lta/baseline';
import OutputLtaScenario from './output/lta/scenario';
import OutputLtaCore from './output/lta/core';

export default class Engine {
  private monthsToRun: number;
  public debug: Boolean;
  private salaryScale: SalaryScale;
  private annuityTable: AnnuityTable;
  private erfTable: ErfTable;
  private lrfTable: LrfTable;
  private flexRateTable: FlexRateTable;
  private excelFunctions: ExcelFunctions;
  private parameters: Parameters;
  private baseline: OutputPensionCore;
  private dateFormatter: DateFormatter;

  private salaryScales: number[];
  private unroundedAges: number[];
  private ages: number[];
  private salaries: number[];
  private statuses: number[];
  private careAccruals: number[];
  private baselineInServiceRevaluations: number[];
  private inServiceRevaluations: number[];

  constructor(parameters: Parameters, debug: Boolean) {
    // optionally pass a debug parameter to trigger break points or logging
    // during specific test cases when batch testing
    this.debug = typeof debug == 'undefined' ? false : debug;
    this.monthsToRun = (constants.ENGINE_END_AGE - constants.ENGINE_START_AGE) * dateConstants.MONTHS_IN_YEAR;
    this.parameters = parameters;
    this.salaryScale = new SalaryScale(parameters.salaryScale.startAge, parameters.salaryScale.scale);
    this.annuityTable = new AnnuityTable(parameters.assumptions.calculationDate, parameters.annuity.annuities);
    this.erfTable = new ErfTable(parameters.erf.rpi, parameters.erf.cpi);
    this.lrfTable = new LrfTable(parameters.lrf.startAge, parameters.lrf.rpi, parameters.lrf.cpi);
    this.flexRateTable = new FlexRateTable(parameters.flexRates.rates);
    this.baseline = new OutputPensionCore();
    this.excelFunctions = new ExcelFunctions();
    this.dateFormatter = new DateFormatter();

    this.salaryScales = [];
    this.unroundedAges = [];
    this.ages = [];
    this.salaries = [];
    this.statuses = [];
    this.careAccruals = [];
    this.baselineInServiceRevaluations = [];
    this.inServiceRevaluations = [];
  }

  public standardRetirementAge() {
    return this.parameters.standardRetirementAge();
  }

  public ageNow() {
    return this.parameters.getAgeNow();
  }

  public ageNowRounded() {
    return this.parameters.ageNowRounded();
  }

  public ageNowMonth() {
    return this.parameters.ageNowMonth();
  }

  public earlyRetirementFactor() {
    if (this.parameters.standardRetirementAge() >= this.parameters.assumptions.futureServiceNormalRetirementAge) {
      return 1;
    }

    return this.erfTable.getErf(
      this.parameters.assumptions.futureServiceNormalRetirementAge - this.parameters.standardRetirementAge(),
      this.erfTable.CPI_TABLE
    );
  }

  // Jan 2022 update. Introduction of Pre and Post 20 Care tranches. Need to calculate ERF for Post 20 tranche.
  public earlyRetirementFactorPost20() {
    return this.standardRetirementAge() >= this.parameters.assumptions.pastServiceNormalRetirementAgePost20
      ? 1
      : this.erfTable.getErf(
          this.parameters.assumptions.pastServiceNormalRetirementAgePost20 - this.standardRetirementAge(),
          this.erfTable.CPI_TABLE
        );
  }

  public lateRetirementFactor() {
    if (this.parameters.standardRetirementAge() <= this.parameters.assumptions.futureServiceNormalRetirementAge) {
      return 1;
    }

    return (
      this.lrfTable.getLrf(this.parameters.standardRetirementAge(), this.lrfTable.CPI_TABLE) *
      Math.pow(
        1 + this.parameters.assumptions.cpi,
        this.parameters.standardRetirementAge() - this.parameters.assumptions.futureServiceNormalRetirementAge
      )
    );
  }

  public getSalaryScale(age: number, ageNowMonth: number, month: number) {
    //January - 1 month is December....
    if (month != (ageNowMonth == 1 ? 12 : ageNowMonth - 1)) {
      return null;
    }

    return this.salaryScale.getScale(Math.floor(age));
  }

  public status(age: number, ageNow: number, retirementAge: number, termToDateOfLeaving: number) {
    if (age < ageNow || age >= retirementAge) {
      return Status.NONE;
    } else if (termToDateOfLeaving === null || age < ageNow + termToDateOfLeaving) {
      return Status.IN_SERVICE;
    }
    return Status.IN_DEFERMENT;
  }

  public projectBaselineSalary(salaries: number[], salaryIncreaseRate: number, index: number) {
    // WARN: Excel adds 0.001 to standardRetirementAge during this check....
    if (this.ages[index] >= this.standardRetirementAge() + 0.001) {
      return 0;
    }
    return this.projectSalary(salaries, salaryIncreaseRate, index);
  }

  public projectSalary(salaries: number[], salaryIncreaseRate: number, index: number) {
    if (this.ages[index] < this.ageNowRounded()) {
      return 0;
    }
    if (this.ages[index] == this.ageNowRounded()) {
      const salary = (this.parameters.person.salary / dateConstants.MONTHS_IN_YEAR) * (1 + salaryIncreaseRate);
      return salary * (index === 0 ? 1 : 1 + this.salaryScales[index - 1]);
    } else {
      if (this.salaryScales[index] === null) {
        return salaries[index - 1];
      } else {
        return salaries[index - 1] * (1 + this.salaryScales[index]) * (1 + salaryIncreaseRate);
      }
    }
  }

  public flexedRate(
    age: number,
    ageNow: number,
    retirementAge: number,
    flexTerm: number,
    rate1: number,
    rate2: number
  ) {
    if (age < ageNow || age >= retirementAge) {
      return 0;
    }
    if (flexTerm === null || age < flexTerm + ageNow) {
      return rate1;
    } else {
      return rate2;
    }
  }

  public careInServiceAccrual(retirementAge: number, accrualRate: number, index: number) {
    if (this.statuses[index] === Status.IN_SERVICE) {
      return 0;
    }

    return this.careAccrual(retirementAge, accrualRate, index);
  }

  public careAccrual(retirementAge: number, accrualRate: number, index: number) {
    if (index === 0 || this.ages[index] >= retirementAge || accrualRate === 0) {
      return 0;
    }

    if (this.ages[index] == this.ageNowRounded()) {
      return this.salaries[index] / accrualRate;
    } else {
      return this.salaries[index - 1] / accrualRate;
    }
  }

  public inServiceRevaluation(retirementAge: number, status: number, inServiceRevaluation: number[], index: number) {
    if (status === Status.IN_SERVICE && this.ages[index] >= this.ageNowRounded() && this.ages[index] < retirementAge) {
      if (this.ages[index] == this.ageNowRounded()) {
        return this.parameters.assumptions.calculationDate.getMonth() + 1;
      } else if (inServiceRevaluation[index - 1] !== null) {
        // no need for 0 index special case. age == ageNow condition will be hit first
        return inServiceRevaluation[index - 1] == 12 ? 1 : inServiceRevaluation[index - 1] + 1;
      }
    }
    return null;
  }

  public inDefermentRevaluationMonth(
    age: number,
    ageNow: number,
    retirementAge: number,
    status: number,
    inServiceRevaluation: number[],
    revaluation: number[],
    calculationDate: any,
    index: number
  ) {
    if (status === Status.IN_DEFERMENT && age >= ageNow && age < retirementAge) {
      if (inServiceRevaluation[index - 1] !== null && inServiceRevaluation[index] === null) {
        return calculationDate.getMonth() + 1;
      } else if (revaluation[index - 1] !== null) {
        // no need for 0 index special case. age == ageNow condition will be hit first
        return revaluation[index - 1] == 12 ? 1 : revaluation[index - 1] + 1;
      }
    }
    return null;
  }

  public post65AtNormalRetirementAge(
    age: number,
    standardRetirementAge: number,
    rpi: number,
    careAccrual: number,
    inServiceRevaluation: number[],
    index: number
  ) {
    if (age < standardRetirementAge) {
      return 0;
    } else {
      return this.revalue(
        careAccrual,
        rpi,
        this.excelFunctions.countIf(inServiceRevaluation.slice(index + 1), dateConstants.REAL_APRIL)
      );
    }
  }

  public dcContributionPounds(percent: number, salary: number) {
    return salary * percent;
  }

  public dcFundAtNormalRetirementAge(
    age: number,
    contribution: number,
    investmentReturn: number,
    retirementAge: number
  ) {
    return this.revalue(contribution, investmentReturn, retirementAge - age);
  }

  public pastDcFund(
    age: number,
    ageNow: number,
    ageNowRounded: number,
    benefitType: number,
    dcFund: number,
    investmentReturn: number
  ) {
    if (benefitType == BenefitType.BENEFIT_95 && age >= ageNowRounded) {
      return this.revalue(dcFund, investmentReturn, age - ageNow);
    }
    return 0;
  }

  public revalue(value: number, rate: number, time: number) {
    return value * Math.pow(1 + rate, time);
  }

  public revalueFromStatementDateToCalculationDate(value: number, salary: number, accrualRate: number) {
    return value + (this.parameters.periodBetweenStatementDateAndCalculationDate() * salary) / accrualRate;
  }

  public futureDcFund(fund: number[], investmentReturn: number, contribution: number, index: number) {
    return index === 0
      ? contribution
      : this.revalue(fund[index - 1], investmentReturn, 1 / dateConstants.MONTHS_IN_YEAR) + contribution;
  }

  public defiendBenefitRevaluation(
    value: number,
    inServiceRevaluation: number,
    inDefermentRevaluation: number,
    revaluationMonth: number,
    revaluationRate: number[]
  ) {
    return (
      value *
      (inServiceRevaluation == revaluationMonth ? 1 + revaluationRate[0] : 1) *
      (inDefermentRevaluation == revaluationMonth ? 1 + revaluationRate[1] : 1)
    );
  }

  public careRevaluation(
    age: number,
    ageNow: number,
    carePension: number,
    care: number[],
    inServiceRevaluation: number,
    inDefermentRevaluation: number,
    revaluationRates: number[],
    index: number
  ) {
    if (index === 0) {
      return age == ageNow ? carePension * (1 + revaluationRates[2]) : 0;
    }

    return (
      this.defiendBenefitRevaluation(
        care[index - 1],
        inServiceRevaluation,
        inDefermentRevaluation,
        dateConstants.REAL_APRIL,
        revaluationRates
      ) + (age == ageNow ? carePension * (1 + revaluationRates[2]) : 0)
    );
  }

  public finalSalaryRevaluation(
    age: number,
    ageNow: number,
    finalSalaryTransferInPension: number,
    finalSalaryTransferIn: number[],
    inServiceRevaluation: number,
    inDefermentRevaluation: number,
    revaluationRates: number[],
    index: number
  ) {
    if (index === 0) {
      return age == ageNow ? finalSalaryTransferInPension : 0;
    }

    return (
      this.defiendBenefitRevaluation(
        finalSalaryTransferIn[index - 1],
        inServiceRevaluation,
        inDefermentRevaluation,
        dateConstants.REAL_JULY,
        revaluationRates
      ) + (age == ageNow ? finalSalaryTransferInPension : 0)
    );
  }

  public futureCare(
    futureCare: number[],
    inServiceRevaluation: number,
    inDefermentRevaluation: number,
    careAccrual: number,
    cpi: number,
    rpi: number,
    index: number
  ) {
    return index === 0
      ? careAccrual
      : this.defiendBenefitRevaluation(
          futureCare[index - 1],
          inServiceRevaluation,
          inDefermentRevaluation,
          dateConstants.REAL_APRIL,
          [rpi, cpi]
        ) + careAccrual;
  }

  //NOTE: slowest function by far during engine execution
  public pastFinalSalary(
    benefitType: number,
    status: number,
    salary: number,
    finalSalaryRevaluation: number[],
    pastServiceFinalSalaryPension: number,
    finalSalaryAtDateOfLeaving: number,
    salaryAtDateOfCalculation: number,
    salaryIncreaseRate: number,
    salaryIncreaseRateBase: number,
    salaryScale: SalaryScale,
    age: number,
    rpi: number,
    index: number
  ) {
    if (benefitType == BenefitType.BENEFIT_50 && status != Status.NONE) {
      var value = 0;
      var revaluationAmount = (1 + salaryIncreaseRateBase) * (1 + salaryScale.getScale(Math.floor(age)));

      if (status == Status.IN_SERVICE) {
        value =
          (pastServiceFinalSalaryPension * revaluationAmount * (salary * dateConstants.MONTHS_IN_YEAR)) /
          salaryAtDateOfCalculation;
      } else if (status == Status.IN_DEFERMENT) {
        value =
          (pastServiceFinalSalaryPension * revaluationAmount * finalSalaryAtDateOfLeaving) / salaryAtDateOfCalculation;
      }

      var revaluationPeriods = this.excelFunctions.countIf(
        finalSalaryRevaluation.slice(0, index + 1),
        dateConstants.REAL_JULY
      );

      return (
        this.revalue(value, rpi, revaluationPeriods) /
        (revaluationPeriods >= 1 ? Math.min(1 + salaryIncreaseRate, 1 + rpi) : 1)
      );
    }
    return 0;
  }

  public finalSalaryAtDateOfLeaving(salary: number[], status: number[]) {
    //WARN: what happens if 'in deferment' happens in the first year?
    // this will return a 0 salary at date of leaving....
    var finalSalaryAtDateOfLeaving =
      this.excelFunctions.index(
        salary,
        this.excelFunctions.match(Status.IN_DEFERMENT, status, this.excelFunctions.EXACT) - 1
      ) * dateConstants.MONTHS_IN_YEAR;

    return this.excelFunctions.ifError(finalSalaryAtDateOfLeaving, 0);
  }

  public salaryAtAge(salary: number[], age: number, ageRange: number[]) {
    return (
      this.excelFunctions.index(salary, this.excelFunctions.match(age, ageRange, this.excelFunctions.EXACT) - 1) *
      dateConstants.MONTHS_IN_YEAR
    );
  }

  public futurePre65PensionAtRetirementAgeFutureTerms(careValueAtNormalRetirementAge: number[]) {
    return (
      this.excelFunctions.sumIf(
        this.ages,
        function (test: number) {
          return test < 65;
        },
        careValueAtNormalRetirementAge
      ) *
      this.earlyRetirementFactor() *
      this.lateRetirementFactor()
    );
  }

  public salaryAtDateOfCalculation() {
    return (
      this.excelFunctions.lookup(this.ageNowRounded(), this.ages, this.salaries, this.excelFunctions.LESS) *
      dateConstants.MONTHS_IN_YEAR
    );
  }

  public salaryAtStandardRetirementAge() {
    return this.ageNow() >= 65
      ? this.parameters.person.salary
      : this.parameters.person.standardAccrualRate == BenefitType.BENEFIT_50 && this.ageNowRounded() == 60
      ? this.salaryAtDateOfCalculation()
      : this.salaryAtAge(this.salaries, this.parameters.standardRetirementAge(), this.ages);
  }

  public calculateBaseline() {
    var careValueAtNormalRetirementAge: number[];
    var months: number[];
    var debugSalaryProjection: any[];

    careValueAtNormalRetirementAge = [];
    months = [1];
    debugSalaryProjection = [];

    for (var i = 0; i <= this.monthsToRun; i++) {
      this.unroundedAges[i] = constants.ENGINE_START_AGE + i / dateConstants.MONTHS_IN_YEAR;
      this.ages[i] = this.excelFunctions.round(this.unroundedAges[i], 2);
      this.salaryScales[i] = this.getSalaryScale(this.ages[i], this.ageNowMonth(), months[i]);
      this.salaries[i] = this.projectBaselineSalary(this.salaries, this.parameters.assumptions.salaryIncreaseRate, i);
      this.careAccruals[i] = this.careAccrual(this.standardRetirementAge(), this.parameters.currentAccrualRate(), i);
      this.baselineInServiceRevaluations[i] = this.inServiceRevaluation(
        this.standardRetirementAge(),
        Status.IN_SERVICE,
        this.baselineInServiceRevaluations,
        i
      );

      //we have an integer based month counter to avoid floating point crazyness to determine month of the year
      months[i + 1] = months[i] == 12 ? 1 : months[i] + 1;
    }

    // a secondary set of calculations that require a full history of outputs as parameters
    for (var j = 0; j <= this.monthsToRun; j++) {
      careValueAtNormalRetirementAge[j] = this.revalue(
        this.careAccruals[j],
        this.parameters.assumptions.rpi,
        this.excelFunctions.countIf(this.baselineInServiceRevaluations.slice(j + 1), dateConstants.REAL_APRIL)
      );
    }

    var futurePre65PensionAtRetirementAgeCurrentTerms =
      (this.futurePre65PensionAtRetirementAgeFutureTerms(careValueAtNormalRetirementAge) *
        this.salaryAtDateOfCalculation()) /
      this.salaryAtStandardRetirementAge();

    var futurePost65PensionAtRetirementAgeFutureTerms = this.excelFunctions.sumIf(
      this.ages,
      function (test: number) {
        return test >= 65;
      },
      careValueAtNormalRetirementAge
    );

    var futurePost65PensionAtRetirementAgeCurrentTerms =
      (futurePost65PensionAtRetirementAgeFutureTerms * this.salaryAtDateOfCalculation()) /
      this.salaryAtStandardRetirementAge();

    this.baseline.ages = this.ages;
    this.baseline.unroundedAges = this.unroundedAges;
    this.baseline.salaries = this.salaries;
    this.baseline.salaryScales = this.salaryScales;
    this.baseline.careAccruals = this.careAccruals;
    this.baseline.inServiceRevaluations = this.baselineInServiceRevaluations;
    this.baseline.careValueAtNormalRetirementAge = careValueAtNormalRetirementAge;
    this.baseline.futurePre65PensionAtRetirementAgeFutureTerms = this.futurePre65PensionAtRetirementAgeFutureTerms(
      careValueAtNormalRetirementAge
    );

    this.baseline.salaryAtDateOfCalculation = this.salaryAtDateOfCalculation();
    this.baseline.salaryAtStandardRetirementAge = this.salaryAtStandardRetirementAge();
    this.baseline.futurePre65PensionAtRetirementAgeCurrentTerms = futurePre65PensionAtRetirementAgeCurrentTerms;
    this.baseline.futurePost65PensionAtRetirementAgeFutureTerms = futurePost65PensionAtRetirementAgeFutureTerms;
    this.baseline.futurePost65PensionAtRetirementAgeCurrentTerms = futurePost65PensionAtRetirementAgeCurrentTerms;
    this.baseline.month = months;

    return this.baseline;
  }

  public run() {
    let notionalSalary: number[];
    let flexedRate: number[];
    let inDefermentRevaluation: number[];
    let finalSalaryRevaluation: number[];
    let post65AtNormalRetirementAge: number[];
    let dcContributionPercent: number[];
    let dcContributionPounds: number[];
    let dcFundAtNormalRetirementAge: number[];
    let careTransferIn: number[];
    let pastCare: number[];
    let pastCarePost20: number[];
    let futureCare: number[];
    let finalSalaryTransferIn: number[];
    let pastFinalSalary: number[];
    let careValueAtNormalRetirementAge: number[];
    let months: number[];
    let debugBuildUp: string[];

    notionalSalary = [];
    flexedRate = [];
    inDefermentRevaluation = [];
    finalSalaryRevaluation = [];
    post65AtNormalRetirementAge = [];
    dcContributionPercent = [];
    dcContributionPounds = [];
    dcFundAtNormalRetirementAge = [];
    careTransferIn = [];
    pastCare = [];
    pastCarePost20 = [];
    futureCare = [];
    finalSalaryTransferIn = [];
    pastFinalSalary = [];
    careValueAtNormalRetirementAge = [];
    months = [1];
    debugBuildUp = [];

    this.calculateBaseline();

    //shortcuts from baseline calculation
    // var age = this.baseline.age;

    //summations from baseLine calculation
    var annuity = this.annuityTable.getAnnuity(this.standardRetirementAge(), this.parameters.yearOfBirth());

    //phase 3, dcFund no longer required
    // still in Excel model so keeping if required in the future
    var dcFund = 0;
    var careAccruedPension = this.parameters.person.pastServiceCarePension;
    var careAccruedPensionPost20 = this.parameters.person.pastServiceCarePensionPost20;

    // Jan 2022 update. Introduction of Pre and Post 20 Care tranches. Need to calculate ERF for Post 20 tranche.
    var pastServiceNRAPost20 = this.parameters.assumptions.pastServiceNormalRetirementAgePost20;

    //main loop. Loop through each month from age 16 to age 70
    // maintain integer index for look-ups to avoid JavaScript float randomness
    // (Excel model used age as index - i.e. fractional years)
    for (var i = 0; i <= this.monthsToRun; i++) {
      this.statuses[i] = this.status(
        this.ages[i],
        this.ageNowRounded(),
        this.parameters.person.retirementAge,
        this.parameters.person.termToDateOfLeaving
      );

      notionalSalary[i] = this.projectSalary(notionalSalary, this.parameters.person.salaryIncreaseRate, i);

      this.salaries[i] =
        this.statuses[i] === Status.IN_SERVICE && this.ages[i] < this.parameters.person.retirementAge
          ? this.projectSalary(this.salaries, this.parameters.person.salaryIncreaseRate, i)
          : 0;

      flexedRate[i] = this.flexedRate(
        this.ages[i],
        this.ageNowRounded(),
        this.parameters.person.retirementAge,
        this.parameters.person.flexTerm,
        this.parameters.person.flexRate1,
        this.parameters.person.flexRate2
      );

      this.careAccruals[i] =
        this.statuses[i] === Status.IN_SERVICE
          ? this.careAccrual(this.parameters.person.retirementAge, flexedRate[i], i)
          : 0;

      this.inServiceRevaluations[i] = this.inServiceRevaluation(
        this.parameters.person.retirementAge,
        this.statuses[i],
        this.inServiceRevaluations,
        i
      );

      inDefermentRevaluation[i] = this.inDefermentRevaluationMonth(
        this.ages[i],
        this.ageNowRounded(),
        this.parameters.person.retirementAge,
        this.statuses[i],
        this.inServiceRevaluations,
        inDefermentRevaluation,
        this.parameters.assumptions.calculationDate,
        i
      );

      finalSalaryRevaluation[i] = this.inDefermentRevaluationMonth(
        this.ages[i],
        this.ageNowRounded(),
        this.parameters.person.retirementAge,
        this.statuses[i],
        this.inServiceRevaluations,
        finalSalaryRevaluation,
        this.parameters.assumptions.calculationDate,
        i
      );

      dcContributionPercent[i] = this.flexedRate(
        this.ages[i],
        this.ageNowRounded(),
        this.parameters.person.retirementAge,
        this.parameters.person.flexTerm,
        this.parameters.person.dcContributionRate1,
        this.parameters.person.dcContributionRate2
      );

      dcContributionPounds[i] = this.dcContributionPounds(dcContributionPercent[i], this.salaries[i]);

      dcFundAtNormalRetirementAge[i] = this.dcFundAtNormalRetirementAge(
        this.unroundedAges[i],
        dcContributionPounds[i],
        this.parameters.person.investmentReturn,
        this.parameters.person.retirementAge
      );

      careTransferIn[i] =
        this.parameters.person.standardAccrualRate == BenefitType.BENEFIT_95
          ? this.careRevaluation(
              this.ages[i],
              this.ageNowRounded(),
              this.parameters.person.careTransferInPension,
              careTransferIn,
              this.inServiceRevaluations[i],
              inDefermentRevaluation[i],
              [this.parameters.assumptions.cpi, this.parameters.assumptions.cpi, this.parameters.assumptions.cpi],
              i
            )
          : 0;

      //phase 3, now includes revalued CARE
      // Feb 2022, splits CARE into Pre and Post 20
      pastCare[i] = this.careRevaluation(
        this.ages[i],
        this.ageNowRounded(),
        careAccruedPension,
        pastCare,
        this.inServiceRevaluations[i],
        inDefermentRevaluation[i],
        [this.parameters.assumptions.rpi, this.parameters.assumptions.cpi, this.parameters.assumptions.rpi],
        i
      );

      pastCarePost20[i] = this.careRevaluation(
        this.ages[i],
        this.ageNowRounded(),
        this.revalueFromStatementDateToCalculationDate(
          careAccruedPensionPost20,
          this.parameters.person.salary,
          this.parameters.person.membership == Membership.NEW_JOINER
            ? this.parameters.person.flexRate1
            : this.parameters.currentAccrualRate()
        ),
        pastCarePost20,
        this.inServiceRevaluations[i],
        inDefermentRevaluation[i],
        [this.parameters.assumptions.rpi, this.parameters.assumptions.cpi, this.parameters.assumptions.rpi],
        i
      );

      futureCare[i] = this.futureCare(
        futureCare,
        this.inServiceRevaluations[i],
        inDefermentRevaluation[i],
        this.careAccruals[i],
        this.parameters.assumptions.cpi,
        this.parameters.assumptions.rpi,
        i
      );

      if (this.debug && this.careAccruals[i] > 0) {
        // console.log('futureCare[i]', futureCare[i]);
        // console.log('this.careAccruals[i]', Math.round(this.careAccruals[i]));
      }

      finalSalaryTransferIn[i] =
        this.parameters.person.standardAccrualRate == BenefitType.BENEFIT_50
          ? this.finalSalaryRevaluation(
              this.ages[i],
              this.ageNowRounded(),
              this.parameters.person.finalSalaryTransferInPension,
              finalSalaryTransferIn,
              this.inServiceRevaluations[i],
              inDefermentRevaluation[i],
              [this.parameters.assumptions.rpi, this.parameters.assumptions.rpi],
              i
            )
          : 0;
    }

    // if (this.debug) {
    //   console.log(debugBuildUp.join("\n"));
    // }

    //phase 3, this used to be a derived input, now explicit & revalued
    var pastServiceFinalSalaryPension =
      this.parameters.person.pastServiceFinalSalaryPension *
      (1 + this.parameters.assumptions.salaryIncreaseRate) *
      (1 + this.salaryScale.getScale(Math.floor(this.parameters.getAgeNow())));

      var finalSalaryAtDateOfLeaving = this.finalSalaryAtDateOfLeaving(this.salaries, this.statuses);

      var salaryAtRetirementAge =
      this.ageNow() >= 65
        ? this.parameters.person.salary
        : this.parameters.person.standardAccrualRate == BenefitType.BENEFIT_50 && this.ageNowRounded() == 60
        ? this.baseline.salaryAtDateOfCalculation
        : this.salaryAtAge(notionalSalary, this.parameters.person.retirementAge, this.ages);

    // a secondary set of calculations that require a full history of outputs as parameters
    for (var j = 0; j <= this.monthsToRun; j++) {
      post65AtNormalRetirementAge[j] = this.post65AtNormalRetirementAge(
        this.ages[j],
        this.parameters.assumptions.futureServiceNormalRetirementAge,
        this.parameters.assumptions.rpi,
        this.careAccruals[j],
        this.inServiceRevaluations,
        j
      );
      pastFinalSalary[j] = this.pastFinalSalary(
        this.parameters.person.standardAccrualRate,
        this.statuses[j],
        this.salaries[j],
        finalSalaryRevaluation,
        this.parameters.person.pastServiceFinalSalaryPension,
        finalSalaryAtDateOfLeaving,
        this.baseline.salaryAtDateOfCalculation,
        this.parameters.person.salaryIncreaseRate,
        this.parameters.assumptions.salaryIncreaseRate,
        this.salaryScale,
        this.parameters.getAgeNow(),
        this.parameters.assumptions.rpi,
        j
      );
    }

    //counfIf shortcuts
    var aprilRevaluationPeriods = this.excelFunctions.countIf(
      this.baseline.inServiceRevaluations,
      dateConstants.REAL_APRIL
    );

    var julyRevaluationPeriods = this.excelFunctions.countIf(
      this.baseline.inServiceRevaluations,
      dateConstants.REAL_JULY
    );

    //additional calculations required for output
    var baselinePastServicePensionAtRetirementAgeFutureTerms =
      (pastServiceFinalSalaryPension * this.baseline.salaryAtStandardRetirementAge) /
      this.baseline.salaryAtDateOfCalculation;

    //phase 3, person.finalSalaryTransferInPension is no longer conditional on CATEGORY (now BenefitType)
    // & finalSalaryTransferInPension needs revaluing (maybe)
    var baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeCurrentTerms =
      this.parameters.person.finalSalaryTransferInPension *
      (this.parameters.person.effectiveDateOfTotalRewardStatement.equals(
        this.dateFormatter.advDateFromString('05/04/2015')
      )
        ? 1 + this.parameters.assumptions.rpi
        : 1);

    var baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeFutureTerms = this.revalue(
      baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeCurrentTerms,
      this.parameters.assumptions.rpi,
      julyRevaluationPeriods
    );

    var baselinePastServiceCarePensionAtRetirementAgeCurrentTerms = careAccruedPension;

    var baselinePastServiceCarePensionAtRetirementAgeFutureTerms = this.revalue(
      baselinePastServiceCarePensionAtRetirementAgeCurrentTerms,
      this.parameters.assumptions.rpi,
      aprilRevaluationPeriods
    );

    var baselinePastServiceCarePensionAtRetirementAgeCurrentTermsPost20 =
      this.revalueFromStatementDateToCalculationDate(
        careAccruedPensionPost20,
        this.parameters.person.salary,
        this.parameters.currentAccrualRate()
      ) * this.earlyRetirementFactorPost20();

    var baselinePastServiceCarePensionAtRetirementAgeFutureTermsPost20 = this.revalue(
      baselinePastServiceCarePensionAtRetirementAgeCurrentTermsPost20,
      this.parameters.assumptions.rpi,
      aprilRevaluationPeriods
    );

    //phase 3, person.careTransferInPension is no longer conditional on CATEGORY (now BenefitType)
    var baselineAdditionalCareTransferInPensionAtRetirementAgeCurrentTerms = this.parameters.person
      .careTransferInPension;

    var baselineAdditionalCareTransferInPensionAtRetirementAgeFutureTerms = this.revalue(
      baselineAdditionalCareTransferInPensionAtRetirementAgeCurrentTerms,
      this.parameters.assumptions.cpi,
      aprilRevaluationPeriods
    );

    var baselinePastServiceDcFundAtRetirementAgeFutureTerms =
      this.parameters.person.standardAccrualRate == BenefitType.BENEFIT_95
        ? this.revalue(
            dcFund,
            this.parameters.assumptions.investmentReturn,
            this.standardRetirementAge() - this.ageNow()
          )
        : 0;

    var baselinePastServiceDcFundAtRetirementAgeCurrentTerms =
      (baselinePastServiceDcFundAtRetirementAgeFutureTerms * this.baseline.salaryAtDateOfCalculation) /
      this.baseline.salaryAtStandardRetirementAge;

    var baselineFutureServiceDcFundAtRetirementAgeFutureTerms = 0;

    var baselineFutureServiceDcFundAtRetirementAgeCurrentTerms =
      (baselineFutureServiceDcFundAtRetirementAgeFutureTerms * this.baseline.salaryAtStandardRetirementAge) /
      this.baseline.salaryAtDateOfCalculation;

    var baselineDcPensionAtRetirementAgeFutureTerms =
      (baselinePastServiceDcFundAtRetirementAgeFutureTerms + baselineFutureServiceDcFundAtRetirementAgeFutureTerms) /
      annuity;

    var baselineDcPensionAtRetirementAgeCurrentTerms =
      (baselineDcPensionAtRetirementAgeFutureTerms * this.baseline.salaryAtDateOfCalculation) /
      this.baseline.salaryAtStandardRetirementAge;

    //additional outputs required for online modeller output
    var baselineTotalPastServicePensionAtRetirementAgeCurrentTerms =
      pastServiceFinalSalaryPension +
      baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeCurrentTerms +
      baselinePastServiceCarePensionAtRetirementAgeCurrentTerms +
      baselinePastServiceCarePensionAtRetirementAgeCurrentTermsPost20 +
      baselineAdditionalCareTransferInPensionAtRetirementAgeCurrentTerms +
      baselineDcPensionAtRetirementAgeCurrentTerms;

    var baselineTotalFutureServicePensionAtRetirementAgeCurrentTerms =
      this.baseline.futurePre65PensionAtRetirementAgeCurrentTerms +
      this.baseline.futurePost65PensionAtRetirementAgeCurrentTerms;

    var baselineTotalPastServicePensionAtRetirementAgeFutureTerms =
      baselinePastServicePensionAtRetirementAgeFutureTerms +
      baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeFutureTerms +
      baselinePastServiceCarePensionAtRetirementAgeFutureTerms +
      baselinePastServiceCarePensionAtRetirementAgeFutureTermsPost20 +
      baselineAdditionalCareTransferInPensionAtRetirementAgeFutureTerms +
      baselineDcPensionAtRetirementAgeFutureTerms;

    var baselineTotalFutureServicePensionAtRetirementAgeFutureTerms =
      this.baseline.futurePre65PensionAtRetirementAgeFutureTerms +
      this.baseline.futurePost65PensionAtRetirementAgeFutureTerms;

    var baselineTotalPensionAtRetirementAgeFutureTerms =
      baselineTotalPastServicePensionAtRetirementAgeFutureTerms +
      baselineTotalFutureServicePensionAtRetirementAgeFutureTerms;

    var baselineTotalPensionAtRetirementAgeCurrentTerms =
      baselineTotalPastServicePensionAtRetirementAgeCurrentTerms +
      baselineTotalFutureServicePensionAtRetirementAgeCurrentTerms;

    var baselineReplacementRatioFutureTerms =
      baselineTotalPensionAtRetirementAgeFutureTerms / this.baseline.salaryAtStandardRetirementAge;

    var baselineReplacementRatioCurrentTerms =
      baselineTotalPensionAtRetirementAgeCurrentTerms / this.baseline.salaryAtDateOfCalculation;

    // added below Feb 2020 due to changes in how the ERFs/LRFs work
    // TODO: refactor into a retirementFactor() function(s)
    var futureServiceNRA = this.parameters.assumptions.futureServiceNormalRetirementAge;
    var pastServiceNRA = this.parameters.pastServiceNormalRetirementAge();
    var retirementAge = this.parameters.person.retirementAge;
    var pastServiceERF;
    var pastServiceERFPre20;
    var pastServiceERFPost20;
    var pastServiceLRFFS;

    // pastServiceERF used for FS (uses RPI)
    // pastServiceERFPre20 used for Pre 20 CARE (uses CPI)
    if (retirementAge >= pastServiceNRA) {
      pastServiceERF = 1;
      pastServiceERFPre20 = 1;
    } else {
      pastServiceERF = this.erfTable.getErf(pastServiceNRA - retirementAge, this.erfTable.RPI_TABLE);
      pastServiceERFPre20 = this.erfTable.getErf(pastServiceNRA - retirementAge, this.erfTable.CPI_TABLE);
    }

    // pastServiceERFPost20 used for Post 20 CARE
    if (retirementAge >= pastServiceNRAPost20) {
      pastServiceERFPost20 = 1;
    } else {
      pastServiceERFPost20 = this.erfTable.getErf(pastServiceNRAPost20 - retirementAge, this.erfTable.CPI_TABLE);
    }

    if (retirementAge <= pastServiceNRA) {
      pastServiceLRFFS = 1;
    } else {
      pastServiceLRFFS =
        this.parameters.getAgeNow() > 60
          ? 1
          : this.lrfTable.getLrf(retirementAge, this.erfTable.RPI_TABLE) *
            Math.pow(1 + this.parameters.assumptions.rpi, retirementAge - pastServiceNRA);
    }

    var pastServiceLRFCARE =
      retirementAge <= pastServiceNRAPost20
        ? 1
        : this.lrfTable.getLrf(retirementAge, this.lrfTable.CPI_TABLE) *
          Math.pow(1 + this.parameters.assumptions.cpi, Math.max(0, retirementAge - 65));

    var pastServiceRetirementFactorCARE = pastServiceERFPre20 * pastServiceLRFCARE;
    var pastServiceRetirementFactorCAREPost20 = pastServiceERFPost20 * pastServiceLRFCARE;
    var pastServiceRetirementFactorFS = pastServiceERF * pastServiceLRFFS;

    var futureServiceERF =
      retirementAge >= futureServiceNRA
        ? 1
        : this.erfTable.getErf(futureServiceNRA - retirementAge, this.erfTable.CPI_TABLE);

    var futureServiceLRF =
      retirementAge <= futureServiceNRA
        ? 1
        : this.lrfTable.getLrf(retirementAge, this.lrfTable.CPI_TABLE) *
          Math.pow(1 + this.parameters.assumptions.cpi, retirementAge - futureServiceNRA);

    var futureServiceRetirementFactor = futureServiceERF * futureServiceLRF;

    var scenarioPastServicePensionAtRetirementAgeFutureTerms =
      this.excelFunctions.index(
        pastFinalSalary,
        this.excelFunctions.match(this.parameters.person.retirementAge, this.ages, this.excelFunctions.EXACT) - 1
      ) * pastServiceRetirementFactorFS;

    var scenarioPastServicePensionAtRetirementAgeCurrentTerms =
      (scenarioPastServicePensionAtRetirementAgeFutureTerms * this.baseline.salaryAtDateOfCalculation) /
      salaryAtRetirementAge;

    var scenarioAdditionalFinalSalaryTransferInPensionAtRetirementAgeFutureTerms =
      this.excelFunctions.index(
        finalSalaryTransferIn,
        this.excelFunctions.match(
          Math.min(this.parameters.person.retirementAge, 60),
          this.ages,
          this.excelFunctions.EXACT
        ) - 1
      ) * pastServiceRetirementFactorFS;

    var scenarioAdditionalFinalSalaryTransferInPensionAtRetirementAgeCurrentTerms = this.revalue(
      this.excelFunctions.ifError(
        (baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeCurrentTerms *
          scenarioAdditionalFinalSalaryTransferInPensionAtRetirementAgeFutureTerms) /
          baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeFutureTerms,
        0
      ),
      this.parameters.assumptions.rpi,
      this.standardRetirementAge() - this.parameters.person.retirementAge
    );

    //index shortcut
    var indexOfRetirementAge =
      this.excelFunctions.match(
        Math.min(this.parameters.person.retirementAge, 65),
        this.ages,
        this.excelFunctions.EXACT
      ) - 1;

    var scenarioPastServiceCarePensionAtRetirementAgeFutureTerms =
      this.excelFunctions.index(pastCare, indexOfRetirementAge) * pastServiceRetirementFactorCARE;

    var scenarioPastServiceCarePensionAtRetirementAgeFutureTermsPost20 =
      this.excelFunctions.index(pastCarePost20, indexOfRetirementAge) * pastServiceRetirementFactorCAREPost20;

    var scenarioPastServiceCarePensionAtRetirementAgeCurrentTerms = this.revalue(
      this.excelFunctions.ifError(
        (baselinePastServiceCarePensionAtRetirementAgeCurrentTerms *
          scenarioPastServiceCarePensionAtRetirementAgeFutureTerms) /
          baselinePastServiceCarePensionAtRetirementAgeFutureTerms,
        0
      ),
      this.parameters.assumptions.rpi,
      this.standardRetirementAge() - this.parameters.person.retirementAge
    );

    var scenarioPastServiceCarePensionAtRetirementAgeCurrentTermsPost20 = this.revalue(
      this.excelFunctions.ifError(
        (baselinePastServiceCarePensionAtRetirementAgeCurrentTermsPost20 *
          scenarioPastServiceCarePensionAtRetirementAgeFutureTermsPost20) /
          baselinePastServiceCarePensionAtRetirementAgeFutureTermsPost20,
        0
      ),
      this.parameters.assumptions.rpi,
      this.standardRetirementAge() - this.parameters.person.retirementAge
    );

    var scenarioAdditionalCareTransferInPensionAtRetirementAgeFutureTerms =
      this.excelFunctions.index(careTransferIn, indexOfRetirementAge) * pastServiceRetirementFactorCARE;

    var scenarioAdditionalCareTransferInPensionAtRetirementAgeCurrentTerms = this.revalue(
      this.excelFunctions.ifError(
        (baselineAdditionalCareTransferInPensionAtRetirementAgeCurrentTerms *
          scenarioAdditionalCareTransferInPensionAtRetirementAgeFutureTerms) /
          baselineAdditionalCareTransferInPensionAtRetirementAgeFutureTerms,
        0
      ),
      this.parameters.assumptions.cpi,
      this.standardRetirementAge() - this.parameters.person.retirementAge
    );

    var scenarioFuturePre65PensionAtRetirementAgeFutureTerms =
      this.excelFunctions.index(futureCare, indexOfRetirementAge) * futureServiceRetirementFactor;

    if (this.debug) {
      // console.log(
      //   'scenarioFuturePre65PensionAtRetirementAgeFutureTerms ',
      //   scenarioFuturePre65PensionAtRetirementAgeFutureTerms
      // );
    }

    var scenarioFuturePre65PensionAtRetirementAgeCurrentTerms =
      (scenarioFuturePre65PensionAtRetirementAgeFutureTerms * this.baseline.salaryAtDateOfCalculation) /
      salaryAtRetirementAge;

    var scenarioFuturePost65PensionAtRetirementAgeFutureTerms =
      this.parameters.person.retirementAge <= 65 ? 0 : this.excelFunctions.sum(post65AtNormalRetirementAge);

    var scenarioFuturePost65PensionAtRetirementAgeCurrentTerms =
      (scenarioFuturePost65PensionAtRetirementAgeFutureTerms * this.baseline.salaryAtDateOfCalculation) /
      salaryAtRetirementAge;

    var scenarioPastServiceDcFundAtRetirementAgeFutureTerms =
      this.parameters.person.standardAccrualRate == BenefitType.BENEFIT_95
        ? this.revalue(
            dcFund,
            this.parameters.person.investmentReturn,
            this.parameters.person.retirementAge - this.ageNow()
          )
        : 0;

    var scenarioPastServiceDcFundAtRetirementAgeCurrentTerms =
      (scenarioPastServiceDcFundAtRetirementAgeFutureTerms * this.baseline.salaryAtDateOfCalculation) /
      salaryAtRetirementAge;

    var scenarioFutureServiceDcFundAtRetirementAgeFutureTerms = this.excelFunctions.sumIf(
      this.ages,
      {
        func: function (test: number, retirementAge: number) {
          return test < retirementAge;
        },
        params: this.parameters.person.retirementAge,
      },
      dcFundAtNormalRetirementAge
    );

    var scenarioFutureServiceDcFundAtRetirementAgeCurrentTerms =
      (scenarioFutureServiceDcFundAtRetirementAgeFutureTerms * this.baseline.salaryAtDateOfCalculation) /
      salaryAtRetirementAge;

    var scenarioAnnuity = this.annuityTable.getAnnuity(
      this.parameters.person.retirementAge,
      this.parameters.yearOfBirth()
    );

    var scenarioDcPensionAtRetirementAgeFutureTerms =
      (scenarioPastServiceDcFundAtRetirementAgeFutureTerms + scenarioFutureServiceDcFundAtRetirementAgeFutureTerms) /
      scenarioAnnuity;

    var scenarioDcPensionAtRetirementAgeCurrentTerms =
      (scenarioDcPensionAtRetirementAgeFutureTerms * this.baseline.salaryAtDateOfCalculation) / salaryAtRetirementAge;

    //additional outputs required for online modeller output
    var pastDcPensionAtRetirementAgeCurrentTerms =
      scenarioPastServiceDcFundAtRetirementAgeCurrentTerms / scenarioAnnuity;

    var futureDcPensionAtRetirementAgeCurrentTerms =
      scenarioFutureServiceDcFundAtRetirementAgeCurrentTerms / scenarioAnnuity;

    var scenarioTotalPastServicePensionAtRetirementAgeCurrentTerms =
      scenarioPastServicePensionAtRetirementAgeCurrentTerms +
      scenarioAdditionalFinalSalaryTransferInPensionAtRetirementAgeCurrentTerms +
      scenarioPastServiceCarePensionAtRetirementAgeCurrentTerms +
      scenarioPastServiceCarePensionAtRetirementAgeCurrentTermsPost20 +
      scenarioAdditionalCareTransferInPensionAtRetirementAgeCurrentTerms +
      pastDcPensionAtRetirementAgeCurrentTerms;

    var scenarioTotalFutureServicePensionAtRetirementAgeCurrentTerms =
      scenarioFuturePre65PensionAtRetirementAgeCurrentTerms +
      scenarioFuturePost65PensionAtRetirementAgeCurrentTerms +
      futureDcPensionAtRetirementAgeCurrentTerms;

    var pastDcPensionAtRetirementAgeFutureTerms = scenarioPastServiceDcFundAtRetirementAgeFutureTerms / scenarioAnnuity;

    var futureDcPensionAtRetirementAgeFutureTerms =
      scenarioFutureServiceDcFundAtRetirementAgeFutureTerms / scenarioAnnuity;

    var scenarioTotalPastServicePensionAtRetirementAgeFutureTerms =
      scenarioPastServicePensionAtRetirementAgeFutureTerms +
      scenarioAdditionalFinalSalaryTransferInPensionAtRetirementAgeFutureTerms +
      scenarioPastServiceCarePensionAtRetirementAgeFutureTerms +
      scenarioPastServiceCarePensionAtRetirementAgeFutureTermsPost20 +
      scenarioAdditionalCareTransferInPensionAtRetirementAgeFutureTerms +
      pastDcPensionAtRetirementAgeFutureTerms;

    var scenarioTotalFutureServicePensionAtRetirementAgeFutureTerms =
      scenarioFuturePre65PensionAtRetirementAgeFutureTerms +
      scenarioFuturePost65PensionAtRetirementAgeFutureTerms +
      futureDcPensionAtRetirementAgeFutureTerms;

    var scenarioTotalPensionAtRetirementAgeFutureTerms =
      scenarioTotalPastServicePensionAtRetirementAgeFutureTerms +
      scenarioTotalFutureServicePensionAtRetirementAgeFutureTerms;

    var scenarioTotalPensionAtRetirementAgeCurrentTerms =
      scenarioTotalPastServicePensionAtRetirementAgeCurrentTerms +
      scenarioTotalFutureServicePensionAtRetirementAgeCurrentTerms;

    var scenarioReplacementRatioFutureTerms = scenarioTotalPensionAtRetirementAgeFutureTerms / salaryAtRetirementAge;

    var scenarioReplacementRatioCurrentTerms =
      scenarioTotalPensionAtRetirementAgeCurrentTerms / this.baseline.salaryAtDateOfCalculation;

    var salaryChange =
      this.flexRateTable.getDifference(this.parameters.person.flexRate1, this.parameters.standardAccrualRate()) -
      this.parameters.person.dcContributionRate1;

    var scenarioSalaryCurrentTerms = this.parameters.person.salary * (1 + salaryChange);

    var scenarioSalaryFutureTerms = salaryAtRetirementAge * (1 + salaryChange);

    //populate output object

    let baselineCurrentResults: OutputPensionResults;
    let baselineFutureResults: OutputPensionResults;
    let scenarioCurrentResults: OutputPensionResults;
    let scenarioFutureResults: OutputPensionResults;
    let baseline: OutputPensionBaseline;
    let scenario: OutputPensionScenario;
    let output: OutputPensionCore;

    baselineCurrentResults = new OutputPensionResults();
    baselineFutureResults = new OutputPensionResults();
    scenarioCurrentResults = new OutputPensionResults();
    scenarioFutureResults = new OutputPensionResults();
    baseline = new OutputPensionBaseline();
    scenario = new OutputPensionScenario();
    output = new OutputPensionCore();

    baseline.currentTerms = baselineCurrentResults;
    baseline.futureTerms = baselineFutureResults;

    scenario.currentTerms = scenarioCurrentResults;
    scenario.futureTerms = scenarioFutureResults;

    output.baseline = baseline;
    output.scenario = scenario;

    output.salaryScales = this.salaryScales;
    output.statuses = this.statuses;
    output.notionalSalary = notionalSalary;
    output.salaries = this.salaries;
    output.flexedRate = flexedRate;
    output.careAccruals = this.careAccruals;
    output.inServiceRevaluations = this.inServiceRevaluations;
    output.inDefermentRevaluation = inDefermentRevaluation;
    output.finalSalaryRevaluation = finalSalaryRevaluation;
    output.dcContributionPercent = dcContributionPercent;
    output.dcContributionPounds = dcContributionPounds;
    output.dcFundAtNormalRetirementAge = dcFundAtNormalRetirementAge;
    output.careTransferIn = careTransferIn;
    output.pastCare = pastCare;
    output.futureCare = futureCare;
    output.finalSalaryTransferIn = finalSalaryTransferIn;
    output.pastFinalSalary = pastFinalSalary;
    output.annuity = annuity;
    output.dcFund = dcFund;
    output.careAccruedPension = careAccruedPension;
    output.careAccruedPensionPost20 = careAccruedPensionPost20;
    output.pastServiceFinalSalaryPension = pastServiceFinalSalaryPension;
    output.finalSalaryAtDateOfLeaving = finalSalaryAtDateOfLeaving;
    output.salaryChange = salaryChange;

    output.baseline.futureTerms.pastServicePensionAtRetirementAge = baselinePastServicePensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.additionalFinalSalaryTransferInPensionAtRetirementAge = baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.pastServiceCarePensionAtRetirementAge = baselinePastServiceCarePensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.pastServiceCarePensionAtRetirementAgePost20 = baselinePastServiceCarePensionAtRetirementAgeFutureTermsPost20;
    output.baseline.futureTerms.additionalCareTransferInPensionAtRetirementAge = baselineAdditionalCareTransferInPensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.futurePre65PensionAtRetirementAge = this.baseline.futurePre65PensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.futurePost65PensionAtRetirementAge = this.baseline.futurePost65PensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.pastServiceDcFundAtRetirementAge = baselinePastServiceDcFundAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.futureServiceDcFundAtRetirementAge = baselineFutureServiceDcFundAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.annuity = annuity;
    output.baseline.futureTerms.dcPensionAtRetirementAge = baselineDcPensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.totalPastServicePensionAtRetirementAge = baselineTotalPastServicePensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.totalFutureServicePensionAtRetirementAge = baselineTotalFutureServicePensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.totalPensionAtRetirementAge = baselineTotalPensionAtRetirementAgeFutureTerms;
    output.baseline.futureTerms.salaryAtRetirementAge = this.baseline.salaryAtStandardRetirementAge;
    output.baseline.futureTerms.replacementRatio = baselineReplacementRatioFutureTerms;
    output.baseline.futureTerms.salary = 0;

    output.baseline.currentTerms.pastServicePensionAtRetirementAge = pastServiceFinalSalaryPension;
    output.baseline.currentTerms.additionalFinalSalaryTransferInPensionAtRetirementAge = baselineAdditionalFinalSalaryTransferInPensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.pastServiceCarePensionAtRetirementAge = baselinePastServiceCarePensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.pastServiceCarePensionAtRetirementAgePost20 = baselinePastServiceCarePensionAtRetirementAgeCurrentTermsPost20;
    output.baseline.currentTerms.additionalCareTransferInPensionAtRetirementAge = baselineAdditionalCareTransferInPensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.futurePre65PensionAtRetirementAge = this.baseline.futurePre65PensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.futurePost65PensionAtRetirementAge = this.baseline.futurePost65PensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.pastServiceDcFundAtRetirementAge = baselinePastServiceDcFundAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.futureServiceDcFundAtRetirementAge = baselineFutureServiceDcFundAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.annuity = annuity;
    output.baseline.currentTerms.dcPensionAtRetirementAge = baselineDcPensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.totalPastServicePensionAtRetirementAge = baselineTotalPastServicePensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.totalFutureServicePensionAtRetirementAge = baselineTotalFutureServicePensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.totalPensionAtRetirementAge = baselineTotalPensionAtRetirementAgeCurrentTerms;
    output.baseline.currentTerms.salaryAtRetirementAge = this.baseline.salaryAtDateOfCalculation;
    output.baseline.currentTerms.replacementRatio = baselineReplacementRatioCurrentTerms;
    output.baseline.currentTerms.salary = this.parameters.person.salary;

    output.scenario.futureTerms.pastServicePensionAtRetirementAge = scenarioPastServicePensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.additionalFinalSalaryTransferInPensionAtRetirementAge = scenarioAdditionalFinalSalaryTransferInPensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.pastServiceCarePensionAtRetirementAge = scenarioPastServiceCarePensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.pastServiceCarePensionAtRetirementAgePost20 = scenarioPastServiceCarePensionAtRetirementAgeFutureTermsPost20;
    output.scenario.futureTerms.additionalCareTransferInPensionAtRetirementAge = scenarioAdditionalCareTransferInPensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.futurePre65PensionAtRetirementAge = scenarioFuturePre65PensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.futurePost65PensionAtRetirementAge = scenarioFuturePost65PensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.pastServiceDcFundAtRetirementAge = scenarioPastServiceDcFundAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.futureServiceDcFundAtRetirementAge = scenarioFutureServiceDcFundAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.annuity = scenarioAnnuity;
    output.scenario.futureTerms.dcPensionAtRetirementAge = scenarioDcPensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.totalPastServicePensionAtRetirementAge = scenarioTotalPastServicePensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.totalFutureServicePensionAtRetirementAge = scenarioTotalFutureServicePensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.totalPensionAtRetirementAge = scenarioTotalPensionAtRetirementAgeFutureTerms;
    output.scenario.futureTerms.salaryAtRetirementAge = salaryAtRetirementAge;
    output.scenario.futureTerms.replacementRatio = scenarioReplacementRatioFutureTerms;
    output.scenario.futureTerms.salary = scenarioSalaryFutureTerms;

    output.scenario.currentTerms.pastServicePensionAtRetirementAge = scenarioPastServicePensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.additionalFinalSalaryTransferInPensionAtRetirementAge = scenarioAdditionalFinalSalaryTransferInPensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.pastServiceCarePensionAtRetirementAge = scenarioPastServiceCarePensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.pastServiceCarePensionAtRetirementAgePost20 = scenarioPastServiceCarePensionAtRetirementAgeCurrentTermsPost20;
    output.scenario.currentTerms.additionalCareTransferInPensionAtRetirementAge = scenarioAdditionalCareTransferInPensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.futurePre65PensionAtRetirementAge = scenarioFuturePre65PensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.futurePost65PensionAtRetirementAge = scenarioFuturePost65PensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.pastServiceDcFundAtRetirementAge = scenarioPastServiceDcFundAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.futureServiceDcFundAtRetirementAge = scenarioFutureServiceDcFundAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.annuity = scenarioAnnuity;
    output.scenario.currentTerms.dcPensionAtRetirementAge = scenarioDcPensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.totalPastServicePensionAtRetirementAge = scenarioTotalPastServicePensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.totalFutureServicePensionAtRetirementAge = scenarioTotalFutureServicePensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.totalPensionAtRetirementAge = scenarioTotalPensionAtRetirementAgeCurrentTerms;
    output.scenario.currentTerms.salaryAtRetirementAge = this.baseline.salaryAtDateOfCalculation;
    output.scenario.currentTerms.replacementRatio = scenarioReplacementRatioCurrentTerms;
    output.scenario.currentTerms.salary = scenarioSalaryCurrentTerms;

    return output;
  }

  //LTA calculations are a separate page in the UI so these calculations can be separated off for performance improvements for the main loop
  // requires output object from main engine run.
  public lta(output: OutputPensionCore) {
    this.calculateBaseline();

    let baselineCurrentResults: OutputLtaResults;
    let baselineFutureResults: OutputLtaResults;
    let scenarioCurrentResults: OutputLtaResults;
    let scenarioFutureResults: OutputLtaResults;
    let baseline: OutputLtaBaseline;
    let scenario: OutputLtaScenario;
    let lta: OutputLtaCore;
    let baselinePastDcFund: number[];
    let baselinePastCare: number[];
    let baselineFutureCare: number[];
    let baselinePastFinalSalary: number[];
    let baselineLtaValue: number[];
    let pastDcFund: number[];
    let futureDcFund: number[];
    let ltaValue: number[];
    let ageNow = this.parameters.getAgeNow();
    let standardRetirementAge = this.parameters.standardRetirementAge();

    baselineCurrentResults = new OutputLtaResults();
    baselineFutureResults = new OutputLtaResults();
    scenarioCurrentResults = new OutputLtaResults();
    scenarioFutureResults = new OutputLtaResults();
    baseline = new OutputLtaBaseline();
    scenario = new OutputLtaScenario();
    lta = new OutputLtaCore();

    baseline.currentTerms = baselineCurrentResults;
    baseline.futureTerms = baselineFutureResults;

    scenario.currentTerms = scenarioCurrentResults;
    scenario.futureTerms = scenarioFutureResults;

    lta.baseline = baseline;
    lta.scenario = scenario;

    baselinePastDcFund = [];
    baselinePastCare = [];
    baselineFutureCare = [];
    baselinePastFinalSalary = [];
    baselineLtaValue = [];
    pastDcFund = [];
    futureDcFund = [];
    ltaValue = [];

    // phase 3, dcFund no longer required
    // TODO: remove code relating to dcFund once batch testing matches
    var dcFund = 0;

    for (var i = 0; i <= this.monthsToRun; i++) {
      //baseline LTA calculations
      baselinePastDcFund[i] = this.pastDcFund(
        this.ages[i],
        ageNow,
        this.ageNowRounded(),
        this.parameters.person.standardAccrualRate,
        dcFund,
        this.parameters.assumptions.investmentReturn
      );

      //NOTE: no in deferment revaluation, revalutation rate for in deferment is 0.
      baselinePastCare[i] =
        this.parameters.person.standardAccrualRate == BenefitType.BENEFIT_65
          ? this.careRevaluation(
              this.ages[i],
              this.ageNowRounded(),
              output.careAccruedPension + output.careAccruedPensionPost20,
              baselinePastCare,
              this.baseline.inServiceRevaluations[i],
              this.baseline.inServiceRevaluations[i],
              [this.parameters.assumptions.rpi, 0, this.parameters.assumptions.rpi],
              i
            )
          : 0;
      baselineFutureCare[i] = this.futureCare(
        baselineFutureCare,
        this.baseline.inServiceRevaluations[i],
        this.baseline.inServiceRevaluations[i],
        this.baseline.careAccruals[i],
        this.parameters.assumptions.cpi,
        0,
        i
      );

      baselinePastFinalSalary[i] =
        this.parameters.person.standardAccrualRate == BenefitType.BENEFIT_50 && output.statuses[i] != Status.NONE
          ? (output.pastServiceFinalSalaryPension * (this.baseline.salaries[i] * dateConstants.MONTHS_IN_YEAR)) /
            output.baseline.currentTerms.salaryAtRetirementAge
          : 0;
      baselineLtaValue[i] =
        baselinePastDcFund[i] +
        (baselinePastCare[i] + baselineFutureCare[i] + baselinePastFinalSalary[i]) *
          this.parameters.assumptions.ltaFactor;

      //scenario LTA calculations
      pastDcFund[i] = this.pastDcFund(
        this.ages[i],
        ageNow,
        this.ageNowRounded(),
        this.parameters.person.standardAccrualRate,
        dcFund,
        this.parameters.person.investmentReturn
      );
      futureDcFund[i] = this.futureDcFund(
        futureDcFund,
        this.parameters.person.investmentReturn,
        output.dcContributionPounds[i],
        i
      );
      ltaValue[i] =
        pastDcFund[i] +
        futureDcFund[i] +
        (output.pastCare[i] + output.futureCare[i] + output.finalSalaryTransferIn[i] + output.pastFinalSalary[i]) *
          this.parameters.assumptions.ltaFactor;
    }

    var baselineLegacyDcFundAtRetirementAge = this.revalue(
      this.parameters.person.legacyDcFund,
      this.parameters.assumptions.investmentReturn,
      standardRetirementAge - ageNow + this.parameters.periodBetweenStatementDateAndCalculationDate()
    );

    var baselinePastService =
      (output.baseline.futureTerms.pastServicePensionAtRetirementAge +
        output.baseline.futureTerms.additionalFinalSalaryTransferInPensionAtRetirementAge +
        output.baseline.futureTerms.pastServiceCarePensionAtRetirementAge +
        output.baseline.futureTerms.pastServiceCarePensionAtRetirementAgePost20 +
        output.baseline.futureTerms.additionalCareTransferInPensionAtRetirementAge) *
        this.parameters.assumptions.ltaFactor +
      output.baseline.futureTerms.pastServiceDcFundAtRetirementAge;

    var baselineLegacy =
      this.parameters.person.legacyDbPension * this.parameters.assumptions.ltaFactor +
      baselineLegacyDcFundAtRetirementAge;

    var baselineFuture =
      (output.baseline.futureTerms.futurePost65PensionAtRetirementAge +
        output.baseline.futureTerms.futurePre65PensionAtRetirementAge) *
        this.parameters.assumptions.ltaFactor +
      output.baseline.futureTerms.futureServiceDcFundAtRetirementAge;

    var baselineLtaAtRetirementAge =
      (output.baseline.futureTerms.totalPensionAtRetirementAge - output.baseline.futureTerms.dcPensionAtRetirementAge) *
        this.parameters.assumptions.ltaFactor +
      (output.baseline.futureTerms.pastServiceDcFundAtRetirementAge +
        output.baseline.futureTerms.futureServiceDcFundAtRetirementAge) +
      baselineLegacy;

    var scenarioLegacyDcFundAtRetirementAge = this.revalue(
      this.parameters.person.legacyDcFund,
      this.parameters.person.investmentReturn,
      this.parameters.person.retirementAge - ageNow + this.parameters.periodBetweenStatementDateAndCalculationDate()
    );

    var scenarioPastService =
      (output.scenario.futureTerms.pastServicePensionAtRetirementAge +
        output.scenario.futureTerms.additionalFinalSalaryTransferInPensionAtRetirementAge +
        output.scenario.futureTerms.pastServiceCarePensionAtRetirementAge +
        output.scenario.futureTerms.pastServiceCarePensionAtRetirementAgePost20 +
        output.scenario.futureTerms.additionalCareTransferInPensionAtRetirementAge) *
        this.parameters.assumptions.ltaFactor +
      output.scenario.futureTerms.pastServiceDcFundAtRetirementAge;

    var scenarioLegacy =
      this.parameters.person.legacyDbPension * this.parameters.assumptions.ltaFactor +
      scenarioLegacyDcFundAtRetirementAge;

    var scenarioFuture =
      (output.scenario.futureTerms.futurePost65PensionAtRetirementAge +
        output.scenario.futureTerms.futurePre65PensionAtRetirementAge) *
        this.parameters.assumptions.ltaFactor +
      output.scenario.futureTerms.futureServiceDcFundAtRetirementAge;

    var scenarioLtaAtRetirementAge =
      (output.scenario.futureTerms.totalPensionAtRetirementAge - output.scenario.futureTerms.dcPensionAtRetirementAge) *
        this.parameters.assumptions.ltaFactor +
      (output.scenario.futureTerms.pastServiceDcFundAtRetirementAge +
        output.scenario.futureTerms.futureServiceDcFundAtRetirementAge) +
      scenarioLegacy;

    //populate output object
    // var lta = new Output(this.INIT_OBJECT);
    lta.baselinePastDcFund = baselinePastDcFund;
    lta.baselinePastCare = baselinePastCare;
    lta.baselineFutureCare = baselineFutureCare;
    lta.baselinePastFinalSalary = baselinePastFinalSalary;
    lta.baselineLtaValue = baselineLtaValue;
    lta.pastDcFund = pastDcFund;
    lta.futureDcFund = futureDcFund;
    lta.ltaValue = ltaValue;

    //required for online modeller output
    lta.baseline.futureTerms.legacyDcFundAtRetirementAge = baselineLegacyDcFundAtRetirementAge;
    lta.baseline.futureTerms.past = baselinePastService;
    lta.baseline.futureTerms.legacy = baselineLegacy;
    lta.baseline.futureTerms.future = baselineFuture;
    lta.scenario.futureTerms.legacyDcFundAtRetirementAge = scenarioLegacyDcFundAtRetirementAge;
    lta.scenario.futureTerms.past = scenarioPastService;
    lta.scenario.futureTerms.legacy = scenarioLegacy;
    lta.scenario.futureTerms.future = scenarioFuture;

    lta.baseline.futureTerms.ltaAtRetirementAge = baselineLtaAtRetirementAge;
    lta.scenario.futureTerms.ltaAtRetirementAge = scenarioLtaAtRetirementAge;

    return lta;
  }
};
