import React, { useEffect, useState, useRef } from 'react';

import './percentage_input.scss';

type PercentageInputProps = {
  value: number;
  min?: number;
  max?: number;
  onChange: (value: number) => void;
  cyName?: string;
  labelledBy?: string;
};

const PercentageInput = ({ value, onChange, min = 0, max = 100, cyName, labelledBy = '' }: PercentageInputProps) => {
  const [formValue, setFormValue] = useState<number>(value * 100);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [hasBlurred, setHasBlurred] = useState<boolean>(false);

  const ref = useRef<HTMLInputElement>(null);

  const errorMessage: string = (() => {
    if (value < min) return `This must be at least ${min}%`;
    if (value > max) return `This must be less than ${max}%`;
    return '';
  })();

  useEffect(() => {
    if (ref.current) {
      ref.current.setCustomValidity('');
      if (value < min) ref.current.setCustomValidity(`This must be at least ${min}%`);
      if (value > max) ref.current.setCustomValidity(`This must be less than ${max}%`);
    }
  }, [value, formValue, hasBlurred]);

  useEffect(() => {
    setFormValue(value);
  }, [value]);

  useEffect(() => {
    if (isEditing && ref.current) ref.current.select();
  }, [isEditing]);

  return (
    <div className="percentage-input-wrapper">
      {hasBlurred && errorMessage && <span className="yearlyamount__error">{errorMessage}</span>}
      <input
        id={labelledBy}
        min={min}
        max={max}
        data-cy={cyName}
        ref={ref}
        formNoValidate={true}
        value={isEditing ? Number((formValue * 100).toFixed(1)) : `${String(Number((value * 100).toFixed(1)))}%`}
        type={isEditing ? 'number' : 'text'}
        aria-labelledby={labelledBy}
        onFocus={() => {
          setIsEditing(true);
          if (ref.current) ref.current.select();
        }}
        className="percentage-input"
        inputMode="numeric"
        onChange={(event) => setFormValue(Number(event.target.value) / 100)}
        onBlur={(event) => {
          const relatedTarget: any = event.relatedTarget || (event as any).explicitOriginalTarget;
          if (relatedTarget === null) return;
          if (
            !relatedTarget ||
            (!event.currentTarget.contains(relatedTarget) && ref.current && !ref.current.contains(relatedTarget))
          ) {
            setHasBlurred(true);
            setIsEditing(false);
            onChange(formValue);
          }
        }}
      />
    </div>
  );
};

export default PercentageInput;
