export default class ClassUtil {
  //recursively merge one Object into another
  public merge(destination: any, source: any) {
    for (var p in source) {
      try {
        // Property in destination object set; update its value.
        if ( source[p].constructor==Object ) {
          destination[p] = this.merge(destination[p], source[p]);
        }
        else {
          destination[p] = source[p];
        }
      }
      catch(e) {
        // Property in destination object not set; create it and set its value.
        destination[p] = source[p];
      }
    }
    return destination;
  };
}
