import React from 'react';
import { useUpdateUserData, useUserData } from '../../../../store';

import RadioInputs from '../../../inputs/radio_inputs';
import SliderInput from '../../../inputs/slider_input/slider_input';
import HelpLabel from '../../../../components/help_label/help_label';

const RetirementAge = () => {
  const { retirementAge, termToDateOfLeaving, atBankUntilRetirement, maxFlexTerm } = useUserData();
  const updateUserData = useUpdateUserData();

  return (
    <section className="user-options-tab-content user-options-tab-content--retirement-age">
      <form noValidate>
        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="target-retirement-age"
            description="At what age do you wish to retire?"
            extendedDescription="Your default assumed retirement age is 65, unless you are a former Final Salary member who will be under age 60 as at 31 March 2024, in which case it is 60. If you wish to retire at a different age, please use this slider to indicate at which age you wish to retire."
          />
          <SliderInput
            labelledBy="target-retirement-age"
            value={retirementAge}
            handleChange={(value) => updateUserData({ retirementAge: value })}
            min={55}
            max={70}
            unitLabel="years"
          />
        </fieldset>

        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="at-bank-until-retirement"
            description="Do you expect to work at the Bank of England until your retirement age?"
            extendedDescription="This information is purely for the purposes of this Pensions Calculator and will not be collected by the Bank of England."
          />
          <RadioInputs
            labelledBy="at-bank-until-retirement"
            selected={atBankUntilRetirement}
            handleChange={() => updateUserData({ atBankUntilRetirement: !atBankUntilRetirement })}
            radioOptions={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        </fieldset>

        {!atBankUntilRetirement && (
          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="how-long-at-bank-of-england"
              description="How long do you expect to work for the Bank of England?"
              extendedDescription="This will illustrate the impact of leaving the Bank on both your past service and future service pension benefits. While you may earn new future pension benefits with a different employer, the Calculator will also illustrate the difference between how your past service benefits increase while you remain in service compared to how they would increase after you leave service.  This information is purely for the purposes of this Pensions Calculator and will not be collected by the Bank of England."
            />
            <SliderInput
              labelledBy="how-long-at-bank-of-england"
              value={termToDateOfLeaving}
              handleChange={(value) => updateUserData({ termToDateOfLeaving: Number(value) })}
              min={1}
              max={maxFlexTerm}
              unitLabel="years"
            />
          </fieldset>
        )}
      </form>
    </section>
  );
};

export default RetirementAge;
