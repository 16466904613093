import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { PageUrls } from '../../../types';

type NavigationItemProps = {
  targetPath: string;
  linkIsDisabled?: boolean;
  children: React.ReactNode;
};

const NavigationItem = ({ targetPath, linkIsDisabled, children }: NavigationItemProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (linkIsDisabled) event.preventDefault();
  };

  const orderedPageUrls = Object.values(PageUrls);
  const activePageIndex = orderedPageUrls.indexOf(useLocation().pathname as PageUrls);
  const prevPageUrl = orderedPageUrls[activePageIndex - 1];
  const nextPageUrl = orderedPageUrls[activePageIndex + 1];

  const DEFAULT_CSS_CLASS = 'nav-link';
  const navLinkCssClasses = (navData: any) => {
    const classes = [DEFAULT_CSS_CLASS];
    if (linkIsDisabled) classes.push(`${DEFAULT_CSS_CLASS}--disabled`);
    if (navData.isActive) classes.push(`${DEFAULT_CSS_CLASS}--selected`);
    if (targetPath === prevPageUrl) classes.push(`${DEFAULT_CSS_CLASS}--prev-page`);
    if (targetPath === nextPageUrl) classes.push(`${DEFAULT_CSS_CLASS}--next-page`);
    return classes.join(' ');
  };

  return (
    <NavLink to={targetPath} className={navLinkCssClasses} onClick={handleClick}>
      {children}
    </NavLink>
  );
};

export default NavigationItem;
