import React from 'react';

import NavigationBar from '../navigation_bar';

import './header.scss';

import xpsLogo from '../../../images/xps_logo.png';
import boeLogo from '../../../images/boe_logo.png';

const Header = () => {
  return (
    <header>
      <div className="company-logos">
        <img src={xpsLogo} className="company-logos__xps" alt="" />
        <img src={boeLogo} className="company-logos__bank-of-england" alt="" />
      </div>
      <NavigationBar />
    </header>
  );
};

export default Header;
