import OutputLtaResults from './results';

export default class OutputLtaScenario {
  public currentTerms: OutputLtaResults;
  public futureTerms: OutputLtaResults;

  constructor() {
    this.currentTerms = new OutputLtaResults();
    this.futureTerms = new OutputLtaResults();
  }
}
