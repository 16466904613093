export default class LrfTable {
  public startAge: number;
  public cpiTable: any;
  public rpiTable: any;
  public RPI_TABLE: number;
  public CPI_TABLE: number;
  public rpi: number;
  public cpi: number;

  constructor(startAge: number, rpiTable: any, cpiTable: any) {
    this.startAge = startAge;
    this.rpiTable = rpiTable;
    this.cpiTable = cpiTable;
    this.RPI_TABLE = 0;
    this.CPI_TABLE = 1;
  }

  public getLrf(age: number, type: number) {
    var table = type === this.RPI_TABLE ? this.rpiTable : this.cpiTable;
    var lookUpAge = age - this.startAge;

    return lookUpAge < 0 || lookUpAge >= table.length ? NaN : table[lookUpAge];
  }
}
