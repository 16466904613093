import OutputPensionCore from './output/pension/core';
import OutputAnnualAllowanceResults from './output/annualAllowance/results';
import Parameters from './parameters';
import FlexRateTable from './tables/flexRateTable';
import SalaryScale from './tables/salaryScale';
import SchemePaysTable from './tables/schemePays';

export default class AnnualAllowance {
  private debug: Boolean;
  private output: OutputPensionCore;
  private p: Parameters;
  private salaryScale: SalaryScale;
  private flexRateTable: FlexRateTable;
  private careSchemePaysTable: SchemePaysTable;
  private finalSalarySchemePaysTable: SchemePaysTable;

  constructor(parameters: Parameters, output: OutputPensionCore, debug: Boolean) {
    // optionally pass a debug parameter to trigger break points or logging
    // during specific test cases when batch testing
    this.debug = typeof debug == 'undefined' ? false : debug;
    this.output = output;
    this.p = parameters;
    this.salaryScale = new SalaryScale(this.p.salaryScale.startAge, this.p.salaryScale.scale);
    this.flexRateTable = new FlexRateTable(this.p.flexRates.rates);
    this.careSchemePaysTable = new SchemePaysTable(this.p.schemePays.startAge, this.p.schemePays.careFactors);
    this.finalSalarySchemePaysTable = new SchemePaysTable(
      this.p.schemePays.startAge,
      this.p.schemePays.finalSalaryFactors
    );
  }

  public run() {
    for (var i = 0; i < this.p.accrual.rates.length; i++) {
      var rate = this.p.accrual.rates[i];
      var accrualRatekey: string;
      accrualRatekey = 'annualAllowanceForAccrualRate' + rate.toString();
      Object.assign(this.output, { [accrualRatekey]: this.calculateForAccrualRate(rate) });
    }

    return this.output;
  }

  public calculateForAccrualRate(rate: any) {
    let output = new OutputAnnualAllowanceResults();

    var accrualRate = rate;
    output.rate = accrualRate;

    var firstExpectedSalary = this.p.person.salary * (1 + this.p.person.firstYearSalaryIncrease);
    var flexibleBenefitsPot = firstExpectedSalary * this.p.assumptions.annualAllowance.flexibleBenefitsPot;
    var performanceAward = firstExpectedSalary * this.p.person.performanceAward;
    var secondExpectedSalary = firstExpectedSalary * (1 + this.p.person.secondYearSalaryIncrease);
    var otherBankTaxableIncome = this.p.person.otherBankTaxableIncome;
    var otherExternalTaxableIncome = this.p.person.otherExternalTaxableIncome;

    // In reality, it should refer to this.p.standardAccrualRate() so that new joiners stick to 95ths but the
    // batch tests cases don't factor that in and assign a different rate in some cases. In the UI, a new
    // joiner would be assigned 95ths automatically.
    var contributionChange =
      firstExpectedSalary * this.flexRateTable.getDifference(accrualRate, this.p.person.standardAccrualRate);
    var thresholdIncome =
      (firstExpectedSalary + flexibleBenefitsPot) * (329 / 365) /* WTF */ +
      secondExpectedSalary * (1 + this.p.assumptions.annualAllowance.flexibleBenefitsPot) * (36 / 365) /* WTF */ +
      performanceAward +
      otherBankTaxableIncome +
      otherExternalTaxableIncome +
      Math.max(contributionChange, 0);
    var exemptFromTapering = this.p.assumptions.annualAllowance.thresholdCap > thresholdIncome;

    //final salary
    var revaluedPastServiceFinalSalaryPension =
      (this.output.pastServiceFinalSalaryPension * (1 + this.p.person.firstYearSalaryIncrease)) /
      ((1 + this.p.assumptions.salaryIncreaseRate) * (1 + this.salaryScale.getScale(Math.floor(this.p.getAgeNow()))));
    var finalSalaryPensionAtStartOfPip =
      revaluedPastServiceFinalSalaryPension +
      this.output.baseline.currentTerms.additionalFinalSalaryTransferInPensionAtRetirementAge;
    var finalSalaryPensionAtEndOfPip =
      revaluedPastServiceFinalSalaryPension * (1 + this.p.person.secondYearSalaryIncrease) +
      this.output.baseline.currentTerms.additionalFinalSalaryTransferInPensionAtRetirementAge *
        (1 + this.p.person.firstYearRpiRevaluation);
    var finalSalaryPensionInputAmount = this.pensionInputAmount(
      finalSalaryPensionAtStartOfPip,
      finalSalaryPensionAtEndOfPip,
      this.p.assumptions.annualAllowance.annualAllowanceFactor,
      this.p.assumptions.annualAllowance.cpi
    );

    //care
    var careAccruedPensionAtStartOfPip =
      this.output.baseline.currentTerms.pastServiceCarePensionAtRetirementAge *
        (1 + this.p.assumptions.annualAllowance.firstYearRpiRevaluation) +
      this.output.baseline.currentTerms.additionalCareTransferInPensionAtRetirementAge *
        (1 + this.p.assumptions.annualAllowance.firstYearCpiRevaluation) +
      ((5 / 365) /* WTF */ * firstExpectedSalary) / accrualRate;
    var careAccruedPensionAtEndOfPip =
      (this.output.baseline.currentTerms.pastServiceCarePensionAtRetirementAge *
        (1 + this.p.assumptions.annualAllowance.firstYearRpiRevaluation) +
        firstExpectedSalary / accrualRate) *
        (1 + this.p.person.secondYearRpiRevaluation) +
      ((5 / 365) /* WTF */ * secondExpectedSalary) / accrualRate +
      this.output.baseline.currentTerms.additionalCareTransferInPensionAtRetirementAge *
        (1 + this.p.assumptions.annualAllowance.firstYearCpiRevaluation) *
        (1 + this.p.person.secondYearCpiRevaluation);
    var carePensionInputAmount = this.pensionInputAmount(
      careAccruedPensionAtStartOfPip,
      careAccruedPensionAtEndOfPip,
      this.p.assumptions.annualAllowance.annualAllowanceFactor,
      this.p.assumptions.annualAllowance.cpi
    );
    var dcContributions = this.p.person.dcContributions;
    var totalPensionInputAmount = finalSalaryPensionInputAmount + carePensionInputAmount + dcContributions;
    var adjustedIncome =
      thresholdIncome + Math.min(contributionChange, 0) + finalSalaryPensionInputAmount + carePensionInputAmount;
    var annualAllowance = exemptFromTapering
      ? this.p.assumptions.annualAllowance.standardAnnualAllowance
      : Math.max(
          this.p.assumptions.annualAllowance.standardAnnualAllowance -
            Math.max(adjustedIncome - this.p.assumptions.annualAllowance.annualAllowanceTaperCap, 0) / 2,
          this.p.assumptions.annualAllowance.minimumAnnualAllowance
        );
    var annualAllowanceCarryForward = this.p.person.annualAllowanceCarryForward;
    var finalSalaryPensionAtStartOfYear =
      this.output.pastServiceFinalSalaryPension /
        ((1 + this.p.assumptions.salaryIncreaseRate) *
          (1 + this.salaryScale.getScale(Math.floor(this.p.getAgeNow())))) +
      this.output.baseline.currentTerms.additionalFinalSalaryTransferInPensionAtRetirementAge /
        (1 + this.p.assumptions.annualAllowance.rpi);
    var finalSalaryPensionInputAmountForYear = this.pensionInputAmount(
      finalSalaryPensionAtStartOfYear,
      finalSalaryPensionAtStartOfPip,
      this.p.assumptions.annualAllowance.annualAllowanceFactor,
      this.p.assumptions.annualAllowance.annualAllowanceUplift
    );
    var careAccruedPensionAtStartOfYear =
      this.output.baseline.currentTerms.pastServiceCarePensionAtRetirementAge -
      (this.p.person.salary / this.p.person.currentAccrualRate) * (360 / 365) /* WTF */ +
      this.output.baseline.currentTerms.additionalCareTransferInPensionAtRetirementAge;
    var carePensionInputAmountForYear = this.pensionInputAmount(
      careAccruedPensionAtStartOfYear,
      careAccruedPensionAtStartOfPip,
      this.p.assumptions.annualAllowance.annualAllowanceFactor,
      this.p.assumptions.annualAllowance.annualAllowanceUplift
    );
    var annualAllowanceAssessedValue = finalSalaryPensionInputAmountForYear + carePensionInputAmountForYear;
    var otherAnnualAllowanceValue = this.p.person.otherAnnualAllowanceValue;
    var annualAllowanceCarryForwardForYear = Math.max(
      0,
      this.p.assumptions.annualAllowance.standardAnnualAllowancePreviousYear -
        (annualAllowanceAssessedValue + otherAnnualAllowanceValue)
    );
    var annualAllowanceAllowingForCarryForward =
      annualAllowance + annualAllowanceCarryForward + annualAllowanceCarryForwardForYear;
    var excessAboveAnnualAllowance = Math.max(0, totalPensionInputAmount - annualAllowanceAllowingForCarryForward);
    var marginalTaxRate = this.p.person.marginalTaxRate;
    var taxCharge = excessAboveAnnualAllowance * marginalTaxRate;
    var ageAtDateOfSchemePays = Math.floor(this.p.ageAt(this.p.assumedDateOfSchemePays()));
    var careFactor = this.careSchemePaysTable.getFactor(ageAtDateOfSchemePays);
    var finalSalaryFactor = this.finalSalarySchemePaysTable.getFactor(ageAtDateOfSchemePays);
    var schemePaysGreaterThanCare = taxCharge / careFactor > careAccruedPensionAtEndOfPip;
    var schemePaysPensionGivenUp = schemePaysGreaterThanCare
      ? careAccruedPensionAtEndOfPip + (taxCharge - careAccruedPensionAtEndOfPip * careFactor) / finalSalaryFactor
      : taxCharge / careFactor;

    var increaseInPensionBeforeSchemePays =
      finalSalaryPensionAtEndOfPip +
      careAccruedPensionAtEndOfPip -
      (finalSalaryPensionAtStartOfPip + careAccruedPensionAtStartOfPip);
    var increaseInPensonAfterSchemePays = increaseInPensionBeforeSchemePays - schemePaysPensionGivenUp;

    //core outputs
    output.firstExpectedSalary = firstExpectedSalary;
    output.flexibleBenefitsPot = flexibleBenefitsPot;
    output.performanceAward = performanceAward;
    output.secondExpectedSalary = secondExpectedSalary;
    output.otherBankTaxableIncome = otherBankTaxableIncome;
    output.otherExternalTaxableIncome = otherExternalTaxableIncome;
    output.contributionChange = contributionChange;
    output.thresholdIncome = thresholdIncome;
    output.finalSalaryPensionInputAmount = finalSalaryPensionInputAmount;
    output.carePensionInputAmount = carePensionInputAmount;
    output.dcContributions = dcContributions;
    output.totalPensionInputAmount = totalPensionInputAmount;
    output.adjustedIncome = adjustedIncome;
    output.annualAllowance = annualAllowance;
    output.annualAllowanceCarryForward = annualAllowanceCarryForward;
    output.annualAllowanceAssessedValue = annualAllowanceAssessedValue;
    output.annualAllowanceCarryForwardForYear = annualAllowanceCarryForwardForYear;
    output.annualAllowanceAllowingForCarryForward = annualAllowanceAllowingForCarryForward;
    output.excessAboveAnnualAllowance = excessAboveAnnualAllowance;
    output.taxCharge = taxCharge;
    output.schemePaysPensionGivenUp = schemePaysPensionGivenUp;
    output.increaseInPensionBeforeSchemePays = increaseInPensionBeforeSchemePays;
    output.increaseInPensonAfterSchemePays = increaseInPensonAfterSchemePays;
    output.finalSalaryPensionAtStartOfYear = finalSalaryPensionAtStartOfYear;
    output.careAccruedPensionAtStartOfYear = careAccruedPensionAtStartOfYear;
    output.exemptFromTapering = exemptFromTapering;
    output.revaluedPastServiceFinalSalaryPension = revaluedPastServiceFinalSalaryPension;
    output.finalSalaryPensionAtStartOfPip = finalSalaryPensionAtStartOfPip;
    output.finalSalaryPensionAtEndOfPip = finalSalaryPensionAtEndOfPip;
    output.careAccruedPensionAtStartOfPip = careAccruedPensionAtStartOfPip;
    output.careAccruedPensionAtEndOfPip = careAccruedPensionAtEndOfPip;
    output.finalSalaryPensionInputAmountForYear = finalSalaryPensionInputAmountForYear;
    output.carePensionInputAmountForYear = carePensionInputAmountForYear;
    output.ageAtDateOfSchemePays = ageAtDateOfSchemePays;
    output.careFactor = careFactor;
    output.finalSalaryFactor = finalSalaryFactor;
    output.schemePaysGreaterThanCare = schemePaysGreaterThanCare;

    return output;
  }

  public pensionInputAmount(startValue: number, endValue: number, annualAllowanceFactor: number, uplift: number) {
    var openingValue = startValue * annualAllowanceFactor * (1 + uplift);
    var closingValue = endValue * annualAllowanceFactor;
    return Math.max(closingValue - openingValue, 0);
  }
}
