export default class ErfTable {
  public RPI_TABLE: number;
  public CPI_TABLE: number;

  public rpiTable: any;
  public cpiTable: any;
  public rpi: number;
  public cpi: number;

  constructor(rpiTable: any, cpiTable: any) {
    this.RPI_TABLE = 0;
    this.CPI_TABLE = 1;
    this.rpiTable = rpiTable;
    this.cpiTable = cpiTable;
  }

  public getErf(period: number, type: number) {
    var table = type === this.RPI_TABLE ? this.rpiTable : this.cpiTable;

    return period < 0 || period >= table.length ? NaN : table[period];
  }
};
