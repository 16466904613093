import { useSelector, useDispatch } from 'react-redux';

import { reducerActions, UserDataStore, UiOptionsStore, ReduxStore } from '../../types';

export const useUserData = () =>
  useSelector((state: ReduxStore): UserDataStore => {
    return state.userData;
  });

export const useUpdateUserData = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (payload: object) =>
    dispatch({
      type: reducerActions.UPDATE_USER_DATA,
      payload,
    });
};

export const useUiOptions = () =>
  useSelector((state: ReduxStore): UiOptionsStore => {
    return state.uiOptions;
  });

export const useUpdateUiOptions = () => {
  const dispatch = useDispatch();
  // eslint-disable-next-line @typescript-eslint/ban-types
  return (payload: object) =>
    dispatch({
      type: reducerActions.UPDATE_UI_OPTIONS,
      payload,
    });
};
