import ClassUtil from './lib/classUtil';
import BenefitType from './lib/benefitType';
import Membership from './lib/membership';
import ExcelFunctions from './lib/excelFunctions';
import Person from './person';
import Assumptions from './assumptions';

import SalaryScale from './tables/salaryScale';
import AnnuityTable from './tables/annuityTable';
import ErfTable from './tables/erfTable';
import LrfTable from './tables/lrfTable';
import FlexRatesTable from './tables/flexRateTable';
import SchemePays from './tables/schemePays';
import AccrualRate from './tables/accrualRate';

import { AdvancedDate as AdvDate, dateUtils } from '@lifetime-gallagher/calculator-toolkit';
import dateConstants from './lib/dateConstants';
import DateFormatter from './lib/dateFormatter';

//override AdvDate to have 365.25 days in a year to match Excel date comparison
// dateConstants.DAYS_IN_YEAR = 365.25;
// dateConstants.MILLISECONDS_IN_YEAR = 31557600000;

export default class Parameters {
  public person: Person;
  public assumptions: Assumptions;
  public salaryScale: SalaryScale;
  public annuity: AnnuityTable;
  public erf: ErfTable;
  public lrf: LrfTable;
  public flexRates: FlexRatesTable;
  public schemePays: SchemePays;
  public dateFormatter: DateFormatter;
  public classUtil: ClassUtil;
  public excelFunctions: ExcelFunctions;
  public ageNow: number;
  public accrual: AccrualRate;

  constructor(data: any) {
    this.assumptions = new Assumptions();
    //recursively merge JSON object into parameters Object
    //so properties can be accessed directly from Parameters
    this.accrual = new AccrualRate();
    this.classUtil = new ClassUtil();
    this.classUtil.merge(this, data);
    this.person = new Person(data);
    this.dateFormatter = new DateFormatter();
    this.excelFunctions = new ExcelFunctions();
    //parse Date objects from String values in JSON
    this.person.dateOfBirth = this.dateFormatter.advDateFromString(data.person.dateOfBirth);
    this.person.effectiveDateOfTotalRewardStatement = this.dateFormatter.advDateFromString(
      data.person.effectiveDateOfTotalRewardStatement
    );
    this.assumptions.calculationDate = this.dateFormatter.advDateFromString(data.assumptions.calculationDate);
    this.ageNow = this.getAgeNow();
  }

  //age to full precision where is used in determining revaluation period to avoid differences with Excel model
  public getAgeNow() {
    return this.ageAt(this.assumptions.calculationDate);
  }

  public ageAt(date: any) {
    return dateUtils.monthsBetween(this.person.dateOfBirth, date) / dateConstants.MONTHS_IN_YEAR;
  }

  //age at a sensible precision for the purposes of age ot age comparisons
  public ageNowRounded() {
    return this.excelFunctions.round(this.getAgeNow(), 2);
  }

  public ageNowMonth() {
    // return the month component of ageNow as an integer month (indexed from 1) to avoid floating point
    // comparisons when matching ages
    return Math.round((this.getAgeNow() % 1) / (1 / dateConstants.MONTHS_IN_YEAR)) + 1;
  }

  public yearOfBirth() {
    return this.person.dateOfBirth.getFullYear();
  }

  public dateOfRetirement(retirementAge: number) {
    return this.person.dateOfBirth.plus(retirementAge);
  }

  public standardRetirementAge() {
    var ageNow = this.getAgeNow();
    if (ageNow > 65) {
      return this.excelFunctions.roundUp(ageNow, 0);
    } else {
      return ageNow > this.pastServiceNormalRetirementAge() ? 65 : this.pastServiceNormalRetirementAge();
    }
  }

  public pastServiceNormalRetirementAge() {
    return this.person.standardAccrualRate == BenefitType.BENEFIT_50 ? 60 : 65;
  }

  public standardAccrualRate() {
    //phase 3, previously this was a calculated value based on person.category, now based on membership type
    return this.person.membership == Membership.NEW_JOINER ? 95 : this.person.standardAccrualRate;
  }

  public currentAccrualRate() {
    //phase 3, new property
    return this.person.membership == Membership.NEW_JOINER ? 95 : this.person.currentAccrualRate;
  }

  // NOTE: this approach matches Excel date subtraction - e.g. date1 - date2
  // IMPORTANT: Excel uses 365.25 days per year, to match Excel pass millisecondsInYear as 31557600000
  public fastMinus = function (date1: any, date2: any) {
    return (date1.date.valueOf() - date2.date.valueOf()) / dateConstants.MILLISECONDS_IN_YEAR;
  };

  public periodBetweenStatementDateAndCalculationDate() {
    return this.fastMinus(this.assumptions.calculationDate, this.person.effectiveDateOfTotalRewardStatement);
  }

  public assumedDateOfSchemePays() {
    return this.dateFormatter.advDate(this.assumptions.calculationDate.getFullYear() + 2, 6, 31);
  }

  public lta(retirementAge: number) {
    var ltaSwitchesToCpiIncreases = this.dateFormatter.advDateFromString('01/04/2025');
    if (this.dateOfRetirement(retirementAge).before(ltaSwitchesToCpiIncreases)) {
      return this.assumptions.lta;
    }
    return (
      this.assumptions.lta *
      Math.pow(
        1 + this.assumptions.cpi,
        Math.floor(dateUtils.compareAdvDateObjects(ltaSwitchesToCpiIncreases, this.dateOfRetirement(retirementAge)))
      )
    );
  }
}
