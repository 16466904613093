export default class AssumptionsAnnualAllowance {
  public flexibleBenefitsPot: number;
  public thresholdCap: number;
  public annualAllowanceFactor: number;
  public cpi: number;
  public firstYearRpiRevaluation: number;
  public firstYearCpiRevaluation: number;
  public standardAnnualAllowancePreviousYear: number;
  public standardAnnualAllowance: number;
  public annualAllowanceTaperCap: number;
  public minimumAnnualAllowance: number;
  public rpi: number;
  public annualAllowanceUplift: number;

  constructor() {
    this.flexibleBenefitsPot = 0;
    this.thresholdCap = 0;
    this.annualAllowanceFactor = 0;
    this.cpi = 0;
    this.firstYearRpiRevaluation = 0;
    this.firstYearCpiRevaluation = 0;
    this.standardAnnualAllowance = 0;
    this.annualAllowanceTaperCap = 0;
    this.minimumAnnualAllowance = 0;
    this.rpi = 0;
    this.annualAllowanceUplift = 0;
  }
}
