import { reducerAction, reducerActions, PensionValuationOrAnnualAllowanceOutputStore } from '../../types';

import OutputPensionBaseline from '../../engine/output/pension/baseline';
import OutputPensionScenario from '../../engine/output/pension/scenario';
import OutputAnnualAllowanceResults from '../../engine/output/annualAllowance/results';

const defaultState: PensionValuationOrAnnualAllowanceOutputStore = {
  ages: [],
  unroundedAges: [],
  salaries: [],
  salaryScales: [],
  careAccruals: [],
  inServiceRevaluations: [],
  careValueAtNormalRetirementAge: [],
  month: [],
  statuses: [],
  notionalSalary: [],
  flexedRate: [],
  inDefermentRevaluation: [],
  finalSalaryRevaluation: [],
  dcContributionPercent: [],
  dcContributionPounds: [],
  dcFundAtNormalRetirementAge: [],
  careTransferIn: [],
  pastCare: [],
  futureCare: [],
  finalSalaryTransferIn: [],
  pastFinalSalary: [],
  futurePre65PensionAtRetirementAgeFutureTerms: 0,
  salaryAtDateOfCalculation: 0,
  salaryAtStandardRetirementAge: 0,
  futurePre65PensionAtRetirementAgeCurrentTerms: 0,
  futurePost65PensionAtRetirementAgeFutureTerms: 0,
  futurePost65PensionAtRetirementAgeCurrentTerms: 0,
  careAccruedPension: 0,
  careAccruedPensionPost20: 0,
  pastServiceFinalSalaryPension: 0,
  finalSalaryAtDateOfLeaving: 0,
  annuity: 0,
  dcFund: 0,
  salaryChange: 0,
  baseline: new OutputPensionBaseline(),
  scenario: new OutputPensionScenario(),
  annualAllowanceForAccrualRate120: new OutputAnnualAllowanceResults(),
  annualAllowanceForAccrualRate110: new OutputAnnualAllowanceResults(),
  annualAllowanceForAccrualRate95: new OutputAnnualAllowanceResults(),
  annualAllowanceForAccrualRate80: new OutputAnnualAllowanceResults(),
  annualAllowanceForAccrualRate65: new OutputAnnualAllowanceResults(),
  annualAllowanceForAccrualRate50: new OutputAnnualAllowanceResults(),
};

const annualAllowanceReducer = (
  oldState: PensionValuationOrAnnualAllowanceOutputStore = defaultState,
  action: reducerAction
): PensionValuationOrAnnualAllowanceOutputStore => {
  switch (action.type) {
    case reducerActions.UPDATE_ANNUAL_ALLOWANCE_OUTPUT:
      return { ...oldState, ...action.payload };
    case reducerActions.RESET_ALL:
      return { ...defaultState };
    default:
      return oldState;
  }
};

export default annualAllowanceReducer;
