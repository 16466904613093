// MILLISECONDS_IN_YEAR and DAYS_IN_YEAR have been overridden, compared to the normal
// values in the calculator-toolkit, to match the client's values.
// MILLISECONDS_IN_YEAR: 31557600000,
// DAYS_IN_YEAR: 365.25,

const dateConstants = {
  MILLISECONDS_IN_YEAR: 31557600000,
  DAYS_IN_YEAR: 365.25,
  WEEKS_IN_YEAR: 52,
  MONTHS_IN_YEAR: 12,
  HOURS_IN_DAY: 24,
  MINUTES_IN_HOUR: 60,
  SECONDS_IN_MINUTE: 60,
  MILLISECONDS_IN_SECOND: 1000,
  MONTHS_OF_YEAR: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  FIRST_OF_THE_MONTH: 1,
  // Months used when defining dates in javascript, which account
  // for the month number being one less than the real month.
  JANUARY: 0,
  FEBRUARY: 1,
  MARCH: 2,
  APRIL: 3,
  MAY: 4,
  JUNE: 5,
  JULY: 6,
  AUGUST: 7,
  SEPTEMBER: 8,
  OCTOBER: 9,
  NOVEMBER: 10,
  DECEMBER: 11,
  // But sometimes we also want April to have the number of the
  // real month of April:
  REAL_APRIL: 4,
  REAL_JULY: 7,
};

export default dateConstants;
