import React from 'react';
import { useUpdateUserData, useUserData } from '../../../../store';

import RadioInputs from '../../../inputs/radio_inputs';
import SliderInput from '../../../inputs/slider_input/slider_input';
import HelpLabel from '../../../../components/help_label/help_label';
import DefaultEngineData from '../../../../engine/config/engine.json';
import { Tooltip } from 'react-tooltip';

const FutureIncreases = () => {
  const { salaryIncreaseRate, investmentReturn, futureTerms } = useUserData();
  const updateUserData = useUpdateUserData();

  const hideSalaryIncreaseTooltipOnDefaultValue = (newValue: number) => {
    document.getElementById('change-salary-increase-tooltip').style.visibility =
      newValue === DefaultEngineData.assumptions.salaryIncreaseRate ? 'hidden' : '';
  };

  return (
    <section className="user-options-tab-content user-options-tab-content--future-increases">
      <form noValidate>
        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="salary-increase-rate"
            description="Salary increases"
            extendedDescription={
              'This is your estimated future average yearly increase in salary – the default assumption is 3.5% a year. The Calculator also includes an allowance for promotional increases on top of the general increase of 3.5% a year (or whatever alternative assumption you choose).'
            }
          />
          <span id="change-salary-increase" className="help-popup help-popup--salary-increase">
            <Tooltip
              anchorId="change-salary-increase"
              id="change-salary-increase-tooltip"
              content="Unless you choose to show your pension figures in future terms (see below), they will be displayed in today’s earnings terms. As your CARE pension is only partially linked to your salary, the faster your salary grows, the lower the CARE pension you would receive as a proportion of your salary. In absolute terms, however, the faster your salary grows, the higher the actual amount of CARE pension you would receive at retirement."
              place="top"
              variant="dark"
              style={{ width: '400px', zIndex: 100, visibility: 'hidden' }}
            ></Tooltip>

            <SliderInput
              labelledBy="salary-increase-rate"
              value={salaryIncreaseRate}
              handleChange={(value) => {
                updateUserData({ salaryIncreaseRate: Number(value) });
                hideSalaryIncreaseTooltipOnDefaultValue(Number(value));
              }}
              min={0}
              max={0.05}
              increment={0.001}
              inputRepresentsPercentage
              unitLabel="per year"
            />
          </span>
        </fieldset>

        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="dc-investment-return"
            description="DC investment return"
            extendedDescription="The expected future rate of investment return on your DC pension investments – the default assumption is 5% a year."
          />
          <SliderInput
            labelledBy="dc-investment-return"
            value={investmentReturn}
            handleChange={(value) => updateUserData({ investmentReturn: Number(value) })}
            min={0}
            max={0.1}
            increment={0.001}
            inputRepresentsPercentage
            unitLabel="per year"
          />
        </fieldset>

        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="show-in-future-terms"
            description="Show results in future terms?"
            extendedDescription="Click 'Yes' to show results in monetary terms at retirement."
          />
          <RadioInputs
            labelledBy="show-in-future-terms"
            selected={futureTerms}
            handleChange={(value) => updateUserData({ futureTerms: value })}
            radioOptions={[
              { label: 'Yes', value: true },
              { label: 'No', value: false },
            ]}
          />
        </fieldset>
      </form>
    </section>
  );
};

export default FutureIncreases;
