import { reducerAction, reducerActions, UserDataStore } from '../../types';
import StringLiterals from '../../components/utils/stringLiterals';

const defaultState: UserDataStore = {
  currentAge: null,
  dateOfBirth: parseInt(StringLiterals.thisYear) - 40 + '-04-01',
  dateOfBirthMin: parseInt(StringLiterals.thisYear) - 65 + '-04-01',
  dateOfBirthMax: parseInt(StringLiterals.thisYear) - 16 + '-04-01',
  retirementAge: null,
  termToDateOfLeaving: 1,
  atBankUntilRetirement: true,
  maxFlexTerm: 0,
  salary: 0,
  flexTerm: null,
  flexRate1: 65,
  flexRate2: 65,
  dcContributionRate1: 0,
  dcContributionRate2: 0,
  careTransferInPension: 0,
  membershipType: 'existingMember',
  finalSalaryTransferInPension: 0,
  salaryIncreaseRate: 0.035,
  investmentReturn: 0.05,
  legacyDbPension: 0,
  legacyDcFund: 0,
  effectiveDateOfTotalRewardStatement: StringLiterals.lastYear + '-06-01',
  effectiveDateOfTotalRewardStatementMin: StringLiterals.lastYear + '-04-01',
  effectiveDateOfTotalRewardStatementMax: StringLiterals.thisYear + '-03-31',
  standardAccrualRate: 65,
  currentAccrualRate: 50,
  pastServiceFinalSalaryPension: 0,
  pastServiceCarePension: 0,
  pastServiceCarePensionPost20: 0,
  otherBankTaxableIncome: 0,
  otherExternalTaxableIncome: 0,
  dcContributions: 0,
  annualAllowanceCarryForward: 0,
  otherAnnualAllowanceValue: 0,
  marginalTaxRate: 0.45,
  firstYearSalaryIncrease: 0.01,
  secondYearSalaryIncrease: 0.01,
  performanceAward: 0.1,
  firstYearRpiRevaluation: 0.03,
  secondYearRpiRevaluation: 0.03,
  secondYearCpiRevaluation: 0.02,
  futureTerms: false,
};

const userDataReducer = (oldState: UserDataStore = defaultState, action: reducerAction): UserDataStore => {
  const { payload } = action || {};
  switch (action?.type) {
    case reducerActions.UPDATE_USER_DATA:
      return { ...oldState, ...payload };
    case reducerActions.RESET_ALL:
      return { ...defaultState };
    default:
      return oldState;
  }
};

export default userDataReducer;
