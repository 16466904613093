export default class OutputLtaResults {
  public legacyDcFundAtRetirementAge: number;
  public past: number;
  public legacy: number;
  public future: number;
  public ltaAtRetirementAge: number;
  public additionalFinalSalaryTransferInPensionAtRetirementAge: number;
  public pastServiceCarePensionAtRetirementAge: number;
  public additionalCareTransferInPensionAtRetirementAge: number;

  constructor() {
    this.legacyDcFundAtRetirementAge = 0;
    this.past = 0;
    this.legacy = 0;
    this.future = 0;
    this.ltaAtRetirementAge = 0;
    this.additionalFinalSalaryTransferInPensionAtRetirementAge = 0;
    this.pastServiceCarePensionAtRetirementAge = 0;
    this.additionalCareTransferInPensionAtRetirementAge = 0;
  }
};
