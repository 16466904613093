import { AdvancedDate as AdvDate } from '@lifetime-gallagher/calculator-toolkit';
import DateFormatter from './lib/dateFormatter';

export default class Person {
  public dateOfBirth: AdvDate;
  public effectiveDateOfTotalRewardStatement: AdvDate;
  public termToDateOfLeaving: number;
  public salary: number;
  public standardAccrualRate: number;
  public membership: number;
  public currentAccrualRate: number;
  public pastServiceCarePension: number;
  public pastServiceCarePensionPost20: number;
  public retirementAge: number;
  public salaryIncreaseRate: number;
  public flexTerm: number;
  public investmentReturn: number;
  public careTransferInPension: number;
  public dcContributionRate1: number;
  public dcContributionRate2: number;
  public finalSalaryTransferInPension: number;
  public pastServiceFinalSalaryPension: number;
  public flexRate1: number;
  public flexRate2: number;
  public legacyDcFund: number;
  public legacyDbPension: number;
  public dateFormatter: DateFormatter;

  public firstYearSalaryIncrease: number;
  public performanceAward: number;
  public secondYearSalaryIncrease: number;
  public otherBankTaxableIncome: number;
  public otherExternalTaxableIncome: number;
  public firstYearRpiRevaluation: number;
  public secondYearRpiRevaluation: number;
  public secondYearCpiRevaluation: number;
  public dcContributions: number;
  public annualAllowanceCarryForward: number;
  public otherAnnualAllowanceValue: number;
  public marginalTaxRate: number;

  constructor(data: any) {
    this.dateFormatter = new DateFormatter();
    this.dateOfBirth = this.dateFormatter.advDateFromString(data.person.dateOfBirth);
    this.effectiveDateOfTotalRewardStatement = this.dateFormatter.advDateFromString(
      data.person.effectiveDateOfTotalRewardStatement
    );
    this.termToDateOfLeaving = data.person.termToDateOfLeaving;
    this.salary = data.person.salary;
    this.standardAccrualRate = data.person.standardAccrualRate;
    this.membership = data.person.membership;
    this.currentAccrualRate = data.person.currentAccrualRate;
    this.pastServiceCarePension = data.person.pastServiceCarePension;
    this.pastServiceCarePensionPost20 = data.person.pastServiceCarePensionPost20;
    this.retirementAge = data.person.retirementAge;
    this.salaryIncreaseRate = data.person.salaryIncreaseRate;
    this.flexTerm = data.person.flexTerm;
    this.investmentReturn = data.person.investmentReturn;
    this.careTransferInPension = data.person.careTransferInPension;
    this.dcContributionRate1 = data.person.dcContributionRate1;
    this.dcContributionRate2 = data.person.dcContributionRate2;
    this.finalSalaryTransferInPension = data.person.finalSalaryTransferInPension;
    this.pastServiceFinalSalaryPension = data.person.pastServiceFinalSalaryPension;
    this.flexRate1 = data.person.flexRate1;
    this.flexRate2 = data.person.flexRate2;
    this.legacyDcFund = data.person.legacyDcFund;
    this.legacyDbPension = data.person.legacyDbPension;
    this.firstYearSalaryIncrease = data.person.firstYearSalaryIncrease;
    this.performanceAward = data.person.performanceAward;
    this.secondYearSalaryIncrease = data.person.secondYearSalaryIncrease;
    this.otherBankTaxableIncome = data.person.otherBankTaxableIncome;
    this.otherExternalTaxableIncome = data.person.otherExternalTaxableIncome;
    this.firstYearRpiRevaluation = data.person.firstYearRpiRevaluation;
    this.secondYearRpiRevaluation = data.person.secondYearRpiRevaluation;
    this.secondYearCpiRevaluation = data.person.secondYearCpiRevaluation;
    this.dcContributions = data.person.dcContributions;
    this.annualAllowanceCarryForward = data.person.annualAllowanceCarryForward;
    this.otherAnnualAllowanceValue = data.person.otherAnnualAllowanceValue;
    this.marginalTaxRate = data.person.marginalTaxRate;
  }
}
