import React from 'react';
import BaseBarChart from './base_bar_chart';

import { usePensionValuationOutput } from '../../store';
import formatCompoundBarTotal from './label_formatters';

const PensionValuationChart = () => {
  const { baselinePensionValuation, scenarioPensionValuation, salaryImplications } = usePensionValuationOutput();
  const options = {
    chart: {
      type: 'column',
      height: 300,
    },
    accessibility: {
      keyboardNavigation: {
        focusBorder: {
          style: {
            lineWidth: 3,
            color: '#aa1111',
            borderRadius: 5,
          },
          margin: 4,
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: ['Current position, no alternative choices', 'Position after your choices', 'Salary adjustment'],
      labels: {
        useHtml: true,
        y: 30,
        style: {
          fontSize: '14px',
          lineHeight: '16px',
          color: '#12273F',
          marginTop: '15px',
          fontWeight: '500',
        },
      },
    },
    yAxis: {
      stackLabels: {
        enabled: true,
        formatter: formatCompoundBarTotal,
      },
      visible: true,
      title: {
        enabled: false,
      },
      labels: {
        format: '£{text}',
        useHtml: true,
        style: {
          fontSize: '13px',
          lineHeight: '16px',
          color: '#12273F',
          marginTop: '15px',
          fontWeight: '500',
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        animation: {
          duration: 100,
        },
        stacking: 'normal',
      },
      bar: {
        dataLabels: {
          enabled: true,
          format: '{point.series.name}',
        },
      },
    },
    tooltip: {
      pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>£{point.y}<br/>',
      shared: true,
    },
    series: [
      {
        name: 'Future Service Pension',
        data: [
          Math.round(baselinePensionValuation.total) - Math.round(baselinePensionValuation.pastService),
          Math.round(scenarioPensionValuation.total) - Math.round(scenarioPensionValuation.pastService),
        ],
        stack: '1',
        color: '#3CD7D9',
        pointPadding: 0,
        groupPadding: 0.05,
      },
      {
        name: 'Past Service Pension',
        data: [Math.round(baselinePensionValuation.pastService), Math.round(scenarioPensionValuation.pastService)],
        stack: '1',
        color: '#415265',
        pointPadding: 0,
        groupPadding: 0.05,
      },
      {
        name: 'Adjustment',
        data: [null, null, Math.floor(salaryImplications.adjustmentForScenario)],
        stack: '1',
        color: salaryImplications.adjustmentForScenario >= 0 ? '#D4AF37' : '#FF0000',
        pointPadding: 0,
        groupPadding: 0.05,
        format: `${salaryImplications.adjustmentForScenario}`,
      },
      {
        name: 'Current Salary',
        data: [null, null, Math.floor(salaryImplications.current)],
        stack: '1',
        color: '#415265',
        pointPadding: 0,
        groupPadding: 0.05,
      },
    ],
  };

  return <BaseBarChart chartOptions={options} />;
};

export default PensionValuationChart;
