import { AdvancedDate as AdvDate, dateUtils } from '@lifetime-gallagher/calculator-toolkit';

export default class AnnuityTable {
  public startYear: number;
  public annuities: any;

  constructor(calculationDate: any, annuities: any) {
    this.startYear = calculationDate.getFullYear() - 65;
    this.annuities = annuities;
  }

  public getAnnuity(age: number, yearOfBirth: number) {
    let index: number;
    let annuities: any;

    index = yearOfBirth - this.startYear;
    annuities = this.annuities[age];
    return annuities && annuities.length >= index && index >= 0 ? annuities[index] : NaN;
  };
};
