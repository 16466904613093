import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { AdvancedDate } from '@lifetime-gallagher/calculator-toolkit';
import { PageUrls } from '../../../types';
import { useUserData, useUpdateUserData, useUiOptions, useUpdateUiOptions } from '../../../store';
import useDocumentTitle from '../../../store/use_document_title';

import './your_details.scss';

import RadioInputs from '../../inputs/radio_inputs';
import MoneyInput from '../../inputs/money_input';
import InformationBox from '../../containers/information_box';
import StringLiterals from '../../utils/stringLiterals';
import HelpLabel from '../../help_label/help_label';

const YourDetails = () => {
  const {
    membershipType,
    standardAccrualRate,
    currentAccrualRate,
    dateOfBirth,
    dateOfBirthMin,
    dateOfBirthMax,
    effectiveDateOfTotalRewardStatement,
    effectiveDateOfTotalRewardStatementMin,
    effectiveDateOfTotalRewardStatementMax,
    salary,
    pastServiceCarePension,
    pastServiceCarePensionPost20,
    careTransferInPension,
    pastServiceFinalSalaryPension,
    finalSalaryTransferInPension,
  } = useUserData();

  const updateUserData = useUpdateUserData();
  const { hasEnteredValidUserDetails, yourDetailsErrorFields } = useUiOptions();
  const updateUiOptions = useUpdateUiOptions();

  const dateOfBirthId = 'date-of-birth';
  const dateOfTrsId = 'effective-date-trs';

  const [dateValidationErrors, setDateValidationErrors] = useState<{
    dateOfBirth: string[];
    dateOfTrs: string[];
  }>({
    dateOfBirth: [],
    dateOfTrs: [],
  });

  const addErrorOnField = (field: string) => {
    yourDetailsErrorFields.indexOf(field) === -1 ? yourDetailsErrorFields.push(field) : null;
  };

  const removeErrorFromField = (removalField: string) => {
    const fieldIndex = yourDetailsErrorFields.indexOf(removalField);
    yourDetailsErrorFields.splice(fieldIndex, fieldIndex !== -1 ? 1 : 0);
  };

  const validateDateIsInCorrectFormat = (inputDate: AdvancedDate) => {
    return inputDate.date.toString() !== 'Invalid Date';
  };

  const validateDateOfBirth = () => {
    const dateOfBirthObject = new AdvancedDate(dateOfBirth);
    const dateOfBirthMinObject = new AdvancedDate(dateOfBirthMin);
    const dateOfBirthMaxObject = new AdvancedDate(dateOfBirthMax);
    const newDateValidationErrors: string[] = [];

    const dateIsNotInCorrectFormat = !validateDateIsInCorrectFormat(dateOfBirthObject)
      ? 'Date is not in the required format (dd/mm/yyyy).'
      : '';

    const dateIsNotWithinBoundsError =
      dateOfBirthObject.before(dateOfBirthMinObject) || dateOfBirthObject.after(dateOfBirthMaxObject)
        ? 'Must be between ' + dateOfBirthMinObject.toString() + ' and ' + dateOfBirthMaxObject.toString()
        : '';

    addErrorOnField(dateOfBirthId);

    if (dateIsNotInCorrectFormat) {
      newDateValidationErrors.push(dateIsNotInCorrectFormat);
    } else if (dateIsNotWithinBoundsError) {
      newDateValidationErrors.push(dateIsNotWithinBoundsError);
    } else {
      removeErrorFromField(dateOfBirthId);
    }

    setDateValidationErrors((prevState) => {
      return { ...prevState, dateOfBirth: newDateValidationErrors };
    });
  };

  const validateDateOfTrs = () => {
    const dateOfTrsObject = new AdvancedDate(effectiveDateOfTotalRewardStatement);
    const dateOfTrsMinObject = new AdvancedDate(effectiveDateOfTotalRewardStatementMin);
    const dateOfTrsMaxObject = new AdvancedDate(effectiveDateOfTotalRewardStatementMax);
    const newDateValidationErrors: string[] = [];

    const dateIsNotInCorrectFormat = !validateDateIsInCorrectFormat(dateOfTrsObject)
      ? 'Date is not in the required format (dd/mm/yyyy).'
      : '';

    const dateIsNotWithinBoundsError =
      dateOfTrsObject.before(dateOfTrsMinObject) || dateOfTrsObject.after(dateOfTrsMaxObject)
        ? 'Must be between ' + dateOfTrsMinObject.toString() + ' and ' + dateOfTrsMaxObject.toString()
        : '';

    addErrorOnField(dateOfTrsId);

    if (dateIsNotInCorrectFormat) {
      newDateValidationErrors.push(dateIsNotInCorrectFormat);
    } else if (dateIsNotWithinBoundsError) {
      newDateValidationErrors.push(dateIsNotWithinBoundsError);
    } else {
      removeErrorFromField(dateOfTrsId);
    }

    setDateValidationErrors((prevState) => {
      return { ...prevState, dateOfTrs: newDateValidationErrors };
    });
  };

  useEffect(
    function updateUserDetailsValidityWhenInputErrorsChange() {
      let userInputsAreValid = true;

      for (const dateInputValidationErrors of Object.values(dateValidationErrors)) {
        if (dateInputValidationErrors.length > 0) {
          userInputsAreValid = false;
          break;
        }
      }

      if (
        !validateDateIsInCorrectFormat(new AdvancedDate(dateOfBirth)) ||
        !validateDateIsInCorrectFormat(new AdvancedDate(effectiveDateOfTotalRewardStatement)) ||
        yourDetailsErrorFields.length > 0
      ) {
        userInputsAreValid = false;
      }

      updateUiOptions({ hasEnteredValidUserDetails: userInputsAreValid });
    },
    [dateValidationErrors]
  );

  useDocumentTitle(StringLiterals.title + 'Your details');

  return (
    <div className="your-details-page-content">
      <h1>Your details</h1>

      <form noValidate className="user-details-form">
        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="membership-type"
            description="Select your membership type"
            extendedDescription="If you have a recent Total Reward Statement (TRS) that contains your pension information, please select ‘Existing member’. If you have recently joined the Bank and do not yet have a Total Reward Statement, please select ‘New joiner’."
          />
          <RadioInputs
            labelledBy="membership-type"
            selected={membershipType}
            handleChange={(value) =>
              updateUserData({
                membershipType: value,
                standardAccrualRate: value.toString() === 'newJoiner' ? 95 : 50,
                currentAccrualRate: value.toString() === 'newJoiner' ? 95 : 50,
              })
            }
            radioOptions={[
              { label: 'Existing member', value: 'existingMember' },
              { label: 'New joiner', value: 'newJoiner' },
            ]}
          />
        </fieldset>

        {membershipType === 'existingMember' && (
          <>
            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="standard-accrual-rate"
                description="Standard accrual rate"
                extendedDescription="This is your non-contributory pension accrual rate i.e. the standard rate at which you would accrue pension if you choose to neither increase nor decrease your salary in exchange for pension."
              />
              <RadioInputs
                labelledBy="standard-accrual-rate"
                selected={standardAccrualRate}
                handleChange={(value) => updateUserData({ standardAccrualRate: value })}
                radioOptions={[
                  { label: '95ths', value: 95 },
                  { label: '65ths', value: 65 },
                  { label: '50ths', value: 50 },
                ]}
              />
            </fieldset>

            <InformationBox>
              <p>You will need your latest Total Reward Statement (TRS) to find the information requested below.</p>
            </InformationBox>

            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="current-accrual-rate"
                description="Current accrual rate"
                extendedDescription={
                  'This is the pension accrual rate you have already chosen for the ' +
                  StringLiterals.taxYear +
                  ' year.'
                }
              />
              <RadioInputs
                labelledBy="current-accrual-rate"
                selected={currentAccrualRate}
                handleChange={(value) => updateUserData({ currentAccrualRate: value })}
                radioOptions={[
                  { label: '120ths', value: 120 },
                  { label: '110ths', value: 110 },
                  { label: '95ths', value: 95 },
                  { label: '80ths', value: 80 },
                  { label: '65ths', value: 65 },
                  { label: '50ths', value: 50 },
                ]}
              />
            </fieldset>
          </>
        )}

        <fieldset className="input-fieldset">
          <HelpLabel labelFor={dateOfBirthId} description="Date of birth" extendedDescription="" />
          <div>
            <input
              type="date"
              data-cy={dateOfBirthId}
              value={dateOfBirth}
              onChange={(event) => {
                updateUserData({ dateOfBirth: String(event.target.value) });
              }}
              onBlur={(event) => {
                updateUserData({ dateOfBirth: String(event.target.value) });
                validateDateOfBirth();
              }}
              id={dateOfBirthId}
              className={`date-picker ${dateValidationErrors?.dateOfBirth?.length > 0 && 'errored'}`}
            />
            {dateValidationErrors?.dateOfBirth?.length > 0 && (
              <ul className="input-validation-errors">
                {dateValidationErrors.dateOfBirth.map((error) => (
                  <li key={`dob-${error}`}>{error}</li>
                ))}
              </ul>
            )}
          </div>
        </fieldset>

        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor={dateOfTrsId}
            description={membershipType === 'existingMember' ? 'Effective date of TRS' : 'Date of joining'}
            extendedDescription={
              membershipType === 'newJoiner'
                ? 'This is the date you became a member of the Bank of England Pension Fund.'
                : ''
            }
          />
          <div>
            <input
              type="date"
              id={dateOfTrsId}
              value={effectiveDateOfTotalRewardStatement}
              onChange={(event) => {
                updateUserData({ effectiveDateOfTotalRewardStatement: String(event.target.value) });
              }}
              onBlur={(event) => {
                updateUserData({ effectiveDateOfTotalRewardStatement: String(event.target.value) });
                validateDateOfTrs();
              }}
              className={`date-picker ${dateValidationErrors?.dateOfTrs?.length > 0 && 'errored'}`}
            />
            {dateValidationErrors?.dateOfTrs?.length > 0 && (
              <ul className="input-validation-errors">
                {dateValidationErrors.dateOfTrs.map((error) => (
                  <li key={`trs-${error}`}>{error}</li>
                ))}
              </ul>
            )}
          </div>
        </fieldset>

        <fieldset className="input-fieldset">
          <HelpLabel
            labelFor="pensionable-salary"
            description="Pensionable salary"
            extendedDescription="Pensionable salary is your base pay plus, if applicable, any pensionable shift allowance you may have. If you are a part time worker, please use your actual salary and not full time equivalent."
          />
          <div>
            <MoneyInput
              value={salary}
              onChange={(value) => {
                updateUserData({ salary: value });
              }}
              labelledBy="pensionable-salary"
              max={500000}
              min={0}
            />
          </div>
        </fieldset>

        {membershipType === 'existingMember' && standardAccrualRate === 50 && (
          <>
            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="past-service-final-salary-pension"
                description="Final Salary pension based on service to effective date of TRS"
                extendedDescription="This is your Final Salary pension based on your service in the Bank of England Pension Fund. IMPORTANT: It should exclude any transferred-in Final Salary pension, which should instead be included in the box below. The figure on your TRS includes any transferred-in pension you have in the Final Salary section and so the amount of the transferred-in pension should be deducted to arrive at the figure to be inputted here. The transferred-in pension should then be included in the box below instead."
              />
              <MoneyInput
                value={pastServiceFinalSalaryPension}
                onChange={(value) => updateUserData({ pastServiceFinalSalaryPension: value })}
                labelledBy="past-service-final-salary-pension"
                max={200000}
                min={0}
              />
            </fieldset>

            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="final-salary-transfer-in-pension"
                description="Additional Final Salary transferred-in pension"
                extendedDescription="If you have Final Salary benefits as a result of a transfer in of pension benefits previously earned elsewhere, these benefits will be notified to you separately and should be entered into this box. IMPORTANT: You should also deduct the amount of the transferred-in pension from the figure on your TRS when entering your Final Salary pension in the box above."
              />
              <MoneyInput
                value={finalSalaryTransferInPension}
                onChange={(value) => updateUserData({ finalSalaryTransferInPension: value })}
                labelledBy="final-salary-transfer-in-pension"
                max={100000}
                min={0}
              />
            </fieldset>

            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="past-service-care-pension"
                description="Accrued Career Average Pension (Pre 1 April 2020)"
                extendedDescription="This is your Career Average pension based on your service in the Bank of England Pension Fund prior to 1 April 2020, as shown on your TRS."
              />
              <MoneyInput
                value={pastServiceCarePension}
                onChange={(value) => updateUserData({ pastServiceCarePension: value })}
                labelledBy="past-service-care-pension"
                max={200000}
                min={0}
              />
            </fieldset>
          </>
        )}

        {membershipType === 'existingMember' && (
          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="accrued-career-average-pension"
              description={`Accrued Career Average Pension${standardAccrualRate === 50 ? ' (Post 31 March 2020)' : ''}`}
              extendedDescription={`This is your Career Average pension based on your service in the Bank of England Pension Fund${
                standardAccrualRate === 50 ? ' from 1 April 2020' : ''
              }, as shown on your TRS.`}
            />
            <MoneyInput
              value={pastServiceCarePensionPost20}
              onChange={(value) => updateUserData({ pastServiceCarePensionPost20: value })}
              labelledBy="accrued-career-average-pension"
              max={200000}
              min={0}
            />
          </fieldset>
        )}

        {membershipType === 'existingMember' && standardAccrualRate === 95 && (
          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="care-transfer-in-pension"
              description="Current value of transferred-in benefits (if applicable)"
              extendedDescription="If you are a former member of the FCA scheme and you chose to transfer your benefits from the FCA scheme into the CARE section of the Bank of England Pension Fund, this is the amount of your transferred-in pension as shown on your TRS."
            />
            <MoneyInput
              value={careTransferInPension}
              onChange={(value) => updateUserData({ careTransferInPension: value })}
              labelledBy="care-transfer-in-pension"
            />
          </fieldset>
        )}
      </form>

      <div className="page-navigation-buttons">
        <NavLink
          className={`button button--page-navigation ${!hasEnteredValidUserDetails && 'disabled'}`}
          to={PageUrls.yourChoices}
          id="continue-to-your-choices"
          onClick={(event) => {
            if (!hasEnteredValidUserDetails) {
              event.preventDefault();
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }
          }}
        >
          Next
        </NavLink>
      </div>
    </div>
  );
};

export default YourDetails;
