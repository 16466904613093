import React, { useState } from 'react';

import { NavLink } from 'react-router-dom';
import { PageUrls } from '../../../types';

import { useAnnualAllowanceOutput, useUserData, useUpdateUserData } from '../../../store';

import './annual_allowance.scss';
import Formatter from '../../utils/formatter';
import MoneyInput from '../../inputs/money_input';
import PercentageInput from '../../inputs/percentage_input';
import RadioInputs from '../../inputs/radio_inputs/radio_inputs';
import AnnualAllowanceTable from './annual_allowance_table';
import StringLiterals from '../../utils/stringLiterals';
import HelpLabel from '../../help_label/help_label';
import useDocumentTitle from '../../../store/use_document_title';

const AnnualAllowance = () => {
  const {
    salary,
    standardAccrualRate,
    currentAccrualRate,
    otherBankTaxableIncome,
    otherExternalTaxableIncome,
    annualAllowanceCarryForward,
    otherAnnualAllowanceValue,
    marginalTaxRate,
    dcContributions,
  } = useUserData();
  const updateUserData = useUpdateUserData();
  const annualAllowanceOutput = useAnnualAllowanceOutput();
  const [selectedAccrualRates, setSelectedAccrualRates] = useState(new Set<number>([currentAccrualRate]));
  const formatter = new Formatter();

  const MIN_VIEWPORT_WIDTH_TO_SELECT_ALL_ACCRUAL_RATES = 990;
  const handleAccrualRateSelection = (accrualRate: number) => {
    let newAccrualRateSelections = new Set(selectedAccrualRates);

    if (window.innerWidth <= MIN_VIEWPORT_WIDTH_TO_SELECT_ALL_ACCRUAL_RATES) {
      newAccrualRateSelections = new Set([accrualRate]);
    } else if (selectedAccrualRates.has(accrualRate)) {
      newAccrualRateSelections.delete(accrualRate);
    } else {
      newAccrualRateSelections.add(accrualRate);
    }

    newAccrualRateSelections.add(currentAccrualRate);

    const reverseSortedAccrualRateSelections = Array.from(newAccrualRateSelections).sort((a, b) => b - a);

    setSelectedAccrualRates(new Set(reverseSortedAccrualRateSelections));
  };

  const allowanceResultsForCurrentAccrualRate =
    annualAllowanceOutput[`annualAllowanceForAccrualRate${currentAccrualRate}`];

  const { firstExpectedSalary, flexibleBenefitsPot, performanceAward, secondExpectedSalary } =
    allowanceResultsForCurrentAccrualRate;

  useDocumentTitle(StringLiterals.title + 'Annual Allowance');

  return (
    <div className="annual-allowance-page-content">
      <section>
        <h1>Annual Allowance</h1>
        <p>
          This page shows some of the potential Annual Allowance (AA) pensions tax implications for the{' '}
          {StringLiterals.nextTaxYear} tax year depending which of the six accrual rates you choose for the{' '}
          {StringLiterals.nextTaxYear} year. It is based on some of the data you have already inputted on the &apos;Your
          details&apos; tab and requires you to input some further information needed for the AA tax calculation. This
          AA calculator also allows you to vary some of the key factors that will affect the calculation, such as salary
          increases and RPI and CPI inflation at various points over the next couple of years.
        </p>
      </section>

      <section>
        <h2>Your details and inputs</h2>
        <div className="accordion-content active">
          <div className="accordion-content__inner-wrapper">
            <div className="change-inputs-section">
              <div className="change-inputs-section__details">
                <h2>Your details</h2>
                <ul>
                  <li>
                    <HelpLabel
                      labelFor="aa-pensionable-salary"
                      description="Pensionable salary"
                      extendedDescription="Pensionable salary is your base pay plus, if applicable, any pensionable shift allowance you may have. If you are a part time worker, please use your actual salary and not full time equivalent."
                    />
                    <span>{formatter.asCurrencyWithoutPence(salary)}</span>
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-standard-accrual-rate"
                      description="Standard accrual rate"
                      extendedDescription="This is your non-contributory pension accrual rate i.e. the standard rate at which you would accrue pension if you choose to neither increase nor decrease your salary in exchange for pension."
                    />
                    <span>{standardAccrualRate}ths</span>
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-current-accrual-rate"
                      description={'Current accrual rate (for ' + StringLiterals.taxYear + ')'}
                      extendedDescription={
                        'This is the pension accrual rate you have already chosen for the ' +
                        StringLiterals.taxYear +
                        ' CARE year.'
                      }
                    />
                    <span>{currentAccrualRate}ths</span>
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-expected-salary"
                      description={'Expected salary as at 1 March ' + StringLiterals.thisYear}
                      extendedDescription={
                        'Your expected salary based on the salary increase you expect to receive on 1 March  ' +
                        StringLiterals.thisYear +
                        '. You can vary this assumed increase later.'
                      }
                    />

                    <span>{formatter.asCurrencyWithoutPence(firstExpectedSalary)}</span>
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-flexible-benefits-pot"
                      description={'Flexible benefits pot for ' + StringLiterals.nextTaxYear}
                      extendedDescription=""
                    />
                    <span>{formatter.asCurrencyWithoutPence(flexibleBenefitsPot)}</span>
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-performance-award"
                      description={'Performance award expected March ' + StringLiterals.nextYear}
                      extendedDescription={
                        'The Performance Award you expect to receive in March ' +
                        +StringLiterals.nextYear +
                        '. You can vary this assumed amount later.'
                      }
                    />
                    <span>{formatter.asCurrencyWithoutPence(performanceAward)}</span>
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-expected-salary-2024"
                      description={'Expected salary as at 1 March ' + StringLiterals.nextYear}
                      extendedDescription={
                        'Your expected salary based on the salary increases you expect to receive on 1 March ' +
                        StringLiterals.thisYear +
                        ' and 1 March ' +
                        StringLiterals.nextYear +
                        '. You can vary these assumed increases later.'
                      }
                    />
                    <span>{formatter.asCurrencyWithoutPence(secondExpectedSalary)}</span>
                  </li>
                </ul>
              </div>

              <div className="change-inputs-section__inputs">
                <h2>Extra inputs</h2>
                <ul>
                  <li>
                    <HelpLabel
                      labelFor="aa-other-bank-taxable-income"
                      description={'Other Bank taxable income for ' + StringLiterals.nextTaxYear}
                      extendedDescription={
                        'Please enter here any other taxable income you expect to receive from the Bank during the  ' +
                        StringLiterals.nextTaxYear +
                        ' tax year not including the amounts already shown above (e.g. leave sell back and core taxable benefits such as Private Medical Insurance.'
                      }
                    />
                    <MoneyInput
                      value={otherBankTaxableIncome}
                      hideAYear
                      labelledBy="aa-other-bank-taxable-income"
                      cyName="aa-other-bank-taxable-income"
                      onChange={(value) => {
                        updateUserData({ otherBankTaxableIncome: value });
                      }}
                    />
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-other-external-taxable-income"
                      description={'Other external taxable income for ' + StringLiterals.nextTaxYear}
                      extendedDescription={
                        'Please enter here any other taxable income you expect to receive from other sources (e.g. interest on investments, rental income, other paid employment etc.) during the ' +
                        StringLiterals.nextTaxYear +
                        ' tax year.'
                      }
                    />
                    <MoneyInput
                      value={otherExternalTaxableIncome}
                      hideAYear
                      labelledBy="aa-other-external-taxable-income"
                      cyName="aa-other-external-taxable-income"
                      onChange={(value) => {
                        updateUserData({ otherExternalTaxableIncome: value });
                      }}
                    />
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-carry-forward"
                      description={
                        'AA carry forward from ' +
                        StringLiterals.lastLastTaxYear +
                        ' and ' +
                        StringLiterals.lastTaxYear +
                        ' tax years'
                      }
                      extendedDescription={
                        'This is the amount of any unused Annual Allowance you have carried forward from the ' +
                        StringLiterals.lastLastTaxYear +
                        ' and ' +
                        StringLiterals.lastTaxYear +
                        ' tax years i.e. the extent to which the AA value of your pension benefits was lower than the Annual Allowance permitted for those years. You should be able to obtain the AA value of your pension benefits from the administrators of the pension schemes that you were a member of during those years.'
                      }
                    />
                    <MoneyInput
                      value={annualAllowanceCarryForward}
                      hideAYear
                      labelledBy="aa-carry-forward"
                      cyName="aa-carry-forward"
                      onChange={(value) => {
                        updateUserData({ annualAllowanceCarryForward: value });
                      }}
                    />
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-other-value"
                      description={'Other AA value for ' + StringLiterals.taxYear + ' tax year (e.g. DC contributions)'}
                      extendedDescription={
                        'Please enter here any other amount you expect to be counted towards your AA calculation for the period between 6 April  ' +
                        StringLiterals.lastYear +
                        '  and 5 April  ' +
                        StringLiterals.thisYear +
                        '. This should exclude Final Salary and CARE benefits in the Bank Fund (which have already been counted above) but should include any DC contributions paid to the SPP or any pension benefits being built up elsewhere e.g. DC contributions paid to external arrangements.'
                      }
                    />
                    <MoneyInput
                      value={otherAnnualAllowanceValue}
                      hideAYear
                      labelledBy="aa-other-value"
                      cyName="aa-other-value"
                      onChange={(value) => {
                        updateUserData({ otherAnnualAllowanceValue: value });
                      }}
                    />
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="dc-contributions-during-tax-year"
                      description={'DC contributions during ' + StringLiterals.nextTaxYear + ' tax year'}
                      extendedDescription={
                        'Please enter here the monetary amount of any contributions you expect to make to any Defined Contribution arrangements (including the Bank SPP) during the ' +
                        StringLiterals.nextTaxYear +
                        ' tax year.'
                      }
                    />
                    <MoneyInput
                      value={dcContributions}
                      labelledBy="dc-contributions-during-tax-year"
                      hideAYear
                      cyName="dc-contributions-during-tax-year"
                      onChange={(value) => {
                        updateUserData({ dcContributions: value });
                      }}
                    />
                  </li>
                  <li>
                    <HelpLabel
                      labelFor="aa-expected-marginal-rate"
                      description={'Expected marginal rate of income tax for ' + StringLiterals.nextTaxYear}
                      extendedDescription={
                        'Please enter here your assumed marginal rate of income tax for the ' +
                        StringLiterals.nextTaxYear +
                        ' tax year.'
                      }
                    />

                    <PercentageInput
                      value={marginalTaxRate}
                      labelledBy="aa-expected-marginal-rate"
                      cyName="aa-expected-marginal-rate"
                      onChange={(value) => {
                        updateUserData({ marginalTaxRate: value });
                      }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="select-accrual-rates-section">
        <fieldset className="input-fieldset select-accrual-rates">
          <HelpLabel
            labelFor="select-accrual-rates"
            description="Select a new accrual rate"
            extendedDescription="The pension accrual rate is the fraction of salary provided as pension for each year of service in the pension fund e.g. a 65ths accrual rate means you would earn a pension of 1/65 (1.54%) of your salary each year, whereas a 95ths accrual rate means you would earn a pension of 1/95 (1.05%) of your salary each year."
          />

          <RadioInputs
            labelledBy="select-accrual-rates"
            allowMultipleSelections
            selected={selectedAccrualRates}
            handleChange={(value) => handleAccrualRateSelection(Number(value))}
            radioOptions={[
              { label: '120ths', value: 120 },
              { label: '110ths', value: 110 },
              { label: '95ths', value: 95 },
              { label: '80ths', value: 80 },
              { label: '65ths', value: 65 },
              { label: '50ths', value: 50 },
            ]}
          />
        </fieldset>
      </section>

      <section>
        <AnnualAllowanceTable selectedAccrualRates={selectedAccrualRates} />

        <p className="disclaimer">
          The figures shown on this page are projected estimates only and should not be taken as actual figures to be
          used for your own tax self-assessment in due course. In addition, any estimates provided on this page should
          only be taken to constitute the provision of information and not the provision of advice or any other
          regulated activity under the Financial Services and Markets Act 2000.
        </p>

        <div className="page-navigation-buttons">
          <NavLink className="button button--page-navigation" to={PageUrls.furtherInformation}>
            Next
          </NavLink>
        </div>
      </section>
    </div>
  );
};

export default AnnualAllowance;
