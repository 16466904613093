import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { PageUrls } from '../../../types';
import { useUserData, useUpdateUserData } from '../../../store';
import useDocumentTitle from '../../../store/use_document_title';
import { Tooltip } from 'react-tooltip';
import { PensionOptionsTab, RetirementAgeTab, FutureIncreasesTab, CompareLtaTab } from './choices_tabs';
import { PensionValuationChart, LtaChartBaseline, LtaChartScenario } from '../../charts';
import Accordion from '../../containers/accordion';
import DefaultEngineData from '../../../engine/config/engine.json';
import Formatter from '../../utils/formatter';
import StringLiterals from '../../utils/stringLiterals';

import './your_choices.scss';

const YourChoices = () => {
  const { currentAccrualRate, futureTerms } = useUserData();
  const updateUserData = useUpdateUserData();
  const resetUserChoices = () => {
    updateUserData({
      flexRate1: currentAccrualRate,
      flexRate2: currentAccrualRate,
      dcContributionRate1: 0,
      dcContributionRate2: 0,
      salaryIncreaseRate: 0.035,
      investmentReturn: 0.05,
      legacyDbPension: 0,
      legacyDcFund: 0,
      futureTerms: false,
      termToDateOfLeaving: 1,
      retirementAge: null,
      atBankUntilRetirement: true,
    });
  };

  type UserChoicesTab = 'pensionOptions' | 'retirementAge' | 'futureIncreases' | 'compareLta';
  const [activeChoicesTab, setActiveChoicesTab] = useState<UserChoicesTab>('pensionOptions');
  const [previousActiveChoicesTab, storePreviousChoicesTab] = useState<UserChoicesTab>('pensionOptions');
  const UserChoicesTabComponents = {
    pensionOptions: <PensionOptionsTab />,
    retirementAge: <RetirementAgeTab />,
    futureIncreases: <FutureIncreasesTab />,
    compareLta: <CompareLtaTab />,
  };

  const formatter = new Formatter();

  useDocumentTitle(StringLiterals.title + 'Your choices');

  return (
    <div className="your-choices-page-content">
      <h1>Your choices</h1>

      <nav className="choices-tab-navigation">
        <div className="choices-tab-navigation__pension-options">
          <button
            type="button"
            className={`nav-button nav-button--change-tab ${activeChoicesTab === 'pensionOptions' ? 'selected' : ''}`}
            onClick={() => {
              setActiveChoicesTab('pensionOptions');
              storePreviousChoicesTab('pensionOptions');
            }}
          >
            Pension options
          </button>
          <button
            type="button"
            className={`nav-button nav-button--change-tab ${activeChoicesTab === 'retirementAge' ? 'selected' : ''}`}
            onClick={() => {
              setActiveChoicesTab('retirementAge');
              storePreviousChoicesTab('retirementAge');
            }}
          >
            Retirement age
          </button>
          <button
            type="button"
            className={`nav-button nav-button--change-tab ${activeChoicesTab === 'futureIncreases' ? 'selected' : ''}`}
            onClick={() => {
              setActiveChoicesTab('futureIncreases');
              storePreviousChoicesTab('futureIncreases');
            }}
          >
            Future increases
          </button>
        </div>

        <div className="choices-tab-navigation__navlink-container choices-tab-navigation__reset-or-compare">
          <button type="button" className="nav-button nav-button--reset" onClick={resetUserChoices}>
            Reset
          </button>
        </div>
      </nav>

      {UserChoicesTabComponents[activeChoicesTab]}

      <section className="results-section">
        {activeChoicesTab === 'compareLta' ? (
          <>
            <h2>Value of pension benefits</h2>
            <p>
              The charts below show the total future value of the projected pension you may receive at retirement,
              assuming the current Lifetime Allowance test is applied at that future date. The charts are broken down
              into the pension you have already built up from past service and the estimated pension you may earn
              through future service.
            </p>
            <p>
              Please note that the charts below illustrate the estimated total cash value of your pension benefits, and
              not the estimated annual pension you may receive.
            </p>

            <div className="chart-lta">
              <div className="chart-lta__column">
                <LtaChartBaseline />
              </div>
              <div className="chart-lta__column">
                <LtaChartScenario />
              </div>
            </div>

            <div className="chart-footer chart-footer--lta">
              <div className="chart-footer__column">
                <div id="revised-position" className="help-popup">
                  <Tooltip
                    anchorId="revised-position"
                    content="The current position is based on your standard benefits with no alternative choices made and the default assumptions as described below."
                    place="right"
                    variant="dark"
                    style={{ width: '400px', zIndex: 100 }}
                  ></Tooltip>
                </div>
              </div>
              <div className="chart-footer__column">
                <div id="chart-salary-adjustment" className="help-popup">
                  <Tooltip
                    anchorId="chart-salary-adjustment"
                    content="This position shows the result of the options you choose during the Benefits+ window in February/March each year and any assumptions you have chosen in this Calculator."
                    place="right"
                    variant="dark"
                    style={{ width: '400px', zIndex: 100 }}
                  ></Tooltip>
                </div>
              </div>
            </div>

            <p>
              The Lifetime Allowance (LTA) sets the maximum value of pension benefits from all sources you can build up
              during your lifetime on a tax advantaged basis. Any benefits received above the LTA will incur a tax
              charge which will be payable on retirement. The Lifetime Allowance is currently £1,073,100, and is
              scheduled to remain at that level until April 2026, increasing thereafter in line with CPI inflation each
              April. For the purpose of the comparison of benefits against the LTA the value of defined benefit pensions
              are assessed using the statutory factor of 20 for every £1 a year of pension.
            </p>
          </>
        ) : (
          <>
            <h2>Annual pension at retirement {!futureTerms && "in today's earnings terms"}</h2>
            <p>
              Figures shown are approximations only and should not be relied upon as an accurate statement of future
              benefits
            </p>
            <PensionValuationChart />

            <div className="chart-footer">
              <div className="chart-footer__column chart-footer__column--current">
                <div id="current-position" className="help-popup">
                  <Tooltip
                    anchorId="current-position"
                    content="This shows the projected annual pension you may receive at retirement, in today’s earnings terms, broken down into the pension you have already built up from past service and the estimated pension you may earn through future service assuming you remain in service with the Bank until you retire at your assumed retirement age. The current position is based on the current choices you have made as entered on the ‘Your details’ tab and the default assumptions as described below."
                    place="right"
                    variant="dark"
                    style={{ width: '300px', zIndex: 100 }}
                  ></Tooltip>
                </div>
              </div>
              <div className="chart-footer__column chart-footer__column--after">
                <div id="revised-position" className="help-popup">
                  <Tooltip
                    anchorId="revised-position"
                    content="This shows the projected annual pension you may receive at retirement, in today’s earnings terms, as a result of the options you choose during the Benefits+ window in February/March each year.  This is broken down into the pension you have already built up from past service and the estimated pension you may earn through future service."
                    place="right"
                    variant="dark"
                    style={{ width: '300px', zIndex: 100 }}
                  ></Tooltip>
                </div>
              </div>
              <div className="chart-footer__column">
                <div id="chart-salary-adjustment" className="help-popup">
                  <Tooltip
                    anchorId="chart-salary-adjustment"
                    content="This shows the impact of your chosen options on your gross salary (note this only reflects the impact of the immediate options you have chosen above and not any subsequent different options after a certain number of years). You should consider the effect of tax and national insurance on your take home pay. These figures do not take account of your 7% flexible allowance."
                    place="right"
                    variant="dark"
                    style={{ width: '300px', zIndex: 100 }}
                  ></Tooltip>
                </div>
              </div>
            </div>
          </>
        )}
      </section>

      <section className="assumptions-section">
        <Accordion heading="Read more about the assumptions we’ve used in these calculations." includeCloseButton>
          <div className="accordion-content__text-wrapper">
            <h3>Today’s earnings terms</h3>
            <p>
              The illustration shows, in today’s earnings terms, the pension you are expected to build up over the
              remainder of your career at the Bank.
            </p>
            <p>
              It is sometimes easier to understand the value of your pension by comparing it to your current level of
              income. For example, if your salary is projected to be &pound;48,000 by the time you retire and the
              pension you are projected to have built up by that date is &pound;12,000, then you would be expecting your
              income to reduce to 25% of your salary in retirement.
            </p>
            <p>
              When we refer to a pension being shown in today&apos;s earnings terms, we mean that we have taken this
              ratio between salary and pension at retirement and applied it to your current salary. So, in this example,
              if your salary is currently &pound;30,000 your expected income in retirement would be &pound;7,500 when
              shown in today&apos;s earnings terms.
            </p>
            <h3>Key assumptions used in the Calculator</h3>
            <p>
              The actual pension you receive will be affected by factors such as future changes in salary growth and
              inflation. In order to illustrate their impact we have had to make assumptions about the future based on
              what we know today:
            </p>

            <ul>
              <li>
                Future salary increases are assumed to be{' '}
                {formatter.asPercent(DefaultEngineData.assumptions.salaryIncreaseRate, 1)} a year. However, the
                Calculator allows you to choose the level of future salary increases.
              </li>
              <li>
                An allowance is made for promotional salary increases above the&nbsp;
                {formatter.asPercent(DefaultEngineData.assumptions.salaryIncreaseRate, 1)} a year or whatever rate you
                choose.
              </li>
              <li>
                RPI inflation is assumed to be&nbsp;{formatter.asPercent(DefaultEngineData.assumptions.rpi, 1)} a year.
              </li>
              <li>
                CPI inflation is assumed to be&nbsp;{formatter.asPercent(DefaultEngineData.assumptions.cpi, 1)} a year.
              </li>
              <li>
                Future investment returns on defined contribution (DC) benefits are assumed to be 5.0% a year (after
                charges), which is broadly comparable with the Statutory Money Purchase Illustrations (SMPI) investment
                return assumptions used on members’ benefit statements in recent years and also allows for the gradual
                process of de-risking of investments that is common to most DC schemes as members near retirement.
                However, the Calculator allows you to choose the level of future investment returns which may reflect
                your particular investment strategy.
              </li>
              <li>
                For the purpose of converting any defined contribution benefits you have into pension by the purchase of
                an annuity, interest rates are assumed to be 1.3% higher than inflation when you retire (this is a
                measure of the cost of buying a pension).
              </li>
              <li>
                Your default assumed retirement age is 65, unless you are a former Final Salary member who will be under
                age 60 as at 31 March 2024, in which case it is 60.
              </li>
              <li>
                If you will be over age 65 as at 1 April 2024, this Calculator cannot be used to model your benefits.
                Please contact the pensions department if you require further information about your options.
              </li>
              <li>
                Should you select the option in the Calculator to leave the Bank prior to your assumed retirement age,
                the Calculator assumes that there will be no additional defined benefit accrual or contributions to a
                defined contribution scheme after this date.
              </li>
            </ul>
            <h3>Past service pension calculations</h3>
            <h4>Former Final Salary members</h4>
            <p>
              The &apos;Past Service&apos; part of your pension shown is your estimated accrued Final Salary pension as
              at 31 March 2024 plus your estimated CARE pension accrued to that date. The Final Salary pension will
              increase in line with your pensionable salary until the point you decide to draw the pension assuming you
              continue to be an active member of the scheme, and will increase in payment in line with the Retail Price
              Index (RPI) after you leave the Bank or when the pension is in payment. The CARE element of your pension
              is described below, except that it is assumed that the part of your past service CARE pension accrued
              before 1 April 2020 can be taken unreduced from age 60. This discretion was removed from April 2020, so
              any CARE pension accrued after this is subject to reduction if taken before age 65.
            </p>
            <h4>CARE members</h4>
            <p>
              The &apos;Past Service&apos; part of your pension shown is your estimated accrued pension as at 31 March
              2024. This will increase in line with the Retail Prices Index (RPI) until the point you decide to draw the
              pension assuming you continue to be an active member of the scheme, and will increase in line with the
              Consumer Prices Index (CPI) after you leave the Bank or when the pension is in payment. The actual value
              in today’s earnings terms will depend on the relative level of inflation and salary increases over the
              period to the pension coming into payment.
            </p>
            <h4>Ex-FSA members</h4>
            <p>
              The &apos;Past Service&apos; part of your pension shown is your estimated CARE accrued pension as at 31
              March 2024 including any pension as a result of transferring in your DC pot from the FCA scheme. The
              pension resulting from transferring in your DC pot will increase until the point you decide to draw the
              pension in line with the CPI. If you did not choose to transfer your FCA scheme DC pot into the CARE
              scheme, the figures make no allowance for any pension you may be able to buy at retirement with that DC
              pot.
            </p>
            <h4>New joiners</h4>
            <p>
              The &apos;Past Service&apos; part of your pension shown is your estimated CARE pension built up between
              your date of joining the Bank and 31 March 2024.
            </p>
          </div>
        </Accordion>
      </section>

      <div className="page-navigation-buttons">
        <NavLink className="button button--page-navigation" to={PageUrls.annualAllowance}>
          Next
        </NavLink>
      </div>
    </div>
  );
};

export default YourChoices;
