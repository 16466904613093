import React from 'react';
import { useUpdateUserData, useUserData } from '../../../../store';

import RadioInputs from '../../../inputs/radio_inputs';
import SliderInput from '../../../inputs/slider_input/slider_input';
import HelpLabel from '../../../../components/help_label/help_label';

const PensionOptions = () => {
  const { flexTerm, flexRate1, flexRate2, dcContributionRate1, dcContributionRate2, currentAccrualRate, maxFlexTerm } =
    useUserData();
  const updateUserData = useUpdateUserData();

  const hasSelectedNewDcContRateOrAccrualRate = dcContributionRate1 > 0 || flexRate1 !== currentAccrualRate;

  const yearsToRetirementAfterFlexTerm = maxFlexTerm - flexTerm;

  return (
    <>
      <section className="user-options-tab-content user-options-tab-content--pension-options">
        <form noValidate>
          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="new-accrual-rate"
              description="Select a new accrual rate"
              extendedDescription="The pension accrual rate is the fraction of salary provided as pension for each year of service in the pension fund e.g. a 65ths accrual rate means you would earn a pension of 1/65 (1.54%) of your salary each year, whereas a 95ths accrual rate means you would earn a pension of 1/95 (1.05%) of your salary each year."
            />
            <RadioInputs
              labelledBy="new-accrual-rate"
              selected={flexRate1}
              handleChange={(value) => updateUserData({ flexRate1: value })}
              radioOptions={[
                { label: '120ths', value: 120 },
                { label: '110ths', value: 110 },
                { label: '95ths', value: 95 },
                { label: '80ths', value: 80 },
                { label: '65ths', value: 65 },
                { label: '50ths', value: 50 },
              ]}
            />
          </fieldset>

          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="dc-contribution-rate-1"
              description="Make a SPP / DC contribution"
              extendedDescription="If you wish to make a DC contribution to the Supplementary Pension Plan (SPP), please indicate the percentage of your salary that you wish to contribute. The maximum SPP/DC contribution you can make is 50% of your salary per year."
            />
            <SliderInput
              labelledBy="dc-contribution-rate-1"
              value={dcContributionRate1}
              handleChange={(value) => updateUserData({ dcContributionRate1: Number(value) })}
              min={0}
              max={0.5}
              increment={0.001}
              inputRepresentsPercentage
              unitLabel="per year"
            />
          </fieldset>

          {hasSelectedNewDcContRateOrAccrualRate && (
            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="flex-term"
                description="I want these options to apply for the next..."
                extendedDescription="This sets the number of years for which you want your chosen accrual rate and/or DC contribution to apply.  The default period is the number of years you have remaining until your assumed retirement age. If you change your retirement age in the ‘Retirement age’ tab the change to the maximum remaining period you can select will be reflected here."
              />
              <SliderInput
                labelledBy="flex-term"
                value={flexTerm}
                handleChange={(value) => updateUserData({ flexTerm: Number(value) })}
                min={1}
                max={maxFlexTerm}
                unitLabel="years"
              />
            </fieldset>
          )}
        </form>
      </section>

      {hasSelectedNewDcContRateOrAccrualRate && (
        <section
          className={`user-options-tab-content user-options-tab-content__future ${
            yearsToRetirementAfterFlexTerm === 0 && 'disabled'
          }`}
        >
          <h2>
            After {flexTerm} years, what options do you want to choose for the remaining{' '}
            {yearsToRetirementAfterFlexTerm} years?
          </h2>

          <form noValidate>
            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="new-accrual-rate-2"
                description="Select a new accrual rate"
                extendedDescription="This is the accrual rate you wish to apply for the remaining years after the end of the term chosen above, until your assumed retirement age."
              />
              <RadioInputs
                labelledBy="new-accrual-rate-2"
                selected={flexRate2}
                handleChange={(value) => updateUserData({ flexRate2: value })}
                radioOptions={[
                  { label: '120ths', value: 120 },
                  { label: '110ths', value: 110 },
                  { label: '95ths', value: 95 },
                  { label: '80ths', value: 80 },
                  { label: '65ths', value: 65 },
                  { label: '50ths', value: 50 },
                ]}
              />
            </fieldset>

            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="dc-contribution-rate-2"
                description="Make a SPP / DC contribution"
                extendedDescription="If you wish to make a DC contribution for the remaining years after the end of the term chosen above, please indicate what percentage of your salary you wish to contribute. The maximum SPP/DC contribution you can make is 50% of your salary per year."
              />
              <SliderInput
                labelledBy="dc-contribution-rate-2"
                value={dcContributionRate2}
                handleChange={(value) => updateUserData({ dcContributionRate2: Number(value) })}
                min={0}
                max={0.5}
                increment={0.001}
                inputRepresentsPercentage
                unitLabel="per year"
              />
            </fieldset>
          </form>
        </section>
      )}
    </>
  );
};

export default PensionOptions;
