import OutputLtaBaseline from './baseline';
import OutputLtaScenario from './scenario';

export default class OutputLtaCore {
  public baselinePastDcFund: number[];
  public baselinePastCare: number[];
  public baselineFutureCare: number[];
  public baselinePastFinalSalary: number[];
  public baselineLtaValue: number[];
  public pastDcFund: number[];
  public futureDcFund: number[];
  public ltaValue: number[];
  public baseline: OutputLtaBaseline;
  public scenario: OutputLtaScenario;
  public pastServiceFinalSalaryPension: number;
  public flexibleBenefitsPot: number;

  constructor() {
    this.baselinePastDcFund = [];
    this.baselinePastCare = [];
    this.baselineFutureCare = [];
    this.baselinePastFinalSalary = [];
    this.baselineLtaValue = [];
    this.pastDcFund = [];
    this.futureDcFund = [];
    this.ltaValue = [];
    this.baseline = new OutputLtaBaseline();
    this.scenario = new OutputLtaScenario();
    this.pastServiceFinalSalaryPension = 0;
    this.flexibleBenefitsPot = 0;
  }
}
