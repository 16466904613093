import OutputLtaResults from './results';

export default class OutputLtaBaseline {
  currentTerms?: OutputLtaResults;
  futureTerms?: OutputLtaResults;

  constructor() {
    this.currentTerms = new OutputLtaResults();
    this.futureTerms = new OutputLtaResults();
  }
};
