export default class SalaryScale {
  public startAge: number;
  public scale: number[];

  constructor(startAge: number, scale: number[]) {
    this.startAge = startAge;
    this.scale = scale;
  }

  public getScale(age: number) {
    var index = age - this.startAge;
    return this.scale.length >= index && index >= 0 ? this.scale[index] : NaN;
  }
}
