export default class OutputPensionResults {
  public age: number[];
  public salaryScale: number[];
  public careAccrual: number[];
  public inServiceRevaluation: number[];
  public careValueAtNormalRetirementAge: number[];
  public futurePre65PensionAtRetirementAgeFutureTerms: number[];
  public salaryAtDateOfCalculation: number[];
  public salaryAtStandardRetirementAge: number[];
  public futurePre65PensionAtRetirementAgeCurrentTerms: number[];
  public futurePost65PensionAtRetirementAgeFutureTerms: number[];
  public futurePost65PensionAtRetirementAgeCurrentTerms: number[];
  public month: number[];
  public pastServicePensionAtRetirementAge: number;
  public additionalFinalSalaryTransferInPensionAtRetirementAge: number;
  public pastServiceCarePensionAtRetirementAge: number;
  public pastServiceCarePensionAtRetirementAgePost20: number;
  public additionalCareTransferInPensionAtRetirementAge: number;
  public futurePre65PensionAtRetirementAge: number;
  public futurePost65PensionAtRetirementAge: number;
  public pastServiceDcFundAtRetirementAge: number;
  public futureServiceDcFundAtRetirementAge: number;
  public annuity: number;
  public dcPensionAtRetirementAge: number;
  public totalPastServicePensionAtRetirementAge: number;
  public totalFutureServicePensionAtRetirementAge: number;
  public totalPensionAtRetirementAge: number;
  public salaryAtRetirementAge: number;
  public replacementRatio: number;
  public ltaAtRetirementAge: number;
  public salary: number;

  constructor() {
    this.age = [];
    this.salaryScale = [];
    this.careAccrual = [];
    this.inServiceRevaluation = [];
    this.careValueAtNormalRetirementAge = [];
    this.futurePre65PensionAtRetirementAgeFutureTerms = [];
    this.salaryAtDateOfCalculation = [];
    this.salaryAtStandardRetirementAge = [];
    this.futurePre65PensionAtRetirementAgeCurrentTerms = [];
    this.futurePost65PensionAtRetirementAgeFutureTerms = [];
    this.futurePost65PensionAtRetirementAgeCurrentTerms = [];
    this.month = [];
    this.pastServicePensionAtRetirementAge = 0;
    this.additionalFinalSalaryTransferInPensionAtRetirementAge = 0;
    this.pastServiceCarePensionAtRetirementAge = 0;
    this.pastServiceCarePensionAtRetirementAgePost20 = 0;
    this.additionalCareTransferInPensionAtRetirementAge = 0;
    this.futurePre65PensionAtRetirementAge = 0;
    this.futurePost65PensionAtRetirementAge = 0;
    this.pastServiceDcFundAtRetirementAge = 0;
    this.futureServiceDcFundAtRetirementAge = 0;
    this.annuity = 0;
    this.dcPensionAtRetirementAge = 0;
    this.totalPastServicePensionAtRetirementAge = 0;
    this.totalFutureServicePensionAtRetirementAge = 0;
    this.totalPensionAtRetirementAge = 0;
    this.salaryAtRetirementAge = 0;
    this.replacementRatio = 0;
    this.ltaAtRetirementAge = 0;
    this.salary = 0;
  }
};
