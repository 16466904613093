export default class SchemePaysTable {
  public startAge: number;
  public factors: any;
  public careFactors: any;
  public finalSalaryFactors: any;

  constructor(startAge: number, factors: any) {
    this.startAge = startAge;
    this.factors = factors;
  }

  public getFactor(age: number) {
    var index = age - this.startAge;
    return this.factors.length >= index && index >= 0 ? this.factors[index] : NaN;
  }
}

