import React from 'react';
import BaseBarChart from './base_bar_chart';
import { useLtaOutput } from '../../store';
import Formatter from '../utils/formatter';
import formatCompoundBarTotal from './label_formatters';
import './base_bar_chart.scss';

const LtaChartScenario = () => {
  const { baseline, scenario, ltaStandardValue, ltaAlternativeValue } = useLtaOutput();
  const baselineBarHeight = baseline.legacy + baseline.past + baseline.future;
  const scenarioBarHeight = scenario.legacy + scenario.past + scenario.future;
  const yMax = Math.max(baselineBarHeight, scenarioBarHeight, ltaStandardValue, ltaAlternativeValue);
  const formatter = new Formatter();
  const scenarioOptions = {
    chart: {
      type: 'column',
      height: 300,
      marginRight: 80,
    },
    accessibility: {
      keyboardNavigation: {
        focusBorder: {
          style: {
            lineWidth: 3,
            color: '#aa1111',
            borderRadius: 5,
          },
          margin: 4,
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    title: {
      text: '',
    },
    xAxis: {
      categories: ['Position after your choices'],
      labels: {
        useHtml: true,
        y: 30,
        style: {
          fontSize: '14px',
          lineHeight: '16px',
          color: '#12273F',
          marginTop: '15px',
          fontWeight: '500',
        },
      },
    },
    yAxis: {
      min: 0,
      max: yMax,
      stackLabels: {
        enabled: true,
        formatter: formatCompoundBarTotal,
      },
      visible: true,
      title: {
        enabled: false,
      },
      plotLines: [
        {
          width: 2,
          value: ltaAlternativeValue,
          zIndex: 5,
          color: '#FF0000',
          label: {
            formatter: () => `Lifetime<br/>Allowance<br/>${formatter.asCurrencyWithoutPence(ltaAlternativeValue)}`,
            x: -70,
            y: 20,
            align: 'right',
            textAlign: 'left',
            useHtml: true,
            style: {
              color: '#FF0000',
              fontSize: '12px',
              fontWeight: '700',
              align: 'left',
              lineHeight: '20px',
            },
          },
        },
      ],
      labels: {
        useHtml: true,
        format: '£{text}',
        style: {
          fontSize: '0px',
          lineHeight: '16px',
          color: '#12273F',
          marginTop: '15px',
          fontWeight: '500',
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointWidth: 150,
      },
      series: {
        animation: {
          duration: 100,
        },
      },
      bar: {
        dataLabels: {
          enabled: true,
          format: '{point.series.name}',
        },
      },
    },
    tooltip: {
      headerFormat:
        '<span style="font-weight: bold; font-size: 14px; text-decoration: underline; margin-bottom: 10px;">{point.key}</span><br />',
      pointFormat: '<span style="color:{series.color}; font-size: 14px;">{series.name}</span>: <b>£{point.y}<br/>',
      footerFormat:
        '<br/ >The estimated LTA projected to your<br/>selected retirement age is ' +
        formatter.asCurrencyWithoutPence(ltaAlternativeValue),
      shared: true,
    },
    series: [
      {
        name: 'Other pensions',
        data: [Math.round(scenario.legacy)],
        stack: '1',
        color: '#3CD7D9',
        pointPadding: 0,
        groupPadding: 0.05,
      },
      {
        name: 'Future service',
        data: [Math.round(scenario.total) - Math.round(scenario.legacy) - Math.round(scenario.past)],
        stack: '1',
        color: '#415265',
        pointPadding: 0,
        groupPadding: 0.05,
      },
      {
        name: 'Past service',
        data: [Math.round(scenario.past)],
        stack: '1',
        color: '#D4AF37',
        pointPadding: 0,
        groupPadding: 0.05,
      },
    ],
  };

  return <BaseBarChart chartOptions={scenarioOptions} />;
};

export default LtaChartScenario;
