import OutputPensionBaseline from './baseline';
import OutputPensionScenario from './scenario';
import OutputAnnualAllowanceResults from '../annualAllowance/results';
import SalaryScale from '../../tables/salaryScale';

export default class OutputPensionCore {
  public ages: number[];
  public unroundedAges: number[];
  public salaries: number[];
  public salaryScales: number[];
  public careAccruals: number[];
  public inServiceRevaluations: number[];
  public careValueAtNormalRetirementAge: number[];
  public month: number[];
  public statuses: number[];
  public notionalSalary: number[];
  public flexedRate: number[];
  public inDefermentRevaluation: number[];
  public finalSalaryRevaluation: number[];
  public dcContributionPercent: number[];
  public dcContributionPounds: number[];
  public dcFundAtNormalRetirementAge: number[];
  public careTransferIn: number[];
  public pastCare: number[];
  public futureCare: number[];
  public finalSalaryTransferIn: number[];
  public pastFinalSalary: number[];
  public futurePre65PensionAtRetirementAgeFutureTerms: number;
  public salaryAtDateOfCalculation: number;
  public salaryAtStandardRetirementAge: number;
  public futurePre65PensionAtRetirementAgeCurrentTerms: number;
  public futurePost65PensionAtRetirementAgeFutureTerms: number;
  public futurePost65PensionAtRetirementAgeCurrentTerms: number;
  public careAccruedPension: number;
  public careAccruedPensionPost20: number;
  public pastServiceFinalSalaryPension: number;
  public finalSalaryAtDateOfLeaving: number;
  public annuity: number;
  public dcFund: number;
  public salaryChange: number;
  public baseline: OutputPensionBaseline;
  public scenario: OutputPensionScenario;
  public annualAllowanceForAccrualRate120: OutputAnnualAllowanceResults;
  public annualAllowanceForAccrualRate110: OutputAnnualAllowanceResults;
  public annualAllowanceForAccrualRate95: OutputAnnualAllowanceResults;
  public annualAllowanceForAccrualRate80: OutputAnnualAllowanceResults;
  public annualAllowanceForAccrualRate65: OutputAnnualAllowanceResults;
  public annualAllowanceForAccrualRate50: OutputAnnualAllowanceResults;

  constructor() {
    this.ages = [];
    this.unroundedAges = [];
    this.salaries = [];
    this.salaryScales = [];
    this.careAccruals = [];
    this.inServiceRevaluations = [];
    this.careValueAtNormalRetirementAge = [];
    this.month = [];
    this.statuses = [];
    this.notionalSalary = [];
    this.flexedRate = [];
    this.inDefermentRevaluation = [];
    this.finalSalaryRevaluation = [];
    this.dcContributionPercent = [];
    this.dcContributionPounds = [];
    this.dcFundAtNormalRetirementAge = [];
    this.careTransferIn = [];
    this.pastCare = [];
    this.futureCare = [];
    this.finalSalaryTransferIn = [];
    this.pastFinalSalary = [];
    this.futurePre65PensionAtRetirementAgeFutureTerms = 0;
    this.salaryAtDateOfCalculation = 0;
    this.salaryAtStandardRetirementAge = 0;
    this.futurePre65PensionAtRetirementAgeCurrentTerms = 0;
    this.futurePost65PensionAtRetirementAgeFutureTerms = 0;
    this.futurePost65PensionAtRetirementAgeCurrentTerms = 0;
    this.careAccruedPension = 0;
    this.careAccruedPensionPost20 = 0;
    this.pastServiceFinalSalaryPension = 0;
    this.finalSalaryAtDateOfLeaving = 0;
    this.annuity = 0;
    this.dcFund = 0;
    this.salaryChange = 0;
    this.baseline = new OutputPensionBaseline();
    this.scenario = new OutputPensionScenario();
    this.annualAllowanceForAccrualRate120 = new OutputAnnualAllowanceResults();
    this.annualAllowanceForAccrualRate110 = new OutputAnnualAllowanceResults();
    this.annualAllowanceForAccrualRate95 = new OutputAnnualAllowanceResults();
    this.annualAllowanceForAccrualRate80 = new OutputAnnualAllowanceResults();
    this.annualAllowanceForAccrualRate65 = new OutputAnnualAllowanceResults();
    this.annualAllowanceForAccrualRate50 = new OutputAnnualAllowanceResults();
  }
}
