/* eslint-disable import/prefer-default-export */
import OutputPensionBaseline from './engine/output/pension/baseline';
import OutputPensionScenario from './engine/output/pension/scenario';
import OutputAnnualAllowanceResults from './engine/output/annualAllowance/results';
import OutputLtaCore from './engine/output/lta/core';

export enum PageUrls {
  welcome = '/',
  yourDetails = '/your-details',
  yourChoices = '/your-choices',
  annualAllowance = '/annual-allowance',
  furtherInformation = '/further-information',
}

export interface UserDataStore {
  membershipType: string;
  standardAccrualRate: 95 | 65 | 50;
  currentAccrualRate: 120 | 110 | 95 | 80 | 65 | 50;
  currentAge: number;
  dateOfBirth: string;
  dateOfBirthMin: string;
  dateOfBirthMax: string;
  effectiveDateOfTotalRewardStatement: string;
  effectiveDateOfTotalRewardStatementMin: string;
  effectiveDateOfTotalRewardStatementMax: string;
  salary: number;
  pastServiceCarePensionPost20: number;
  flexTerm: number;
  maxFlexTerm: number;
  flexRate1?: number;
  flexRate2?: number;
  dcContributionRate1: number;
  dcContributionRate2: number;
  retirementAge: number;
  salaryIncreaseRate: number;
  investmentReturn: number;
  termToDateOfLeaving: number;
  futureTerms: boolean;
  legacyDbPension: number;
  legacyDcFund: number;
  atBankUntilRetirement: boolean;
  careTransferInPension: number;
  pastServiceFinalSalaryPension: number;
  finalSalaryTransferInPension: number;
  pastServiceCarePension: number;
  otherBankTaxableIncome: number;
  otherExternalTaxableIncome: number;
  dcContributions: number;
  annualAllowanceCarryForward: number;
  otherAnnualAllowanceValue: number;
  marginalTaxRate: number;
  firstYearSalaryIncrease: number;
  secondYearSalaryIncrease: number;
  performanceAward: number;
  firstYearRpiRevaluation: number;
  secondYearRpiRevaluation: number;
  secondYearCpiRevaluation: number;
}

export interface UiOptionsStore {
  hasAcceptedTermsAndConditions: boolean;
  hasEnteredValidUserDetails: boolean;
  yourDetailsErrorFields: string[];
}

export interface PensionValuationOrAnnualAllowanceOutputStore {
  ages: number[];
  unroundedAges: number[];
  salaries: number[];
  salaryScales: number[];
  careAccruals: number[];
  inServiceRevaluations: number[];
  careValueAtNormalRetirementAge: number[];
  month: number[];
  statuses: number[];
  notionalSalary: number[];
  flexedRate: number[];
  inDefermentRevaluation: number[];
  finalSalaryRevaluation: number[];
  dcContributionPercent: number[];
  dcContributionPounds: number[];
  dcFundAtNormalRetirementAge: number[];
  careTransferIn: number[];
  pastCare: number[];
  futureCare: number[];
  finalSalaryTransferIn: number[];
  pastFinalSalary: number[];
  futurePre65PensionAtRetirementAgeFutureTerms: number;
  salaryAtDateOfCalculation: number;
  salaryAtStandardRetirementAge: number;
  futurePre65PensionAtRetirementAgeCurrentTerms: number;
  futurePost65PensionAtRetirementAgeFutureTerms: number;
  futurePost65PensionAtRetirementAgeCurrentTerms: number;
  careAccruedPension: number;
  careAccruedPensionPost20: number;
  pastServiceFinalSalaryPension: number;
  finalSalaryAtDateOfLeaving: number;
  annuity: number;
  dcFund: number;
  salaryChange: number;
  baseline: OutputPensionBaseline;
  scenario: OutputPensionScenario;
  annualAllowanceForAccrualRate120: OutputAnnualAllowanceResults;
  annualAllowanceForAccrualRate110: OutputAnnualAllowanceResults;
  annualAllowanceForAccrualRate95: OutputAnnualAllowanceResults;
  annualAllowanceForAccrualRate80: OutputAnnualAllowanceResults;
  annualAllowanceForAccrualRate65: OutputAnnualAllowanceResults;
  annualAllowanceForAccrualRate50: OutputAnnualAllowanceResults;
}

export interface LtaOutputStore {
  userSummary: OutputLtaCore;
  ltaStandardValue: number;
  ltaAlternativeValue: number;
}

export enum reducerActions {
  UPDATE_USER_DATA = 'update_user_data',
  UPDATE_UI_OPTIONS = 'update_ui_options',
  UPDATE_PENSION_VALUATION_OUTPUT = 'update_pension_valuation_output',
  UPDATE_LTA_OUTPUT = 'update_lta_output',
  UPDATE_ANNUAL_ALLOWANCE_OUTPUT = 'update_annual_allowance_output',
  RESET_ALL = 'reset_all',
}

export type updateUserData = {
  type: reducerActions.UPDATE_USER_DATA;
  payload: UserDataStore;
};

export type updateUiOptions = {
  type: reducerActions.UPDATE_UI_OPTIONS;
  payload: UiOptionsStore;
};

export type updatePensionValuationOutput = {
  type: reducerActions.UPDATE_PENSION_VALUATION_OUTPUT;
  payload: PensionValuationOrAnnualAllowanceOutputStore;
};

export type updateLtaOutput = {
  type: reducerActions.UPDATE_LTA_OUTPUT;
  payload: LtaOutputStore;
};

export type updateAnnualAllowanceOutput = {
  type: reducerActions.UPDATE_ANNUAL_ALLOWANCE_OUTPUT;
  payload: PensionValuationOrAnnualAllowanceOutputStore;
};

export type resetAll = {
  type: reducerActions.RESET_ALL;
  payload: null;
};

export type reducerAction =
  | updateUserData
  | updateUiOptions
  | resetAll
  | updatePensionValuationOutput
  | updateLtaOutput
  | updateAnnualAllowanceOutput;

export interface ReduxStore {
  userData: UserDataStore;
  uiOptions: UiOptionsStore;
  pensionValuationOutput: PensionValuationOrAnnualAllowanceOutputStore;
  annualAllowanceOutput: PensionValuationOrAnnualAllowanceOutputStore;
  ltaOutput: LtaOutputStore;
}
