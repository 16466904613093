import { AdvancedDate as AdvDate } from '@lifetime-gallagher/calculator-toolkit';
import AssumptionsAnnualAllowance from './assumptionsAnnualAllowance';
import OutputLtaCore from './output/lta/core';

export default class Assumptions {
  public calculationDate: AdvDate;
  public salaryIncreaseRate: number;
  public rpi: number;
  public futureServiceNormalRetirementAge: number;
  public cpi: number;
  public pastServiceNormalRetirementAgePost20: number;
  public ltaFactor: number;
  public investmentReturn: number;
  public lta: number;
  public annualAllowance: AssumptionsAnnualAllowance;
  public parameters: number[];
  public output: OutputLtaCore;
  public debug: Boolean;

  constructor() {
    this.calculationDate = new AdvDate();
    this.salaryIncreaseRate = 0;
    this.rpi = 0;
    this.futureServiceNormalRetirementAge = 0;
    this.cpi = 0;
    this.pastServiceNormalRetirementAgePost20 = 0;
    this.ltaFactor = 0;
    this.investmentReturn = 0;
    this.lta = 0;
    this.parameters = [];
    this.output = new OutputLtaCore();
    this.annualAllowance = new AssumptionsAnnualAllowance();
    this.debug = false;
  }
}
