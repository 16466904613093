import React from 'react';

import MoneyInput from '../money_input/money_input';
import PercentageInput from '../percentage_input/percentage_input';

import './slider_input.scss';

type SliderInputProps = {
  value: string | number;
  min: number;
  max: number;
  handleChange: (value: string) => void;
  increment?: number;
  unitLabel?: string;
  labelledBy: string;
  inputRepresentsMoney?: boolean;
  inputRepresentsPercentage?: boolean;
  mobileStyledOnDesktop?: boolean;
};

const SliderInput = ({
  value,
  min,
  max,
  handleChange,
  unitLabel,
  labelledBy,
  increment,
  inputRepresentsMoney,
  inputRepresentsPercentage,
  mobileStyledOnDesktop,
}: SliderInputProps) => {
  const INCREMENT_STEP = increment || 1;

  const validateValue = (newValue: number | string) => {
    const clippedValue = Math.max(Math.min(Number(newValue), max), min);
    handleChange(String(clippedValue));
  };

  const handleIncrementButtonClick = (direction = 'plus') => {
    const currentValue = Number(value);

    const newValue = direction === 'plus' ? currentValue + INCREMENT_STEP : currentValue - INCREMENT_STEP;

    return validateValue(newValue.toFixed(3));
  };

  const adjacentInputField = () => {
    if (inputRepresentsMoney) {
      return (
        <MoneyInput
          key={labelledBy}
          value={Number(value)}
          onChange={(newValue) => validateValue(newValue)}
          labelledBy={labelledBy}
          aria-labelledby={labelledBy}
          hideAYear
        />
      );
    }

    if (inputRepresentsPercentage) {
      return (
        <PercentageInput
          key={labelledBy}
          value={Number(value)}
          onChange={(newValue) => validateValue(newValue)}
          labelledBy={labelledBy}
          min={min}
          max={max}
          aria-labelledby={labelledBy}
        />
      );
    }

    return (
      <input
        id={labelledBy}
        type="number"
        min={min}
        max={max}
        value={Number(value)}
        onChange={(event) => handleChange(event.target.value)}
        onBlur={(event) => validateValue(event.target.value)}
        aria-labelledby={labelledBy}
      />
    );
  };

  return (
    <div className={`slider-container ${mobileStyledOnDesktop ? 'slider-container--mobile-styled-by-default' : ''}`}>
      <button
        className="slider-container__subtract-button"
        type="button"
        aria-label="decrease"
        onClick={() => handleIncrementButtonClick('minus')}
      />
      <input
        type="range"
        min={min}
        max={max}
        value={value}
        className="slider"
        step={INCREMENT_STEP}
        onChange={(event) => validateValue(event.target.value)}
        id={`${labelledBy}-slider`}
        data-cy={labelledBy}
        aria-labelledby={labelledBy}
      />
      <button
        className="slider-container__plus-button"
        type="button"
        aria-label="increase"
        onClick={() => handleIncrementButtonClick()}
      />

      {adjacentInputField()}

      {unitLabel && <p className="slider-container__unit-label">{unitLabel}</p>}
    </div>
  );
};

export default SliderInput;
