import { createStore } from 'redux';
import reducer from './root_reducer';

const store = createStore(reducer);

export default store;

export { useUserData, useUpdateUserData, useUiOptions, useUpdateUiOptions } from './user_inputs/hooks';

export {
  usePensionValuationOutput,
  useUpdatePensionValuationOutput,
  useAnnualAllowanceOutput,
  useUpdateAnnualAllowanceOutput,
  useLtaOutput,
  useUpdateLtaOutput,
} from './engine_outputs/hooks';
