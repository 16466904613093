import Formatter from '../utils/formatter';

const formatter = new Formatter();

const formatCompoundBarTotal = function (): string {
  // @ts-expect-error Do not have highcharts types that are needed to type annotate this correctly
  if (this.total < 0) return '';

  let sum = 0;
  // @ts-expect-error Do not have highcharts types that are needed to type annotate this correctly
  const { series } = this.axis;

  for (const idx in series) {
    // @ts-expect-error Do not have highcharts types that are needed to type annotate this correctly
    if (series[idx].visible && series[idx].options.stacking === 'normal') sum += series[idx].yData[this.x] || 0;
  }

  return formatter.asCurrencyWithoutPence(sum);
};

export default formatCompoundBarTotal;
