import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useUiOptions, useUpdateUiOptions } from '../../../store';
import useDocumentTitle from '../../../store/use_document_title';
import { PageUrls } from '../../../types';
import InformationBox from '../../containers/information_box';
import StringLiterals from '../../utils/stringLiterals';
import './welcome.scss';

const Welcome = () => {
  const { hasAcceptedTermsAndConditions } = useUiOptions();
  const updateUiOptions = useUpdateUiOptions();

  const [termsAndConditionsAreVisible, setTermsAndConditionsAreVisible] = useState(false);

  const handleNextPageButtonClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!hasAcceptedTermsAndConditions) event.preventDefault();
  };

  const termsAndConditionsRef = useRef<null | HTMLDivElement>(null);

  useEffect(
    function shiftTermsIntoViewWhenMadeVisible() {
      if (!termsAndConditionsAreVisible) return;

      const targetScrollPosition = termsAndConditionsRef.current.getBoundingClientRect().y;
      setTimeout(() => window.scrollTo({ top: targetScrollPosition, behavior: 'smooth' }), 100);
    },
    [termsAndConditionsAreVisible]
  );

  useDocumentTitle(StringLiterals.title + 'Welcome');

  return (
    <div className="welcome-page-content copy-section">
      <section>
        <h1>
          Welcome to the Bank of England
          <br />
          Pensions Calculator
        </h1>
        <p className="intro-text">
          This Calculator has been designed to help you understand your pension benefits and the impact of any choices
          you make during the annual selection of your benefits through Benefits+.
        </p>
        <h2>What does the Calculator do?</h2>
        <p>
          The Calculator allows you to model the expected amount of your annual pension at retirement, based on what
          choices you make – for example, what accrual rate you choose, or what age you wish to retire at. This is
          particularly helpful when assessing the short-term impact (cash today) versus long-term impact (pension
          tomorrow).
        </p>
        <p>
          The pension amounts are shown in &apos;today&apos;s earnings terms&apos;. This means you can compare the
          pension amounts shown to your current salary in order to understand the level of income that the pension will
          provide relative to your salary.
        </p>
        <p>
          The Calculator also allows you to compare the estimated value of your benefits with the pensions Annual
          Allowance tax limits. This can help you understand whether your pension benefits may incur a tax charge.
        </p>

        <InformationBox>
          <div>
            <h2 className="information-box__heading">Terms and conditions</h2>
            <form noValidate>
              <p>
                Please accept the{' '}
                <button
                  type="button"
                  className="button--open-terms"
                  onClick={() => {
                    setTermsAndConditionsAreVisible(!termsAndConditionsAreVisible);
                  }}
                >
                  terms and conditions
                </button>
                :
              </p>
              <label htmlFor="accept-terms" className="terms-confirmation__approval-text">
                <div className="checkmark-container">
                  <input
                    type="checkbox"
                    id="accept-terms"
                    checked={hasAcceptedTermsAndConditions}
                    data-cy="accept-terms"
                    onChange={() => {
                      updateUiOptions({ hasAcceptedTermsAndConditions: !hasAcceptedTermsAndConditions });
                    }}
                  />
                  <span className={`checkmark ${hasAcceptedTermsAndConditions ? 'checkmark--visible' : ''}`} />
                </div>
                <span>I have read and accept the terms and condition</span>
              </label>
            </form>
          </div>

          <NavLink
            className={`button button--page-navigation ${!hasAcceptedTermsAndConditions && 'disabled'}`}
            to={PageUrls.yourDetails}
            data-cy="accept-terms-button"
            onClick={handleNextPageButtonClick}
          >
            Next
          </NavLink>
        </InformationBox>
      </section>

      <section>
        <div
          className={`terms-and-conditions-wrapper ${
            termsAndConditionsAreVisible && 'terms-and-conditions-wrapper--visible'
          }`}
        >
          <h2 ref={termsAndConditionsRef}>Terms and conditions</h2>
          <h3>Please take a moment to read and accept these terms and conditions before continuing.</h3>
          <p>
            This Pensions Calculator is provided by XPS Pensions for illustrative purposes only. The Calculator only
            uses data that has been inputted and does not store or record any personal data.
          </p>
          <p>
            The information made available through the Calculator is an estimate only, does not confer any rights to
            benefits and should not be relied upon to make investment decisions.
          </p>
          <p>
            Any estimate provided in the Calculator will not constitute the regulated activity of advising on
            investments or any other regulated activity under the Financial Services and Markets Act 2000. XPS Pensions
            is not authorised and regulated by the Financial Conduct Authority to carry out such activities.
          </p>
          <p>
            The Calculator is only intended to be used by employees of the Bank of England and information obtained
            using the Calculator is provided for their sole use and exclusive benefit. Such information should not be
            shared with any other party without prior written consent from XPS Pensions.
          </p>
          <p>
            Information provided through the Calculator is provided “as is” and without warranty as to its accuracy or
            completeness. The user (or any third party) should not rely on the accuracy or completeness of the
            information provided though the Calculator, and remains responsible for taking further professional advice
            as appropriate. Any liability XPS Pensions may have in connection with the use of the Calculator or
            information made available through it is excluded to the fullest extent permitted by law.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Welcome;
