import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsAccessibility from 'highcharts/modules/accessibility';
import './base_bar_chart.scss';

highchartsAccessibility(Highcharts);
Highcharts.setOptions({
  lang: {
    thousandsSep: ',',
    numericSymbols: null,
  },
});

interface BaseBarChartProps {
  chartOptions?: any;
}

const BaseBarChart = ({ chartOptions }: BaseBarChartProps) => {
  return (
    <div className="bar-chart-wrapper">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
};

export default BaseBarChart;
