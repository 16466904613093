import React, { useState } from 'react';

import { useUserData, useUpdateUserData } from '../../../../store';

import SliderInput from '../../../inputs/slider_input/slider_input';
import HelpLabel from '../../../help_label/help_label';
import stringLiterals from '../../../../components/utils/stringLiterals';

const AdjustAssumptionsSidebar = () => {
  const {
    firstYearSalaryIncrease,
    secondYearSalaryIncrease,
    performanceAward,
    firstYearRpiRevaluation,
    secondYearRpiRevaluation,
    secondYearCpiRevaluation,
    finalSalaryTransferInPension,
    careTransferInPension,
  } = useUserData();
  const updateUserData = useUpdateUserData();

  const [inputsAreVisible, setInputsAreVisible] = useState(false);

  const toggleAllowanceInputVisibility = () => {
    setInputsAreVisible((prevState) => !prevState);
  };

  return (
    <td rowSpan={100} className="adjust-assumptions-wrapper-cell">
      <div className="adjust-assumptions-sidebar">
        <button
          type="button"
          className={`adjust-assumptions-sidebar__heading ${inputsAreVisible ? 'active' : ''}`}
          onClick={() => {
            toggleAllowanceInputVisibility();
          }}
        >
          <span>Adjust your assumptions</span>
        </button>

        <div className={`adjust-assumptions-sidebar__inputs ${inputsAreVisible ? 'active' : ''}`}>
          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="first-year-salary-increase"
              description={'1 March ' + stringLiterals.thisYear + ' salary increase'}
              extendedDescription={
                'The total salary increase (including any promotional increase) you expect to receive on 1 March ' +
                stringLiterals.thisYear +
                '.'
              }
            />
            <SliderInput
              labelledBy="first-year-salary-increase"
              value={firstYearSalaryIncrease}
              handleChange={(value) => updateUserData({ firstYearSalaryIncrease: Number(value) })}
              min={0}
              max={0.2}
              increment={0.001}
              inputRepresentsPercentage
              mobileStyledOnDesktop
            />
          </fieldset>

          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="performance-award"
              description={'March ' + stringLiterals.thisYear + ' Performance Award'}
              extendedDescription={
                'The Performance Award you expect to receive during March ' +
                stringLiterals.thisYear +
                ', as a percentage of your salary on 1 March  ' +
                stringLiterals.thisYear +
                '.'
              }
            />
            <SliderInput
              labelledBy="performance-award"
              value={performanceAward}
              handleChange={(value) => updateUserData({ performanceAward: Number(value) })}
              min={0}
              max={0.2}
              increment={0.001}
              inputRepresentsPercentage
              mobileStyledOnDesktop
            />
          </fieldset>

          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="second-year-salary-increase"
              description={'1 March ' + stringLiterals.nextYear + ' salary increase'}
              extendedDescription={
                'The total salary increase (including any promotional increase) you expect to receive on 1 March ' +
                stringLiterals.nextYear +
                '.'
              }
            />
            <SliderInput
              labelledBy="second-year-salary-increase"
              value={secondYearSalaryIncrease}
              handleChange={(value) => updateUserData({ secondYearSalaryIncrease: Number(value) })}
              min={0}
              max={0.2}
              increment={0.001}
              inputRepresentsPercentage
              mobileStyledOnDesktop
            />
          </fieldset>

          {finalSalaryTransferInPension > 0 && (
            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="first-year-rpi-revaluation"
                description={'1 July ' + stringLiterals.thisYear + ' RPI revaluation'}
                extendedDescription={
                  'The RPI increase you expect to be applied to Final Salary transferred-in pension benefits on 1 July ' +
                  stringLiterals.thisYear +
                  ', which is based on the increase in RPI from May ' +
                  stringLiterals.lastYear +
                  '  to May ' +
                  stringLiterals.thisYear +
                  '.'
                }
              />
              <SliderInput
                labelledBy="first-year-rpi-revaluation"
                value={firstYearRpiRevaluation}
                handleChange={(value) => updateUserData({ firstYearRpiRevaluation: Number(value) })}
                min={0}
                max={0.2}
                increment={0.001}
                inputRepresentsPercentage
                mobileStyledOnDesktop
              />
            </fieldset>
          )}

          <fieldset className="input-fieldset">
            <HelpLabel
              labelFor="second-year-rpi-revaluation"
              description={'1 April ' + stringLiterals.nextYear + ' RPI revaluation'}
              extendedDescription={
                'The RPI increase you expect to be applied to CARE pension benefits on 1 April ' +
                stringLiterals.nextYear +
                ', which is based on the increase in RPI from January ' +
                stringLiterals.thisYear +
                ' to January ' +
                stringLiterals.nextYear +
                '.'
              }
            />
            <SliderInput
              labelledBy="second-year-rpi-revaluation"
              value={secondYearRpiRevaluation}
              handleChange={(value) => updateUserData({ secondYearRpiRevaluation: Number(value) })}
              min={0}
              max={0.2}
              increment={0.001}
              inputRepresentsPercentage
              mobileStyledOnDesktop
            />
          </fieldset>

          {careTransferInPension > 0 && (
            <fieldset className="input-fieldset">
              <HelpLabel
                labelFor="second-year-cpi-revaluation"
                description={'1 April ' + stringLiterals.nextYear + ' CPI revaluation'}
                extendedDescription={
                  'The CPI increase you expect to be applied to CARE transferred-in pension benefits on 1 April ' +
                  stringLiterals.nextYear +
                  ', which is based on the increase in CPI from January ' +
                  stringLiterals.thisYear +
                  ' to January ' +
                  stringLiterals.nextYear +
                  '.'
                }
              />
              <SliderInput
                labelledBy="second-year-cpi-revaluation"
                value={secondYearCpiRevaluation}
                handleChange={(value) => updateUserData({ secondYearCpiRevaluation: Number(value) })}
                min={0}
                max={0.2}
                increment={0.001}
                inputRepresentsPercentage
                mobileStyledOnDesktop
              />
            </fieldset>
          )}
        </div>
      </div>
    </td>
  );
};

export default AdjustAssumptionsSidebar;
